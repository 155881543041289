import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let FeriadoModule = class FeriadoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.feriados = [];
    }
    setFeriados(payload) {
        this.feriados = payload;
    }
    async getFeriados() {
        try {
            const response = await api.getFeriados(mainStore.token);
            if (response) {
                this.setFeriados(response.data);
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateFeriado(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                // @ts-ignore
                api.updateFeriado(mainStore.token, payload.id, payload.feriadoIn),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Feriado atualizada com sucesso',
                color: 'success',
            });
            // @ts-ignore
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async createFeriado(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createFeriado(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Feriado criado com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            // @ts-ignore
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteFeriado(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteFeriado(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Feriado excluído com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getFeriadosPeriodo(datas) {
        try {
            const response = await api.getFeriadosPeriodo(mainStore.token, datas);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async atualizaFeriados(ano) {
        const loadingNotification = { content: 'Sincronizando feriados...', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.atualizaFeriados(mainStore.token, ano);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Feriados sincronizados com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async gerarAquivoFeriadoAno(ano) {
        try {
            const response = await api.gerarAquivoFeriadoAno(mainStore.token, ano);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], FeriadoModule.prototype, "setFeriados", null);
__decorate([
    Action
], FeriadoModule.prototype, "getFeriados", null);
__decorate([
    Action
], FeriadoModule.prototype, "updateFeriado", null);
__decorate([
    Action
], FeriadoModule.prototype, "createFeriado", null);
__decorate([
    Action
], FeriadoModule.prototype, "deleteFeriado", null);
__decorate([
    Action
], FeriadoModule.prototype, "getFeriadosPeriodo", null);
__decorate([
    Action
], FeriadoModule.prototype, "atualizaFeriados", null);
__decorate([
    Action
], FeriadoModule.prototype, "gerarAquivoFeriadoAno", null);
FeriadoModule = __decorate([
    Module({ name: 'feriado' })
], FeriadoModule);
export default FeriadoModule;
