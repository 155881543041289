import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let JornadaColaboradorModule = class JornadaColaboradorModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.jornadaColaborador = [];
    }
    setJornadaColaborador(payload) {
        this.jornadaColaborador = payload;
    }
    async createJornadaColaborador(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createJornadaColaborador(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'JornadaColaborador criado com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getJornadaColaborador(id) {
        try {
            const response = await api.getJornadaColaborador(mainStore.token, id);
            this.setJornadaColaborador(response.data);
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], JornadaColaboradorModule.prototype, "setJornadaColaborador", null);
__decorate([
    Action
], JornadaColaboradorModule.prototype, "createJornadaColaborador", null);
__decorate([
    Action
], JornadaColaboradorModule.prototype, "getJornadaColaborador", null);
JornadaColaboradorModule = __decorate([
    Module({ name: 'jornadaColaborador' })
], JornadaColaboradorModule);
export default JornadaColaboradorModule;
