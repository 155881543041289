export function newParceriaCupomHistorico() {
    return {
        parceria_cupom_id: 0,
        status_id: 0,
        cda_id: 0,
        company_id: 0,
        agendamento: '',
        observacao: '',
    };
}
