import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let SyngooConfiguracaoModule = class SyngooConfiguracaoModule extends VuexModule {
    async createSyngooConfiguracao(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createSyngooConfiguracao(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'SyngooConfiguracao criado com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateSyngooConfiguracao(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateSyngooConfiguracao(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Configuração atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getSyngooConfiguracao() {
        try {
            const response = await api.getSyngooConfiguracao(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getSyngooConfiguracaoById(id) {
        try {
            const response = await api.getSyngooConfiguracaoById(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getSyngooConfiguracaoStatus() {
        try {
            const response = await api.getSyngooConfiguracaoStatus(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async deleteSyngooConfiguracao(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteSyngooConfiguracao(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'SyngooConfiguracao excluida com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], SyngooConfiguracaoModule.prototype, "createSyngooConfiguracao", null);
__decorate([
    Action
], SyngooConfiguracaoModule.prototype, "updateSyngooConfiguracao", null);
__decorate([
    Action
], SyngooConfiguracaoModule.prototype, "getSyngooConfiguracao", null);
__decorate([
    Action
], SyngooConfiguracaoModule.prototype, "getSyngooConfiguracaoById", null);
__decorate([
    Action
], SyngooConfiguracaoModule.prototype, "getSyngooConfiguracaoStatus", null);
__decorate([
    Action
], SyngooConfiguracaoModule.prototype, "deleteSyngooConfiguracao", null);
SyngooConfiguracaoModule = __decorate([
    Module({ name: 'syngooConfiguracao' })
], SyngooConfiguracaoModule);
export default SyngooConfiguracaoModule;
