import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let MarketingDigitalModule = class MarketingDigitalModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.agenciaFinanceiro = null;
        this.fundoFinanceiro = null;
    }
    setAgenciaFinanceiro(payload) {
        this.agenciaFinanceiro = payload;
    }
    setFundoFinanceiro(payload) {
        this.fundoFinanceiro = payload;
    }
    async marketingDigitalAgenciaGetFinanceiro() {
        try {
            const response = await api.marketingDigitalAgenciaGetFinanceiro(mainStore.token);
            if (response) {
                this.setAgenciaFinanceiro(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async marketingDigitalFundoGetFinanceiro() {
        try {
            const response = await api.marketingDigitalFundoGetFinanceiro(mainStore.token);
            if (response) {
                this.setFundoFinanceiro(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async marketingDigitalFundoCreateFinanceiro(payload) {
        try {
            const response = await api.marketingDigitalFundoCreateFinanceiro(mainStore.token, payload);
            if (response) {
                this.setFundoFinanceiro(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async marketingDigitalHistorico() {
        try {
            const response = await api.marketingDigitalHistorico(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], MarketingDigitalModule.prototype, "setAgenciaFinanceiro", null);
__decorate([
    Mutation
], MarketingDigitalModule.prototype, "setFundoFinanceiro", null);
__decorate([
    Action
], MarketingDigitalModule.prototype, "marketingDigitalAgenciaGetFinanceiro", null);
__decorate([
    Action
], MarketingDigitalModule.prototype, "marketingDigitalFundoGetFinanceiro", null);
__decorate([
    Action
], MarketingDigitalModule.prototype, "marketingDigitalFundoCreateFinanceiro", null);
__decorate([
    Action
], MarketingDigitalModule.prototype, "marketingDigitalHistorico", null);
MarketingDigitalModule = __decorate([
    Module({ name: 'marketingDigital' })
], MarketingDigitalModule);
export default MarketingDigitalModule;
