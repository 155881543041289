import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let MapaHorarioModule = class MapaHorarioModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.mapaHorarioPorSala = [];
        this.mapaHorarioPorSemana = [];
    }
    setMapaHorarioPorSala(payload) {
        this.mapaHorarioPorSala = payload;
    }
    setMapaHorarioPorSemana(payload) {
        this.mapaHorarioPorSemana = payload;
    }
    async getMapaHorarioPorSala(payload) {
        try {
            const response = await api.getMapaHorarioPorSala(mainStore.token, payload);
            if (response) {
                this.setMapaHorarioPorSala(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getHorarioPorSemana(payload) {
        try {
            const response = await api.getHorarioPorSemana(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCronogramaPorSemana(payload) {
        try {
            const response = await api.getCronogramaPorSemana(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getMapaHorarioTarefasReunioes(payload) {
        try {
            const response = await api.getMapaHorarioTarefasReunioes(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], MapaHorarioModule.prototype, "setMapaHorarioPorSala", null);
__decorate([
    Mutation
], MapaHorarioModule.prototype, "setMapaHorarioPorSemana", null);
__decorate([
    Action
], MapaHorarioModule.prototype, "getMapaHorarioPorSala", null);
__decorate([
    Action
], MapaHorarioModule.prototype, "getHorarioPorSemana", null);
__decorate([
    Action
], MapaHorarioModule.prototype, "getCronogramaPorSemana", null);
__decorate([
    Action
], MapaHorarioModule.prototype, "getMapaHorarioTarefasReunioes", null);
MapaHorarioModule = __decorate([
    Module({ name: 'mapaHorario' })
], MapaHorarioModule);
export default MapaHorarioModule;
