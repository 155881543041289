import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let ParceriaModule = class ParceriaModule extends VuexModule {
    async updateParceriaAcao(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateParceriaAcao(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Ação atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateStatusParceriaAcao(id) {
        const loadingNotification = { content: 'Atualizando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateStatusParceriaAcao(mainStore.token, id),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Operação efetuada com sucesso',
                color: 'success',
            });
            return true;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getParceriaAcoes(parceria_id) {
        try {
            const response = await api.getParceriaAcoes(mainStore.token, parceria_id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getParceriaAcoesPlanejamentoComercial(payload) {
        try {
            const response = await api.getParceriaAcoesPlanejamentoComercial(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteParceriaAcao(parceriaAcaoId) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.deleteParceriaAcao(mainStore.token, parceriaAcaoId),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Ação excluída com sucesso',
                color: 'success',
            });
            return true;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getCadernoExternoQuery(queryIn) {
        try {
            const response = await api.getCadernoExternoQuery(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], ParceriaModule.prototype, "updateParceriaAcao", null);
__decorate([
    Action
], ParceriaModule.prototype, "updateStatusParceriaAcao", null);
__decorate([
    Action
], ParceriaModule.prototype, "getParceriaAcoes", null);
__decorate([
    Action
], ParceriaModule.prototype, "getParceriaAcoesPlanejamentoComercial", null);
__decorate([
    Action
], ParceriaModule.prototype, "deleteParceriaAcao", null);
__decorate([
    Action
], ParceriaModule.prototype, "getCadernoExternoQuery", null);
ParceriaModule = __decorate([
    Module({ name: 'parceria_acao' })
], ParceriaModule);
export default ParceriaModule;
