import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let TreinamentoModule = class TreinamentoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.treinamentos = [];
    }
    setTreinamentos(payload) {
        this.treinamentos = payload;
    }
    async createTreinamento(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createTreinamento(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Treinamento criado com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateTreinamento(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateTreinamento(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Treinamento atualizado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getTreinamentos() {
        try {
            const response = await api.getTreinamentos(mainStore.token);
            if (response) {
                this.setTreinamentos(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTreinamento(id) {
        try {
            const response = await api.getTreinamento(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async deleteTreinamento(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteTreinamento(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Treinamento excluido com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], TreinamentoModule.prototype, "setTreinamentos", null);
__decorate([
    Action
], TreinamentoModule.prototype, "createTreinamento", null);
__decorate([
    Action
], TreinamentoModule.prototype, "updateTreinamento", null);
__decorate([
    Action
], TreinamentoModule.prototype, "getTreinamentos", null);
__decorate([
    Action
], TreinamentoModule.prototype, "getTreinamento", null);
__decorate([
    Action
], TreinamentoModule.prototype, "deleteTreinamento", null);
TreinamentoModule = __decorate([
    Module({ name: 'treinamento' })
], TreinamentoModule);
export default TreinamentoModule;
