import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let FinanceiroParcelaMovimentacaoModule = class FinanceiroParcelaMovimentacaoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.financeiroParcelaMovimentacoes = [];
    }
    setFinanceiroParcelaMovimentacoes(payload) {
        this.financeiroParcelaMovimentacoes = payload;
    }
    async createFinanceiroParcelaMovimentacao(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createFinanceiroParcelaMovimentacao(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Parcela movimentação criada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async createFinanceiroParcelaMovimentacaoTitulos(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.createFinanceiroParcelaMovimentacaoTitulos(mainStore.token, payload);
            if (result) {
                mainStore.removeNotification(loadingNotification);
                mainStore.addNotification({
                    content: 'Titulos baixados com sucesso',
                    color: 'success',
                });
                return result.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async createFinanceiroParcelaMovimentacaoAnexos(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createFinanceiroParcelaMovimentacaoAnexos(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Anexos inseridos com sucesso',
                color: 'success',
            });
            return result[0].data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteFinanceiroParcelaMovimentacaoAnexos(file_name) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.deleteFinanceiroParcelaMovimentacaoAnexos(mainStore.token, file_name),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Anexo excluído com sucesso',
                color: 'success',
            });
            return result[0].data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateFinanceiroParcelaMovimentacao(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateFinanceiroParcelaMovimentacao(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Parcela movimentação atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getFinanceiroParcelaMovimentacao(id) {
        try {
            const response = await api.getFinanceiroParcelaMovimentacao(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getFinanceiroParcelaMovimentacoes(payload) {
        try {
            const response = await api.getFinanceiroParcelaMovimentacoes(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getFinanceiroParcelaMovimentacoesByParcelaId(parcelaId) {
        try {
            const response = await api.getFinanceiroParcelaMovimentacoesByParcelaId(mainStore.token, parcelaId);
            if (response) {
                this.setFinanceiroParcelaMovimentacoes(response.data);
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteFinanceiroParcelaMovimentacao(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteFinanceiroParcelaMovimentacao(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Movimentação excluida com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async validateCodigoTerminal(payload) {
        try {
            const response = await api.validateCodigoTerminal(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getFinanceiroParcelaMovimentacaoAnexos(payload) {
        try {
            const response = await api.getFinanceiroParcelaMovimentacaoAnexos(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getFinanceiroParcelaMovimentacaoAnexo(payload) {
        try {
            const response = await api.getFinanceiroParcelaMovimentacaoAnexo(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async estornar(id) {
        try {
            const response = await api.estornarFinanceiroParcelaMovimentacao(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getFinanceiroParcelaMovimentacaoQuery(queryIn) {
        try {
            const response = await api.getFinanceiroParcelaMovimentacaoQuery(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], FinanceiroParcelaMovimentacaoModule.prototype, "setFinanceiroParcelaMovimentacoes", null);
__decorate([
    Action
], FinanceiroParcelaMovimentacaoModule.prototype, "createFinanceiroParcelaMovimentacao", null);
__decorate([
    Action
], FinanceiroParcelaMovimentacaoModule.prototype, "createFinanceiroParcelaMovimentacaoTitulos", null);
__decorate([
    Action
], FinanceiroParcelaMovimentacaoModule.prototype, "createFinanceiroParcelaMovimentacaoAnexos", null);
__decorate([
    Action
], FinanceiroParcelaMovimentacaoModule.prototype, "deleteFinanceiroParcelaMovimentacaoAnexos", null);
__decorate([
    Action
], FinanceiroParcelaMovimentacaoModule.prototype, "updateFinanceiroParcelaMovimentacao", null);
__decorate([
    Action
], FinanceiroParcelaMovimentacaoModule.prototype, "getFinanceiroParcelaMovimentacao", null);
__decorate([
    Action
], FinanceiroParcelaMovimentacaoModule.prototype, "getFinanceiroParcelaMovimentacoes", null);
__decorate([
    Action
], FinanceiroParcelaMovimentacaoModule.prototype, "getFinanceiroParcelaMovimentacoesByParcelaId", null);
__decorate([
    Action
], FinanceiroParcelaMovimentacaoModule.prototype, "deleteFinanceiroParcelaMovimentacao", null);
__decorate([
    Action
], FinanceiroParcelaMovimentacaoModule.prototype, "validateCodigoTerminal", null);
__decorate([
    Action
], FinanceiroParcelaMovimentacaoModule.prototype, "getFinanceiroParcelaMovimentacaoAnexos", null);
__decorate([
    Action
], FinanceiroParcelaMovimentacaoModule.prototype, "getFinanceiroParcelaMovimentacaoAnexo", null);
__decorate([
    Action
], FinanceiroParcelaMovimentacaoModule.prototype, "estornar", null);
__decorate([
    Action
], FinanceiroParcelaMovimentacaoModule.prototype, "getFinanceiroParcelaMovimentacaoQuery", null);
FinanceiroParcelaMovimentacaoModule = __decorate([
    Module({ name: 'financeiroParcelaMovimentacao' })
], FinanceiroParcelaMovimentacaoModule);
export default FinanceiroParcelaMovimentacaoModule;
