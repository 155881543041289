import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
// import { DocumentoFiscal, CompraCreate, CompraUpdate } from '@/interfaces/documentoFiscal';
import { mainStore } from '@/utils/store-accessor';
let FinanceiroCategoriaModule = class FinanceiroCategoriaModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.FinanceiroCategorias = [];
        this.FinanceiroCategoriasTreeview = [];
    }
    setFinanceiroCategorias(payload) {
        this.FinanceiroCategorias = payload;
    }
    setFinanceiroCategoriasTreeview(payload) {
        this.FinanceiroCategoriasTreeview = payload;
    }
    async getFinanceiroCategorias() {
        try {
            const response = await api.getFinanceiroCategorias(mainStore.token);
            if (response) {
                this.setFinanceiroCategorias(response.data);
            }
        }
        catch (e) {
            await mainStore.checkApiError(e);
        }
    }
    async getFinanceiroCategoriasTreeview() {
        try {
            const response = await api.getFinanceiroCategoriasTreeview(mainStore.token);
            if (response) {
                this.setFinanceiroCategoriasTreeview(response.data);
            }
        }
        catch (e) {
            await mainStore.checkApiError(e);
        }
    }
    async createFinanceiroCategorias(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createFinanceiroCategoria(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Categoria criada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateFinanceiroCategoria(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateFinanceiroCategoria(payload, mainStore.token),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Categoria de financeiro atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteFinanceiroCategoria(id) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            await Promise.all([
                api.deleteFinanceiroCategoria(id, mainStore.token),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Categoria de financeiro removida com sucesso',
                color: 'success',
            });
            return true;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async financeiroCategoriaRestaurarPadrao(tipo) {
        try {
            const response = await api.restaurarPadraoTipo(mainStore.token, tipo);
            if (response) {
                const configuracoes = response.data;
                this.setFinanceiroCategoriasTreeview(configuracoes);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], FinanceiroCategoriaModule.prototype, "setFinanceiroCategorias", null);
__decorate([
    Mutation
], FinanceiroCategoriaModule.prototype, "setFinanceiroCategoriasTreeview", null);
__decorate([
    Action
], FinanceiroCategoriaModule.prototype, "getFinanceiroCategorias", null);
__decorate([
    Action
], FinanceiroCategoriaModule.prototype, "getFinanceiroCategoriasTreeview", null);
__decorate([
    Action
], FinanceiroCategoriaModule.prototype, "createFinanceiroCategorias", null);
__decorate([
    Action
], FinanceiroCategoriaModule.prototype, "updateFinanceiroCategoria", null);
__decorate([
    Action
], FinanceiroCategoriaModule.prototype, "deleteFinanceiroCategoria", null);
__decorate([
    Action
], FinanceiroCategoriaModule.prototype, "financeiroCategoriaRestaurarPadrao", null);
FinanceiroCategoriaModule = __decorate([
    Module({ name: 'financeiroCategoria' })
], FinanceiroCategoriaModule);
export default FinanceiroCategoriaModule;
