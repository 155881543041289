import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { newParceriaCupomHistorico } from '@/interfaces/parceriaCupomHistorico';
import { mainStore } from '@/utils/store-accessor';
let ParceriaCupomHistoricoModule = class ParceriaCupomHistoricoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.parceriaCuponsHistorico = [];
        this.parceriaCupomHistorico = newParceriaCupomHistorico();
    }
    setParceriaCuponsHistorico(payload) {
        this.parceriaCuponsHistorico = payload;
    }
    setParceriaCupomHistorico(payload) {
        this.parceriaCupomHistorico = payload;
    }
    async getParceriaCupomHistorico(payload) {
        try {
            const response = await api.getParceriaCupomHistorico(mainStore.token, payload);
            if (response) {
                this.setParceriaCuponsHistorico(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getParceriaCupomHistoricoByParceriaCupomId(parceriaId) {
        try {
            const response = await api.getParceriaCupomHistoricoByParceriaCupomId(mainStore.token, parceriaId);
            return response.data;
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createParceriaCupomHistorico(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createParceriaCupomHistorico(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Operação efetuada com sucesso',
                color: 'success',
            });
            return result[0].data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getParceriaCupomHistoricoPeriodo(data) {
        try {
            const response = await api.getParceriaCupomHistoricoPeriodo(mainStore.token, data);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getParceriaCupomHistoricoQuery(queryIn) {
        try {
            const response = await api.getParceriaCupomHistoricoQuery(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getParceriaCuponsAgendados(data) {
        try {
            const response = await api.getParceriaCuponsAgendados(mainStore.token, data);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], ParceriaCupomHistoricoModule.prototype, "setParceriaCuponsHistorico", null);
__decorate([
    Mutation
], ParceriaCupomHistoricoModule.prototype, "setParceriaCupomHistorico", null);
__decorate([
    Action
], ParceriaCupomHistoricoModule.prototype, "getParceriaCupomHistorico", null);
__decorate([
    Action
], ParceriaCupomHistoricoModule.prototype, "getParceriaCupomHistoricoByParceriaCupomId", null);
__decorate([
    Action
], ParceriaCupomHistoricoModule.prototype, "createParceriaCupomHistorico", null);
__decorate([
    Action
], ParceriaCupomHistoricoModule.prototype, "getParceriaCupomHistoricoPeriodo", null);
__decorate([
    Action
], ParceriaCupomHistoricoModule.prototype, "getParceriaCupomHistoricoQuery", null);
__decorate([
    Action
], ParceriaCupomHistoricoModule.prototype, "getParceriaCuponsAgendados", null);
ParceriaCupomHistoricoModule = __decorate([
    Module({ name: 'parceriaCupomHistorico' })
], ParceriaCupomHistoricoModule);
export default ParceriaCupomHistoricoModule;
