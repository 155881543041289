import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let PixModule = class PixModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.nfses = [];
    }
    async setNotasNfseEmitir(payload) {
        this.nfses = payload;
    }
    async pixCobrancaCriar(payload) {
        try {
            const response = await api.pixCobrancaCriar(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            return { error: error.response.data.detail };
        }
    }
    async pixCobrancaConsultarStatus(txid) {
        try {
            const response = await api.pixCobrancaConsulta(mainStore.token, txid);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async pixObterDados(e2eid) {
        try {
            const response = await api.pixConsulta(mainStore.token, e2eid);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async pixGerarCertificadoCsr(payload) {
        try {
            const response = await api.pixGerarCertificadoCsr(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async pixSincronizarRecebimentos(payload) {
        const loadingNotification = { content: 'Solicitando sincronização', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.pixSincronizarRecebimentos(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Sincronização solicitada com sucesso',
                color: 'success',
            });
            return result[0].data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async pixListarRecebimentos(payload) {
        const loadingNotification = { content: 'Carregando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.pixListarRecebimentos(mainStore.token, payload);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Dados carregados com sucesso',
                color: 'success',
            });
            this.setNotasNfseEmitir(result.data);
            return result.data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async pixCobrancaConsultar(payload) {
        try {
            const response = await api.pixCobrancaConsultar(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async pixConsultar(payload) {
        try {
            const response = await api.pixConsultar(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async atualizaStatusPlugnotas(item) {
        const items = this.nfses;
        const index = items.findIndex((nota) => nota.financeiro_parcela_movimentacao_id === item.id);
        items[index].plugnotas_status = item.plugnotas_status;
        this.setNotasNfseEmitir(items);
    }
};
__decorate([
    Mutation
], PixModule.prototype, "setNotasNfseEmitir", null);
__decorate([
    Action
], PixModule.prototype, "pixCobrancaCriar", null);
__decorate([
    Action
], PixModule.prototype, "pixCobrancaConsultarStatus", null);
__decorate([
    Action
], PixModule.prototype, "pixObterDados", null);
__decorate([
    Action
], PixModule.prototype, "pixGerarCertificadoCsr", null);
__decorate([
    Action
], PixModule.prototype, "pixSincronizarRecebimentos", null);
__decorate([
    Action
], PixModule.prototype, "pixListarRecebimentos", null);
__decorate([
    Action
], PixModule.prototype, "pixCobrancaConsultar", null);
__decorate([
    Action
], PixModule.prototype, "pixConsultar", null);
__decorate([
    Mutation
], PixModule.prototype, "atualizaStatusPlugnotas", null);
PixModule = __decorate([
    Module({ name: 'pix' })
], PixModule);
export default PixModule;
