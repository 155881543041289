import { __decorate } from "tslib";
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mainStore } from '@/utils/store-accessor';
let NotificationsManager = class NotificationsManager extends Vue {
    constructor() {
        super(...arguments);
        this.show = false;
        this.text = '';
        this.showProgress = false;
        this.currentNotification = false;
    }
    async hide() {
        this.show = false;
        await new Promise((resolve, reject) => setTimeout(() => resolve(), 500));
        await this.removeCurrentNotification();
        this.show = false;
    }
    async close() {
        await this.hide();
        // await this.removeCurrentNotification();
    }
    async removeCurrentNotification() {
        if (this.currentNotification) {
            await mainStore.removeNotification(this.currentNotification);
        }
    }
    get firstNotification() {
        return mainStore.firstNotification;
        // return readFirstNotification(this.$store);
    }
    async setNotification(notification) {
        if (this.show) {
            await this.hide();
        }
        if (notification) {
            this.currentNotification = notification;
            this.showProgress = notification.showProgress || false;
            this.show = true;
        }
        else {
            this.currentNotification = false;
        }
    }
    async onNotificationChange(newNotification, oldNotification) {
        if (newNotification !== this.currentNotification) {
            await this.setNotification(newNotification);
            if (newNotification) {
                // mainStore.removeNotification({ notification: newNotification, timeout: 6500 });
                // @ts-ignore
                mainStore.removeNotification(oldNotification);
            }
        }
    }
    get currentNotificationContent() {
        return (this.currentNotification && this.currentNotification.content) || '';
    }
    get currentNotificationColor() {
        return ((this.currentNotification && this.currentNotification.color) || 'info');
    }
};
__decorate([
    Watch('firstNotification')
], NotificationsManager.prototype, "onNotificationChange", null);
NotificationsManager = __decorate([
    Component
], NotificationsManager);
export default NotificationsManager;
