import Vue from 'vue';
import VeeValidate from 'vee-validate';
// @ts-ignore
VeeValidate.ValidationObserver.methods.validate = async function (ref) {
    if (ref === void 0) {
        ref = { silent: false };
    }
    const silent = ref.silent;
    const result = await Promise.all(Object.values(this.refs).map((reference) => {
        return reference[silent ? 'validateSilent' : 'validate']().then((r) => {
            return r.valid;
        });
    }).concat(this.observers.map((obs) => {
        return obs.validate({ silent });
    }))).then((results) => {
        return results.every((r) => {
            return r;
        });
    });
    const errors = Object.values(this.ctx.errors).flat();
    const uniqErrors = [];
    errors.forEach((err) => {
        let found = false;
        for (const toast of this.$toasted.toasts) {
            if (toast.el.innerText === err) {
                found = true;
                return;
            }
        }
        this.$toasted.show(err, {
            type: 'error',
            position: 'top-right',
            duration: 3000,
            icon: 'mdi-alert-circle',
            iconPack: 'mdi',
            theme: 'bubble',
            action: {
                icon: 'mdi-close',
                text: '',
                onClick: (e, toastObj) => {
                    toastObj.goAway(0);
                }
            },
            className: 'toast-style',
            containerClass: 'toast-container'
        });
    });
    return result;
};
Vue.use(VeeValidate);
