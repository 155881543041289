import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { analyticsStore, mainStore } from '@/utils/store-accessor';
let LogModule = class LogModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.nomeSistema = `DRIVE_`;
    }
    async filtroPorTabelaBuscaFiltroSessao(tableName) {
        if (!tableName || tableName === '') {
            const headers = analyticsStore.headers;
            this.filtroPorTabelaAtualizarHeader(headers);
            return;
        }
        const userId = mainStore.userProfile.id;
        const filtrosKey = `${this.nomeSistema}${userId}_${tableName}`;
        const filtrosKeysStr = localStorage.getItem(filtrosKey);
        if (!filtrosKeysStr || JSON.parse(localStorage.getItem(filtrosKey)).length === 0) {
            const headers = analyticsStore.headers;
            this.filtroPorTabelaAtualizarHeader(headers);
            return;
        }
        const items = await JSON.parse(localStorage.getItem(filtrosKey));
        this.filtroPorTabelaAtualizarHeader(items);
    }
    async filtroPorTabelaGravarFiltroSessao(payload) {
        const userId = mainStore.userProfile.id;
        const filtrosKey = `${this.nomeSistema}${userId}_${payload.tableName}`;
        localStorage.setItem(`${filtrosKey}`, JSON.stringify(payload.items));
        this.filtroPorTabelaAtualizarHeader(payload.items);
    }
    async filtroPorTabelaGet(tableName) {
        try {
            const response = await api.filtroPorTabelaGet(mainStore.token, tableName);
            if (response) {
                const newItems = response.data.map((i) => {
                    return {
                        show: true,
                        value: i.column_name
                    };
                });
                this.filtroPorTabelaGravarFiltroSessao({ tableName, items: newItems });
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async filtroPorTabelaCreate(payload) {
        try {
            const response = await api.filtroPorTabelaCreate(mainStore.token, payload);
            if (response) {
                if (payload.table_name) {
                    this.filtroPorTabelaGravarFiltroSessao({ tableName: payload.table_name, items: payload.items });
                }
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    filtroPorTabelaAtualizarHeader(headerItemsSessao) {
        const headers = analyticsStore.headers;
        if (!(headerItemsSessao.length > 0)) {
            analyticsStore.setHeaders(headers);
            return;
        }
        const items = headers.map((propItem) => {
            const findItem = headerItemsSessao.find((headerItem) => headerItem.value === propItem.value);
            return { ...propItem, show: !!findItem };
        });
        analyticsStore.setHeaders(items);
    }
};
__decorate([
    Action
], LogModule.prototype, "filtroPorTabelaBuscaFiltroSessao", null);
__decorate([
    Action
], LogModule.prototype, "filtroPorTabelaGravarFiltroSessao", null);
__decorate([
    Action
], LogModule.prototype, "filtroPorTabelaGet", null);
__decorate([
    Action
], LogModule.prototype, "filtroPorTabelaCreate", null);
__decorate([
    Action
], LogModule.prototype, "filtroPorTabelaAtualizarHeader", null);
LogModule = __decorate([
    Module({ name: 'filtroPorTabela' })
], LogModule);
export default LogModule;
