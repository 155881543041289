import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let ProspexModule = class ProspexModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.prospexs = [];
        this.atendimentos = [];
        this.dashboard = {};
    }
    setProspexs(payload) {
        this.prospexs = payload;
    }
    setDashboard(payload) {
        this.dashboard = payload;
    }
    setProspex(payload) {
        const prospexs = this.prospexs.filter((company) => company.id !== payload.id);
        prospexs.push(payload);
        this.prospexs = prospexs;
    }
    async createProspex(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createProspex(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Prospex criado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            let msg = '';
            if (error.detail) {
                msg = error.detail;
            }
            else {
                msg = error;
            }
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateProspex(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateProspex(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Prospex atualizado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteProspex(id) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.deleteProspex(mainStore.token, id),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Prospex deletado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getDashboard() {
        try {
            const response = await api.getProspexDashboard(mainStore.token);
            if (response) {
                this.setDashboard(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getProspexs(queryIn) {
        try {
            const response = await api.getProspexs(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async registerProspexVisit(payload) {
        try {
            const response = await api.registerProspexVisit(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getExistingProspex(payload) {
        try {
            const response = await api.getExistingProspex(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getProspex(id) {
        try {
            const response = await api.getProspex(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAtendimentos() {
        try {
            const response = await api.getProspexAtendimentos(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], ProspexModule.prototype, "setProspexs", null);
__decorate([
    Mutation
], ProspexModule.prototype, "setDashboard", null);
__decorate([
    Mutation
], ProspexModule.prototype, "setProspex", null);
__decorate([
    Action
], ProspexModule.prototype, "createProspex", null);
__decorate([
    Action
], ProspexModule.prototype, "updateProspex", null);
__decorate([
    Action
], ProspexModule.prototype, "deleteProspex", null);
__decorate([
    Action
], ProspexModule.prototype, "getDashboard", null);
__decorate([
    Action
], ProspexModule.prototype, "getProspexs", null);
__decorate([
    Action
], ProspexModule.prototype, "registerProspexVisit", null);
__decorate([
    Action
], ProspexModule.prototype, "getExistingProspex", null);
__decorate([
    Action
], ProspexModule.prototype, "getProspex", null);
__decorate([
    Action
], ProspexModule.prototype, "getAtendimentos", null);
ProspexModule = __decorate([
    Module({ name: 'prospex' })
], ProspexModule);
export default ProspexModule;
