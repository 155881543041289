const env = process.env.VUE_APP_ENV;
let envApiUrl = '';
if (env === 'production') {
    envApiUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
}
else if (env === 'staging') {
    envApiUrl = `https://${process.env.VUE_APP_DOMAIN_STAG}`;
}
else {
    envApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}`;
}
// envApiUrl = `http://192.168.1.103`;
export const apiUrl = envApiUrl;
export const appName = process.env.VUE_APP_NAME;
