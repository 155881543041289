import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let CompanyConfiguracaoIntegracaoBancariaModule = class CompanyConfiguracaoIntegracaoBancariaModule extends VuexModule {
    async createCompanyConfiguracaoIntegracaoBancaria(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createCompanyConfiguracaoIntegracaoBancaria(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Configuração criada com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateCompanyConfiguracaoIntegracaoBancaria(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = api.updateCompanyConfiguracaoIntegracaoBancaria(mainStore.token, payload);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Configuração atualizada com sucesso',
                color: 'success',
            });
            return;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getCompanyConfiguracaoIntegracaoBancarias(companyId) {
        try {
            const response = await api.getCompanyConfiguracaoIntegracaoBancarias(mainStore.token, companyId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCompanyConfiguracaoIntegracaoBancariasByContaBancariaId(companyId) {
        try {
            const response = await api.getCompanyConfiguracaoIntegracaoBancariasByContaBancariaId(mainStore.token, companyId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCompanyConfiguracaoIntegracaoBancaria(id) {
        try {
            const response = await api.getCompanyConfiguracaoIntegracaoBancaria(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCompanyConfiguracaoIntegracaoBancariaAtiva() {
        try {
            const response = await api.getCompanyConfiguracaoIntegracaoBancariaAtiva(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCompanyConfiguracaoIntegracaoBancariaAtivaAdm() {
        try {
            const response = await api.getCompanyConfiguracaoIntegracaoBancariaAtivaAdm(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateCompanyConfiguracaoIntegracaoBancariaCertificados() {
        try {
            const response = await api.updateCompanyConfiguracaoIntegracaoBancariaCertificados(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async syncCompanyConfiguracaoIntegracaoBancariaCertificados() {
        try {
            const response = await api.syncCompanyConfiguracaoIntegracaoBancariaCertificados(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async deleteCompanyConfiguracaoIntegracaoBancaria(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteCompanyConfiguracaoIntegracaoBancaria(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Configuração excluida com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async gerarCertificadoCompanyConfiguracaoIntegracaoBancaria(id) {
        const loadingNotification = { content: 'Configurando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.gerarCertificadoCompanyConfiguracaoIntegracaoBancaria(mainStore.token, id),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Configuração efetuada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
        }
    }
    async cadastrarWebhookCompanyConfiguracaoIntegracaoBancaria(companyConfiguracaoIntegracaoBancariaId) {
        const loadingNotification = { content: 'Configurando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.cadastrarWebhookCompanyConfiguracaoIntegracaoBancaria(mainStore.token, companyConfiguracaoIntegracaoBancariaId),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: result[0].data,
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
        }
    }
    async excluirWebhookCompanyConfiguracaoIntegracaoBancaria(companyConfiguracaoIntegracaoBancariaId) {
        const loadingNotification = { content: 'Configurando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.excluirWebhookCompanyConfiguracaoIntegracaoBancaria(mainStore.token, companyConfiguracaoIntegracaoBancariaId),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: result[0].data,
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
        }
    }
    async consultarWebhookCompanyConfiguracaoIntegracaoBancaria(companyConfiguracaoIntegracaoBancariaId) {
        const loadingNotification = { content: 'Consultando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.consultarWebhookCompanyConfiguracaoIntegracaoBancaria(mainStore.token, companyConfiguracaoIntegracaoBancariaId),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Configuração efetuada com sucesso',
                color: 'success',
            });
            return result[0].data;
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
        }
    }
    async tokenCompanyConfiguracaoIntegracaoBancaria(payload) {
        const loadingNotification = { content: 'Configurando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.tokenCompanyConfiguracaoIntegracaoBancaria(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: result[0].data,
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], CompanyConfiguracaoIntegracaoBancariaModule.prototype, "createCompanyConfiguracaoIntegracaoBancaria", null);
__decorate([
    Action
], CompanyConfiguracaoIntegracaoBancariaModule.prototype, "updateCompanyConfiguracaoIntegracaoBancaria", null);
__decorate([
    Action
], CompanyConfiguracaoIntegracaoBancariaModule.prototype, "getCompanyConfiguracaoIntegracaoBancarias", null);
__decorate([
    Action
], CompanyConfiguracaoIntegracaoBancariaModule.prototype, "getCompanyConfiguracaoIntegracaoBancariasByContaBancariaId", null);
__decorate([
    Action
], CompanyConfiguracaoIntegracaoBancariaModule.prototype, "getCompanyConfiguracaoIntegracaoBancaria", null);
__decorate([
    Action
], CompanyConfiguracaoIntegracaoBancariaModule.prototype, "getCompanyConfiguracaoIntegracaoBancariaAtiva", null);
__decorate([
    Action
], CompanyConfiguracaoIntegracaoBancariaModule.prototype, "getCompanyConfiguracaoIntegracaoBancariaAtivaAdm", null);
__decorate([
    Action
], CompanyConfiguracaoIntegracaoBancariaModule.prototype, "updateCompanyConfiguracaoIntegracaoBancariaCertificados", null);
__decorate([
    Action
], CompanyConfiguracaoIntegracaoBancariaModule.prototype, "syncCompanyConfiguracaoIntegracaoBancariaCertificados", null);
__decorate([
    Action
], CompanyConfiguracaoIntegracaoBancariaModule.prototype, "deleteCompanyConfiguracaoIntegracaoBancaria", null);
__decorate([
    Action
], CompanyConfiguracaoIntegracaoBancariaModule.prototype, "gerarCertificadoCompanyConfiguracaoIntegracaoBancaria", null);
__decorate([
    Action
], CompanyConfiguracaoIntegracaoBancariaModule.prototype, "cadastrarWebhookCompanyConfiguracaoIntegracaoBancaria", null);
__decorate([
    Action
], CompanyConfiguracaoIntegracaoBancariaModule.prototype, "excluirWebhookCompanyConfiguracaoIntegracaoBancaria", null);
__decorate([
    Action
], CompanyConfiguracaoIntegracaoBancariaModule.prototype, "consultarWebhookCompanyConfiguracaoIntegracaoBancaria", null);
__decorate([
    Action
], CompanyConfiguracaoIntegracaoBancariaModule.prototype, "tokenCompanyConfiguracaoIntegracaoBancaria", null);
CompanyConfiguracaoIntegracaoBancariaModule = __decorate([
    Module({ name: 'companyConfiguracaoIntegracaoBancaria' })
], CompanyConfiguracaoIntegracaoBancariaModule);
export default CompanyConfiguracaoIntegracaoBancariaModule;
