import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let CargoModule = class CargoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.cargos = [];
    }
    setCargos(payload) {
        this.cargos = payload;
    }
    async getCargos() {
        this.setCargos([
            { id: 1, nome: 'Professor' },
            { id: 2, nome: 'Coordenador/Gerente' },
            { id: 3, nome: 'Consultor' },
            { id: 4, nome: 'Operacional' },
            // @ts-ignore
            { id: 5, nome: mainStore.userProfile.company.escola_id == 1 ? 'CDA' : 'SDR' },
            { id: 6, nome: 'Administrador' },
        ]);
        // nao precisa bater no banco
        // try {
        //   const response = await api.getCargos(mainStore.token);
        //   if (response) {
        //     this.setCargos(response.data);
        //   }
        // } catch (error) {
        //   await mainStore.checkApiError(error);
        // }
    }
    async getCargoPermissoes(cargoId) {
        try {
            const response = await api.getCargoPermissoes(mainStore.token, cargoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], CargoModule.prototype, "setCargos", null);
__decorate([
    Action
], CargoModule.prototype, "getCargos", null);
__decorate([
    Action
], CargoModule.prototype, "getCargoPermissoes", null);
CargoModule = __decorate([
    Module({ name: 'cargo' })
], CargoModule);
export default CargoModule;
