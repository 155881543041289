import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let DiagnosticoModule = class DiagnosticoModule extends VuexModule {
    // public Cronogramas = [];
    // @Mutation
    // public setCronogramas(payload) {
    //   this.Cronogramas = payload;
    // }
    async getDiagnostico() {
        try {
            const response = await api.getDiagnostico(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDiagnosticoPedagogico() {
        try {
            const response = await api.getDiagnosticoPedagogico(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDiagnosticoPedagogicoAcoes() {
        try {
            const response = await api.getDiagnosticoPedagogicoAcoes(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDiagnosticoPedagogicoAcoesIndex(index) {
        try {
            const response = await api.getDiagnosticoPedagogicoAcoesIndex(mainStore.token, index);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDiagnosticoFinanceiro() {
        try {
            const response = await api.getDiagnosticoFinanceiro(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDiagnosticoComercial(payload) {
        try {
            const response = await api.getDiagnosticoComercial(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], DiagnosticoModule.prototype, "getDiagnostico", null);
__decorate([
    Action
], DiagnosticoModule.prototype, "getDiagnosticoPedagogico", null);
__decorate([
    Action
], DiagnosticoModule.prototype, "getDiagnosticoPedagogicoAcoes", null);
__decorate([
    Action
], DiagnosticoModule.prototype, "getDiagnosticoPedagogicoAcoesIndex", null);
__decorate([
    Action
], DiagnosticoModule.prototype, "getDiagnosticoFinanceiro", null);
__decorate([
    Action
], DiagnosticoModule.prototype, "getDiagnosticoComercial", null);
DiagnosticoModule = __decorate([
    Module({ name: 'diagnostico' })
], DiagnosticoModule);
export default DiagnosticoModule;
