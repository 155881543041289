import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let FinanceiroCategoriaJurosDescontoModule = class FinanceiroCategoriaJurosDescontoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.financeiroCategoriasJurosDesconto = [];
    }
    setFinanceiroCategoriasJurosDesconto(payload) {
        this.financeiroCategoriasJurosDesconto = payload;
    }
    renderFinanceiroCategoriasJurosDesconto() {
        this.financeiroCategoriasJurosDesconto = { ...this.financeiroCategoriasJurosDesconto };
    }
    async getFinanceiroCategoriasJurosDescontoStore() {
        try {
            const response = await api.getFinanceiroCategoriasJurosDesconto(mainStore.token);
            if (response) {
                this.setFinanceiroCategoriasJurosDesconto(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateFinanceiroCategoriasJurosDescontoStore(payload) {
        try {
            const response = await api.updateFinanceiroCategoriasJurosDesconto(payload, mainStore.token);
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], FinanceiroCategoriaJurosDescontoModule.prototype, "setFinanceiroCategoriasJurosDesconto", null);
__decorate([
    Mutation
], FinanceiroCategoriaJurosDescontoModule.prototype, "renderFinanceiroCategoriasJurosDesconto", null);
__decorate([
    Action
], FinanceiroCategoriaJurosDescontoModule.prototype, "getFinanceiroCategoriasJurosDescontoStore", null);
__decorate([
    Action
], FinanceiroCategoriaJurosDescontoModule.prototype, "updateFinanceiroCategoriasJurosDescontoStore", null);
FinanceiroCategoriaJurosDescontoModule = __decorate([
    Module({ name: 'financeiroCategoriaJurosDesconto' })
], FinanceiroCategoriaJurosDescontoModule);
export default FinanceiroCategoriaJurosDescontoModule;
