import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let CampanhaModule = class CampanhaModule extends VuexModule {
    async createCampanha(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createCampanha(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Campanha criado com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateCampanha(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateCampanha(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Campanha atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getCampanhas() {
        try {
            const response = await api.getCampanhas(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCampanha(id) {
        try {
            const response = await api.getCampanha(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async deleteCampanha(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = { data: '' };
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'campanha excluida com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async sincronizarCampanha(campanhaId) {
        const loadingNotification = { content: 'Sincronizando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.sincronizarCampanha(mainStore.token, campanhaId),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Sincronização efetuada com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], CampanhaModule.prototype, "createCampanha", null);
__decorate([
    Action
], CampanhaModule.prototype, "updateCampanha", null);
__decorate([
    Action
], CampanhaModule.prototype, "getCampanhas", null);
__decorate([
    Action
], CampanhaModule.prototype, "getCampanha", null);
__decorate([
    Action
], CampanhaModule.prototype, "deleteCampanha", null);
__decorate([
    Action
], CampanhaModule.prototype, "sincronizarCampanha", null);
CampanhaModule = __decorate([
    Module({ name: 'campanhas' })
], CampanhaModule);
export default CampanhaModule;
