import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let SefazModule = class SefazModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.statusServicos = [];
    }
    setStatusServicos(payload) {
        this.statusServicos = payload;
    }
    setDadosCadastro(payload) {
        this.dadosCadastro = payload;
    }
    async getStatusServicos() {
        try {
            const response = await api.getStatusServicos(mainStore.token);
            if (response) {
                this.setStatusServicos(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getDadosCadastro() {
        try {
            const response = await api.getDadosCadastro(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getConsultaDistribuicao() {
        try {
            const response = await api.getConsultaDistribuicao(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], SefazModule.prototype, "setStatusServicos", null);
__decorate([
    Mutation
], SefazModule.prototype, "setDadosCadastro", null);
__decorate([
    Action
], SefazModule.prototype, "getStatusServicos", null);
__decorate([
    Action
], SefazModule.prototype, "getDadosCadastro", null);
__decorate([
    Action
], SefazModule.prototype, "getConsultaDistribuicao", null);
SefazModule = __decorate([
    Module({ name: 'sefaz' })
], SefazModule);
export default SefazModule;
