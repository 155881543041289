import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let EstoqueModule = class EstoqueModule extends VuexModule {
    async getEstoque() {
        try {
            const response = await api.getEstoque(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getHistorico(id) {
        try {
            const response = await api.getEstoqueHistorico(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createEstoqueXml(payload) {
        try {
            const response = await api.createEstoqueXml(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createEstoque(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createEstoque(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Operação efetuada com sucesso',
                color: 'success',
            });
            return result[0].data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async createAjusteEstoque(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createAjusteEstoque(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Operação efetuada com sucesso',
                color: 'success',
            });
            return result[0].data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], EstoqueModule.prototype, "getEstoque", null);
__decorate([
    Action
], EstoqueModule.prototype, "getHistorico", null);
__decorate([
    Action
], EstoqueModule.prototype, "createEstoqueXml", null);
__decorate([
    Action
], EstoqueModule.prototype, "createEstoque", null);
__decorate([
    Action
], EstoqueModule.prototype, "createAjusteEstoque", null);
EstoqueModule = __decorate([
    Module({ name: 'estoque' })
], EstoqueModule);
export default EstoqueModule;
