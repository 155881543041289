import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let CaixaModule = class CaixaModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.caixas = [];
    }
    setCaixas(payload) {
        this.caixas = payload;
    }
    async getCaixas(payload) {
        try {
            const response = await api.getCaixas(mainStore.token, payload);
            if (response) {
                this.setCaixas(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCaixa(caixaId) {
        try {
            const response = await api.getCaixa(mainStore.token, caixaId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCaixaMovimentacao(caixaId) {
        try {
            const response = await api.getCaixaMovimentacao(mainStore.token, caixaId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async abrirCaixa(payload) {
        const loadingNotification = { content: 'Abrindo o caixa', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                await api.abrirCaixa(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Caixa aberto com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateCaixa(payload) {
        const loadingNotification = { content: 'Atualizado o caixa', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                await api.updateCaixa(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Caixa conferido com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateCaixaMovimentacaoDetalhamento(payload) {
        const loadingNotification = { content: 'Atualizado o caixa movimentação detalhamento', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                await api.updateCaixaMovimentacaoDetalhamento(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Caixa Movimentação Detalhamento atualizado com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async fazerLancamento(payload) {
        const loadingNotification = { content: 'Efetuando o lançamento', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                await api.caixaEfetuarLancamento(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Lançamento efetuado com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async imprimirCaixa(payload) {
        try {
            const response = await api.imprimirCaixa(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], CaixaModule.prototype, "setCaixas", null);
__decorate([
    Action
], CaixaModule.prototype, "getCaixas", null);
__decorate([
    Action
], CaixaModule.prototype, "getCaixa", null);
__decorate([
    Action
], CaixaModule.prototype, "getCaixaMovimentacao", null);
__decorate([
    Action
], CaixaModule.prototype, "abrirCaixa", null);
__decorate([
    Action
], CaixaModule.prototype, "updateCaixa", null);
__decorate([
    Action
], CaixaModule.prototype, "updateCaixaMovimentacaoDetalhamento", null);
__decorate([
    Action
], CaixaModule.prototype, "fazerLancamento", null);
__decorate([
    Action
], CaixaModule.prototype, "imprimirCaixa", null);
CaixaModule = __decorate([
    Module({ name: 'caixa' })
], CaixaModule);
export default CaixaModule;
