import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let EntidadeModule = class EntidadeModule extends VuexModule {
    // public entidades = [];
    // @Mutation
    // public setEntidades(payload) {
    //   this.entidades = payload;
    // }
    async getEntidadesByField(payload) {
        try {
            const response = await api.getEntidadesByField(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getEntidadesList(payload) {
        try {
            const response = await api.getEntidadesList(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], EntidadeModule.prototype, "getEntidadesByField", null);
__decorate([
    Action
], EntidadeModule.prototype, "getEntidadesList", null);
EntidadeModule = __decorate([
    Module({ name: 'entidade' })
], EntidadeModule);
export default EntidadeModule;
