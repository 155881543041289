import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let TicketModule = class TicketModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.tickets = [];
    }
    setTickets(payload) {
        this.tickets = payload;
    }
    async getTickets() {
        try {
            const response = await api.getTickets(mainStore.token);
            if (response) {
                this.setTickets(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTicket(id) {
        try {
            const response = await api.getTicket(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createTicket(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createTicket(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Ticket criado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async createTicketNote(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createTicketNote(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Mensagem enviada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], TicketModule.prototype, "setTickets", null);
__decorate([
    Action
], TicketModule.prototype, "getTickets", null);
__decorate([
    Action
], TicketModule.prototype, "getTicket", null);
__decorate([
    Action
], TicketModule.prototype, "createTicket", null);
__decorate([
    Action
], TicketModule.prototype, "createTicketNote", null);
TicketModule = __decorate([
    Module({ name: 'ticket' })
], TicketModule);
export default TicketModule;
