import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let ParceriaCupomModule = class ParceriaCupomModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.parceriaCupons = [];
    }
    setParceriaCupons(payload) {
        this.parceriaCupons = payload;
    }
    setParceriaCupom(payload) {
        const parceriaCupons = this.parceriaCupons.filter((company) => company.id !== payload.id);
        parceriaCupons.push(payload);
        this.parceriaCupons = parceriaCupons;
    }
    async createParceriaCupom(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createParceriaCupom(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Cupom criado com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async createParceriaCupomN8N(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createParceriaCupomN8N(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Cupom criado com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    // @Action
    // public async landingPageVerifyTelefoneParceriaCupom(telefone: string) {
    //   try {
    //     const response = await api.landingPageVerifyTelefoneParceriaCupom(telefone);
    //     if (response) {
    //       return response.data;
    //     }
    //   } catch (error) {
    //     mainStore.addNotification({
    //       content: error.response.data.detail,
    //       color: 'error',
    //     });
    //     await mainStore.checkApiError(error);
    //   }
    // }
    async createParceriaCupomFromLandingPage(payload) {
        try {
            const response = await api.createParceriaCupomFromLandingPage(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateParceriaCupom(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateParceriaCupom(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Cupom atualizado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getParceriaCupons(payload = { parceriaId: 0, statusPendenteLigacao: true }) {
        try {
            const response = await api.getParceriaCupons(mainStore.token, payload);
            if (response) {
                this.setParceriaCupons(response.data);
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getParceriaCuponsQuery(queryIn) {
        try {
            const response = await api.getParceriaCuponsQuery(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getParceriaCupomByTelefone(telefone) {
        try {
            const response = await api.getParceriaCupomByTelefone(mainStore.token, telefone);
            if (response) {
                this.setParceriaCupons(response.data);
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getParceriaCupom(id) {
        try {
            const response = await api.getParceriaCupom(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getParceriaCupomByParceriaId(id) {
        try {
            const response = await api.getParceriaCupomByParceriaId(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getParceriaCupomByCda(id) {
        try {
            const response = await api.getParceriaCupomByCda(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async desvincularParceriaCupomCda(id) {
        const loadingNotification = { content: 'Desvinculando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.desvincularParceriaCupomCda(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Operação efetuada com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteParceriaCupom(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteParceriaCupom(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Cupom excluído com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], ParceriaCupomModule.prototype, "setParceriaCupons", null);
__decorate([
    Mutation
], ParceriaCupomModule.prototype, "setParceriaCupom", null);
__decorate([
    Action
], ParceriaCupomModule.prototype, "createParceriaCupom", null);
__decorate([
    Action
], ParceriaCupomModule.prototype, "createParceriaCupomN8N", null);
__decorate([
    Action
], ParceriaCupomModule.prototype, "createParceriaCupomFromLandingPage", null);
__decorate([
    Action
], ParceriaCupomModule.prototype, "updateParceriaCupom", null);
__decorate([
    Action
], ParceriaCupomModule.prototype, "getParceriaCupons", null);
__decorate([
    Action
], ParceriaCupomModule.prototype, "getParceriaCuponsQuery", null);
__decorate([
    Action
], ParceriaCupomModule.prototype, "getParceriaCupomByTelefone", null);
__decorate([
    Action
], ParceriaCupomModule.prototype, "getParceriaCupom", null);
__decorate([
    Action
], ParceriaCupomModule.prototype, "getParceriaCupomByParceriaId", null);
__decorate([
    Action
], ParceriaCupomModule.prototype, "getParceriaCupomByCda", null);
__decorate([
    Action
], ParceriaCupomModule.prototype, "desvincularParceriaCupomCda", null);
__decorate([
    Action
], ParceriaCupomModule.prototype, "deleteParceriaCupom", null);
ParceriaCupomModule = __decorate([
    Module({ name: 'parceriaCupom' })
], ParceriaCupomModule);
export default ParceriaCupomModule;
