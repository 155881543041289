export function queryNew() {
    return {
        fields: [],
        params: {},
        skip: 0,
        limit: 200,
    };
}
export function QueryHeaderItemNew() {
    return {
        text: '',
        value: '',
        table: '',
        show: false,
        type: 'str',
        field: '',
    };
}
