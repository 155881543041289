import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let CompanyConfiguracaoNegociacaoModule = class CompanyConfiguracaoNegociacaoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.companyConfiguracaoNegociacoesMultaRescisoriaContrato = [];
    }
    setCompanyConfiguracaoNegociacoesMultaRescisoriaContrato(payload) {
        this.companyConfiguracaoNegociacoesMultaRescisoriaContrato = payload;
    }
    async createCompanyConfiguracaoNegociacaoMultaRescisaoContrato(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createCompanyConfiguracaoNegociacaoMultaRescisaoContrato(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Configuração criada com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateCompanyConfiguracaoNegociacoesMultaRescisaoContrato(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateCompanyConfiguracaoNegociacoesMultaRescisaoContrato(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Configuração atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateCompanyConfiguracaoNegociacoesMultaRescisoria(payload) {
        // const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            // mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateCompanyConfiguracaoNegociacoesMultaRescisaoContrato(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            // mainStore.removeNotification(loadingNotification);
            // mainStore.addNotification({
            //   content: 'Configuração atualizada com sucesso',
            //   color: 'success',
            // });
            return;
        }
        catch (error) {
            // mainStore.removeNotification(loadingNotification);
            // mainStore.addNotification({
            //   content: error.detail,
            //   color: 'error',
            // });
            await mainStore.checkApiError(error);
        }
    }
    async getCompanyConfiguracaoNegociacoesMultaRescisoriaContrato() {
        try {
            const response = await api.getCompanyConfiguracaoNegociacoesMultaRescisaoContrato(mainStore.token);
            if (response) {
                this.setCompanyConfiguracaoNegociacoesMultaRescisoriaContrato(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCompanyConfiguracaoNegociacaoMultaRescisaoContrato(id) {
        try {
            const response = await api.getCompanyConfiguracaoNegociacaoMultaRescisaoContrato(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async deleteCompanyConfiguracaoNegociacaoMultaRescisaoContrato(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteCompanyConfiguracaoNegociacaoMultaRescisaoContrato(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Configuração excluida com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async companyConfiguracaoNegociacaoMultaRescisaoContratoRestaurarPadrao(tipo) {
        try {
            const response = await api.restaurarPadraoTipo(mainStore.token, tipo);
            if (response) {
                const materialParcelamento = response.data;
                this.setCompanyConfiguracaoNegociacoesMultaRescisoriaContrato(materialParcelamento);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], CompanyConfiguracaoNegociacaoModule.prototype, "setCompanyConfiguracaoNegociacoesMultaRescisoriaContrato", null);
__decorate([
    Action
], CompanyConfiguracaoNegociacaoModule.prototype, "createCompanyConfiguracaoNegociacaoMultaRescisaoContrato", null);
__decorate([
    Action
], CompanyConfiguracaoNegociacaoModule.prototype, "updateCompanyConfiguracaoNegociacoesMultaRescisaoContrato", null);
__decorate([
    Action
], CompanyConfiguracaoNegociacaoModule.prototype, "updateCompanyConfiguracaoNegociacoesMultaRescisoria", null);
__decorate([
    Action
], CompanyConfiguracaoNegociacaoModule.prototype, "getCompanyConfiguracaoNegociacoesMultaRescisoriaContrato", null);
__decorate([
    Action
], CompanyConfiguracaoNegociacaoModule.prototype, "getCompanyConfiguracaoNegociacaoMultaRescisaoContrato", null);
__decorate([
    Action
], CompanyConfiguracaoNegociacaoModule.prototype, "deleteCompanyConfiguracaoNegociacaoMultaRescisaoContrato", null);
__decorate([
    Action
], CompanyConfiguracaoNegociacaoModule.prototype, "companyConfiguracaoNegociacaoMultaRescisaoContratoRestaurarPadrao", null);
CompanyConfiguracaoNegociacaoModule = __decorate([
    Module({ name: 'companyConfiguracaoNegociacaoMultaRescisoriaContrato' })
], CompanyConfiguracaoNegociacaoModule);
export default CompanyConfiguracaoNegociacaoModule;
