import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
import _ from 'lodash';
let UserToolModule = class UserToolModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.userTools = [];
        this.userToolsIndexed = {};
        this.userToolsForSearch = [];
        this.userToolUpdate = { group: '', icon: '' };
        this.userToolsCreate = { tool_id: 0, user_id: 0, name: '', group: '', icon: '' };
        this.toolId = 0;
        this.userToolsSchool = [];
    }
    setToolId(payload) {
        this.toolId = payload;
    }
    setUserTools(payload) {
        this.userTools = payload;
    }
    setUserToolsForSearch(payload) {
        this.userToolsForSearch = [
            {
                id: 1,
                elementId: 'btnAnexarcomprovante',
                view: 'main-dashboard', title: 'Dashboard', type: 'Operação', module: 'Financeiro', name: 'Anexar comprovante'
            },
            {
                id: 2, elementId: 'btnCalculardebitos',
                view: 'main-dashboard', title: 'Dashboard', type: 'Operação', module: 'Financeiro', name: 'Calcular debitos'
            },
            {
                id: 3, elementId: 'btnBaixartitulo',
                view: 'main-dashboard', title: 'Dashboard', type: 'Operação', module: 'Financeiro', name: 'Baixar título'
            },
            {
                id: 4, elementId: 'txtRenegociar',
                view: 'main-dashboard', title: 'Dashboard', type: 'Operação', module: 'Financeiro', name: 'Renegociar'
            },
            {
                id: 5, elementId: 'btnRecibo',
                view: 'main-dashboard', title: 'Dashboard', type: 'Operação', module: 'Financeiro', name: 'Recibo'
            },
            {
                id: 6, elementId: 'btnFecharCaixa',
                view: 'main-dashboard', title: 'Dashboard', type: 'Operação', module: 'Financeiro', name: 'Fechar caixa'
            },
            {
                id: 7,
                view: 'main-dashboard', title: 'Dashboard', type: 'Operação', module: 'Financeiro', name: 'Alterar vencimento'
            },
            {
                id: 8,
                view: 'main-dashboard', title: 'Dashboard', type: 'Operação', module: 'Financeiro', name: 'Estornar título'
            },
            {
                id: 9,
                view: 'main-dashboard', title: 'Dashboard', type: 'Operação', module: 'Financeiro', name: 'Cancelar título'
            },
            {
                id: 10,
                view: 'main-dashboard', title: 'Dashboard', type: 'Operação', module: 'Financeiro', name: 'Negativar'
            },
            {
                id: 11,
                view: 'main-dashboard', title: 'Dashboard', type: 'Operação', module: 'Financeiro', name: 'Imprimir caixa'
            },
        ];
    }
    setUserToolsIndexed(payload) {
        this.userToolsIndexed = _.groupBy(payload, 'name');
    }
    setUserToolsSchool(payload) {
        this.userToolsSchool = payload;
    }
    getIdAtual() {
        return this.toolId;
    }
    async getToolsSchool() {
        try {
            const response = await api.getToolsSchool(mainStore.token);
            if (response) {
                this.setUserToolsSchool(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getUserTools() {
        try {
            const response = await api.getUserTools(mainStore.token);
            if (response) {
                this.setUserTools(response.data);
                this.setUserToolsIndexed(response.data);
                this.setUserToolsForSearch(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateUserTool(data) {
        try {
            await Promise.all([
                api.updateUserTools(mainStore.token, data),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getToolsByUserId(userId) {
        try {
            const response = await api.getToolsByUserId(mainStore.token, userId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            return [];
        }
    }
    async createUserTool(payload) {
        try {
            const result = await Promise.all([
                api.creatUserTool(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            return result[0].data.id;
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], UserToolModule.prototype, "setToolId", null);
__decorate([
    Mutation
], UserToolModule.prototype, "setUserTools", null);
__decorate([
    Mutation
], UserToolModule.prototype, "setUserToolsForSearch", null);
__decorate([
    Mutation
], UserToolModule.prototype, "setUserToolsIndexed", null);
__decorate([
    Mutation
], UserToolModule.prototype, "setUserToolsSchool", null);
__decorate([
    Action
], UserToolModule.prototype, "getIdAtual", null);
__decorate([
    Action
], UserToolModule.prototype, "getToolsSchool", null);
__decorate([
    Action
], UserToolModule.prototype, "getUserTools", null);
__decorate([
    Action
], UserToolModule.prototype, "updateUserTool", null);
__decorate([
    Action
], UserToolModule.prototype, "getToolsByUserId", null);
__decorate([
    Action
], UserToolModule.prototype, "createUserTool", null);
UserToolModule = __decorate([
    Module({ name: 'userTool' })
], UserToolModule);
export default UserToolModule;
