import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let SyngooMensagemConfiguracaoModule = class SyngooMensagemConfiguracaoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.syngooMensagemConfiguracao = [];
    }
    setSyngooMensagemConfiguracao(payload) {
        this.syngooMensagemConfiguracao = payload;
    }
    async createSyngooMensagemConfiguracao(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createSyngooMensagemConfiguracao(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Operação efetuada com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateSyngooMensagemConfiguracao(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateSyngooMensagemConfiguracao(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'SyngooMensagemConfiguracao atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getSyngooMensagemConfiguracao(id) {
        try {
            const response = await api.getSyngooMensagemConfiguracao(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getSyngooMensagemConfiguracaoEnvioManualFatura() {
        try {
            const response = await api.getSyngooMensagemConfiguracaoEnvioManualFatura(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getSyngooMensagemConfiguracoes() {
        try {
            const response = await api.getSyngooMensagemConfiguracoes(mainStore.token);
            if (response) {
                this.setSyngooMensagemConfiguracao(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async deleteSyngooMensagemConfiguracao(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteSyngooMensagemConfiguracao(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'SyngooMensagemConfiguracao excluida com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getSyngooMensagemConfiguracaoListaEnvioMensagem(syngooMensagemId) {
        try {
            const response = await api.getSyngooMensagemConfiguracaoListaEnvioMensagem(mainStore.token, syngooMensagemId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async SyngooEnviaWhats(financeiroParcelaId) {
        const loadingNotification = { content: 'Enviando Whatsapp', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.SyngooEnviaWhats(mainStore.token, financeiroParcelaId),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Mensagem enviada com sucesso',
                color: 'success',
            });
            return result[0].data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], SyngooMensagemConfiguracaoModule.prototype, "setSyngooMensagemConfiguracao", null);
__decorate([
    Action
], SyngooMensagemConfiguracaoModule.prototype, "createSyngooMensagemConfiguracao", null);
__decorate([
    Action
], SyngooMensagemConfiguracaoModule.prototype, "updateSyngooMensagemConfiguracao", null);
__decorate([
    Action
], SyngooMensagemConfiguracaoModule.prototype, "getSyngooMensagemConfiguracao", null);
__decorate([
    Action
], SyngooMensagemConfiguracaoModule.prototype, "getSyngooMensagemConfiguracaoEnvioManualFatura", null);
__decorate([
    Action
], SyngooMensagemConfiguracaoModule.prototype, "getSyngooMensagemConfiguracoes", null);
__decorate([
    Action
], SyngooMensagemConfiguracaoModule.prototype, "deleteSyngooMensagemConfiguracao", null);
__decorate([
    Action
], SyngooMensagemConfiguracaoModule.prototype, "getSyngooMensagemConfiguracaoListaEnvioMensagem", null);
__decorate([
    Action
], SyngooMensagemConfiguracaoModule.prototype, "SyngooEnviaWhats", null);
SyngooMensagemConfiguracaoModule = __decorate([
    Module({ name: 'syngooMensagemConfiguracao' })
], SyngooMensagemConfiguracaoModule);
export default SyngooMensagemConfiguracaoModule;
