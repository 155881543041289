import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let FinanceiroParcelaModule = class FinanceiroParcelaModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.financeiroParcelas = [];
        this.financeiroParcelasDrive = [];
    }
    setFinanceiroParcelas(payload) {
        this.financeiroParcelas = payload;
    }
    setFinanceiroParcelasDrive(payload) {
        this.financeiroParcelasDrive = payload;
    }
    async getFinanceiroParcelasQuery(queryIn) {
        try {
            const response = await api.getFinanceiroParcelasQuery(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getFinanceiroParcelas(payload) {
        try {
            this.setFinanceiroParcelas([]);
            const response = await api.getFinanceiroParcelas(mainStore.token, payload);
            if (response) {
                this.setFinanceiroParcelas(response.data);
                return response.data;
            }
        }
        catch (error) {
            this.setFinanceiroParcelas([]);
            await mainStore.checkApiError(error);
        }
    }
    async getFinanceiroParcelasAnexos(id) {
        try {
            const response = await api.getFinanceiroParcelasAnexos(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createFinanceiroParcelas(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createFinanceiroParcelas(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Parcela criada com sucesso',
                color: 'success',
            });
            return true;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async cancelarFinanceiroParcelas(payload) {
        const loadingNotification = { content: 'Cancelando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.cancelarFinanceiroParcelas(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Parcela(s) canceladas com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async baixaManualFinanceiroParcela(payload) {
        const loadingNotification = { content: 'Baixando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.baixaManualFinanceiroParcela(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Parcela baixada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async protestarFinanceiroParcelas(payload) {
        const loadingNotification = { content: 'Protestando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.protestarFinanceiroParcelas(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Parcela(s) protestadas com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async retirarProtestoFinanceiroParcelas(payload) {
        const loadingNotification = { content: 'Protestando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.retirarProtestoFinanceiroParcelas(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Parcela(s) atualizadas com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async renegociarFinanceiroParcelas(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.renegociarFinanceiroParcelas(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Parcela(s) renegociadas com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateFinanceiroParcela(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateFinanceiroParcela(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Parcela atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async quitarParcelaContaAPagar(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            await api.quitarParcelaContaAPagar(mainStore.token, payload, payload.anexos);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Conta a pagar atualizada com sucesso',
                color: 'success',
            });
            return true;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getUrlBucketArquivo(payload) {
        try {
            const response = await api.getUrlBucketArquivo(mainStore.token, payload.bucketArquivoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateFinanceiroVencimentoEmLote(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateFinanceiroVencimentoEmLote(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Parcela(s) atualizada(s) com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getFinanceirosParcelas(ano) {
        this.setFinanceiroParcelas([]);
        try {
            const response = await api.getFinanceirosParcelas(mainStore.token, ano);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getFinanceiroParcela(id) {
        this.setFinanceiroParcelas([]);
        try {
            const response = await api.getFinanceiroParcela(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getFinanceiroParcelasDrive() {
        try {
            const response = await api.getFinanceiroParcelasDrive(mainStore.token);
            if (response) {
                this.setFinanceiroParcelasDrive(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async deleteConta(payload) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteConta(mainStore.token, payload);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Conta a pagar excluída com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async imprimirRecibo(payload) {
        const loadingNotification = { content: 'Gerando recibo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.imprimirRecibo(mainStore.token, payload);
            mainStore.removeNotification(loadingNotification);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async imprimirTitulos(payload) {
        try {
            const response = await api.imprimirTitulos(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getFinanceiroParcelaComprovante(id) {
        try {
            const response = await api.getFinanceiroParcelaComprovante(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getFinanceiroParcelasByFinanceiro(financeiroId) {
        try {
            const response = await api.getFinanceiroParcelasByFinanceiro(mainStore.token, financeiroId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], FinanceiroParcelaModule.prototype, "setFinanceiroParcelas", null);
__decorate([
    Mutation
], FinanceiroParcelaModule.prototype, "setFinanceiroParcelasDrive", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "getFinanceiroParcelasQuery", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "getFinanceiroParcelas", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "getFinanceiroParcelasAnexos", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "createFinanceiroParcelas", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "cancelarFinanceiroParcelas", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "baixaManualFinanceiroParcela", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "protestarFinanceiroParcelas", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "retirarProtestoFinanceiroParcelas", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "renegociarFinanceiroParcelas", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "updateFinanceiroParcela", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "quitarParcelaContaAPagar", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "getUrlBucketArquivo", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "updateFinanceiroVencimentoEmLote", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "getFinanceirosParcelas", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "getFinanceiroParcela", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "getFinanceiroParcelasDrive", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "deleteConta", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "imprimirRecibo", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "imprimirTitulos", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "getFinanceiroParcelaComprovante", null);
__decorate([
    Action
], FinanceiroParcelaModule.prototype, "getFinanceiroParcelasByFinanceiro", null);
FinanceiroParcelaModule = __decorate([
    Module({ name: 'financeiroParcela' })
], FinanceiroParcelaModule);
export default FinanceiroParcelaModule;
