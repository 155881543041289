import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let UtilsModule = class UtilsModule extends VuexModule {
    async utilsImprimirTabela(payload) {
        try {
            const response = await api.utilsImprimirTabela(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async utilsNFSeImportarXML(payload) {
        try {
            const response = await api.utilsNFSeImportarXML(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], UtilsModule.prototype, "utilsImprimirTabela", null);
__decorate([
    Action
], UtilsModule.prototype, "utilsNFSeImportarXML", null);
UtilsModule = __decorate([
    Module({ name: 'utils' })
], UtilsModule);
export default UtilsModule;
