import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let CityModule = class CityModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.cities = [];
    }
    setCities(payload) {
        this.cities = payload;
    }
    async getCityByName(name) {
        try {
            const response = await api.getCityByName(mainStore.token, name);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCityByCodigoMunicipio(codigo) {
        try {
            const response = await api.getCityByCodigoMunicipio(mainStore.token, codigo);
            if (response) {
                return response.data[0];
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCities() {
        try {
            const response = await api.getCities(mainStore.token);
            if (response) {
                this.setCities(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], CityModule.prototype, "setCities", null);
__decorate([
    Action
], CityModule.prototype, "getCityByName", null);
__decorate([
    Action
], CityModule.prototype, "getCityByCodigoMunicipio", null);
__decorate([
    Action
], CityModule.prototype, "getCities", null);
CityModule = __decorate([
    Module({ name: 'city' })
], CityModule);
export default CityModule;
