import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
// import { Ocorrencia, OcorrenciaCreate, OcorrenciaUpdate } from '@/interfaces/ocorrencia';
import { mainStore } from '@/utils/store-accessor';
let OcorrenciaModule = class OcorrenciaModule extends VuexModule {
    async getOcorrenciasBy(payload) {
        try {
            const response = await api.getOcorrenciasBy(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getOcorrenciasById(id) {
        try {
            const response = await api.getOcorrenciasById(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getOcorrencias(payload) {
        try {
            const response = await api.getOcorrencias(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getMultiOcorrencias(payload) {
        try {
            const response = await api.getMultiOcorrencias(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getOcorrenciaAnexos(payload) {
        try {
            const response = await api.getOcorrenciaAnexos(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getOcorrenciaAnexo(payload) {
        try {
            const response = await api.getOcorrenciaAnexo(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async deleteOcorrenciaAnexo(payload) {
        try {
            const response = await api.deleteOcorrenciaAnexo(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async deleteOcorrencia(id) {
        try {
            const response = await api.deleteOcorrencia(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createOcorrencia(payload) {
        try {
            const response = await api.createOcorrencia(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateOcorrencia(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateOcorrencia(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Atualização efetuada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateOcorrenciaTarefa(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateOcorrenciaTarefa(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Atualização efetuada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getOcorrenciasQuery(queryIn) {
        try {
            const response = await api.getOcorrenciasQuery(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], OcorrenciaModule.prototype, "getOcorrenciasBy", null);
__decorate([
    Action
], OcorrenciaModule.prototype, "getOcorrenciasById", null);
__decorate([
    Action
], OcorrenciaModule.prototype, "getOcorrencias", null);
__decorate([
    Action
], OcorrenciaModule.prototype, "getMultiOcorrencias", null);
__decorate([
    Action
], OcorrenciaModule.prototype, "getOcorrenciaAnexos", null);
__decorate([
    Action
], OcorrenciaModule.prototype, "getOcorrenciaAnexo", null);
__decorate([
    Action
], OcorrenciaModule.prototype, "deleteOcorrenciaAnexo", null);
__decorate([
    Action
], OcorrenciaModule.prototype, "deleteOcorrencia", null);
__decorate([
    Action
], OcorrenciaModule.prototype, "createOcorrencia", null);
__decorate([
    Action
], OcorrenciaModule.prototype, "updateOcorrencia", null);
__decorate([
    Action
], OcorrenciaModule.prototype, "updateOcorrenciaTarefa", null);
__decorate([
    Action
], OcorrenciaModule.prototype, "getOcorrenciasQuery", null);
OcorrenciaModule = __decorate([
    Module({ name: 'ocorrencia' })
], OcorrenciaModule);
export default OcorrenciaModule;
