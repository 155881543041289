import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
// import { PlugnotasNfseServicoCreate, PlugnotasNfseServicoUpdate } from '@/interfaces/plugnotasNfseServico';
let PlugnotasNfseServico = class PlugnotasNfseServico extends VuexModule {
    async getPlugnotasNfseServicosByCompanyId(companyId) {
        try {
            const response = await api.getPlugnotasNfseServicosByCompanyId(mainStore.token, companyId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getPlugnotasNfseServico(plugNotasNfseServicoId) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            const result = await api.getPlugnotasNfseServico(mainStore.token, plugNotasNfseServicoId);
            return result.data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async createPlugnotasNfseServico(plugNotasNfseServicoCreate) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            await api.createPlugnotasNfseServico(mainStore.token, plugNotasNfseServicoCreate);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Criado com sucesso',
                color: 'success',
            });
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deletePlugnotasNfseServico(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            await api.deletePlugnotasNfseServico(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Excluído com sucesso',
                color: 'success',
            });
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updatePlugnotasNfseServico(plugNotasNfseServicoUpdate) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            await api.updatePlugnotasNfseServico(mainStore.token, plugNotasNfseServicoUpdate);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Registro Atualizado com sucesso',
                color: 'success',
            });
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], PlugnotasNfseServico.prototype, "getPlugnotasNfseServicosByCompanyId", null);
__decorate([
    Action
], PlugnotasNfseServico.prototype, "getPlugnotasNfseServico", null);
__decorate([
    Action
], PlugnotasNfseServico.prototype, "createPlugnotasNfseServico", null);
__decorate([
    Action
], PlugnotasNfseServico.prototype, "deletePlugnotasNfseServico", null);
__decorate([
    Action
], PlugnotasNfseServico.prototype, "updatePlugnotasNfseServico", null);
PlugnotasNfseServico = __decorate([
    Module({ name: 'plugnotasNfseServico' })
], PlugnotasNfseServico);
export default PlugnotasNfseServico;
