import { __decorate } from "tslib";
import { api } from '@/api';
import { mainStore } from '@/utils/store-accessor';
import { Action, Module, VuexModule } from 'vuex-module-decorators';
let AtividadeModule = class AtividadeModule extends VuexModule {
    // public Cronogramas = [];
    // @Mutation
    // public setCronogramas(payload) {
    //   this.Cronogramas = payload;
    // }
    async getAtividades(livro_id) {
        try {
            const response = await api.getAtividades(mainStore.token, livro_id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], AtividadeModule.prototype, "getAtividades", null);
AtividadeModule = __decorate([
    Module({ name: 'atividade' })
], AtividadeModule);
export default AtividadeModule;
