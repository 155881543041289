import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let FinanceiroParcelaRenegociacaoModule = class FinanceiroParcelaRenegociacaoModule extends VuexModule {
    async getFinanceiroParcelaRengociacao(financeiro_parcela_id) {
        try {
            const response = await api.getFinanceiroParcelaRengociacao(mainStore.token, financeiro_parcela_id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], FinanceiroParcelaRenegociacaoModule.prototype, "getFinanceiroParcelaRengociacao", null);
FinanceiroParcelaRenegociacaoModule = __decorate([
    Module({ name: 'financeiroParcelaRenegociacao' })
], FinanceiroParcelaRenegociacaoModule);
export default FinanceiroParcelaRenegociacaoModule;
