import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let PlugnotasConfiguracao = class PlugnotasConfiguracao extends VuexModule {
    async getPlugnotasConfiguracaoMe() {
        try {
            const response = await api.getPlugnotasConfiguracaoMe(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createPlugnotasConfiguracao(plugNotasConfiguracaoCreate) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            await api.createPlugnotasConfiguracao(mainStore.token, plugNotasConfiguracaoCreate);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Criado com sucesso',
                color: 'success',
            });
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updatePlugnotasConfiguracao(plugNotasConfiguracaoUpdate) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            await api.updatePlugnotasConfiguracao(mainStore.token, plugNotasConfiguracaoUpdate);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Registro Atualizado com sucesso',
                color: 'success',
            });
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugNotasConfiguracaoAceite(payload) {
        const loadingNotification = { content: 'Habilitando o módulo', showProgress: true };
        try {
            const response = await api.plugNotasConfiguracaoAceite(mainStore.token, payload);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Módulo habilitado com sucesso!',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail.error.message,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugNotasConfiguracaoInserirCertificado(plugNotasConfiguracaoCertificado) {
        const loadingNotification = { content: 'Inserindo Certificado...', showProgress: true };
        try {
            const response = await api.plugNotasConfiguracaoInserirCertificado(mainStore.token, plugNotasConfiguracaoCertificado);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Certificado inserido com sucesso!',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail.error.message,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugNotasConfiguracaoAtualizaCertificado(plugNotasConfiguracaoCertificado) {
        const loadingNotification = { content: 'Atualizando certificado...', showProgress: true };
        try {
            const response = await api.plugNotasConfiguracaoAtualizaCertificado(mainStore.token, plugNotasConfiguracaoCertificado.certificadoId, plugNotasConfiguracaoCertificado);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Certificado atualizado com sucesso!',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail.error.message,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], PlugnotasConfiguracao.prototype, "getPlugnotasConfiguracaoMe", null);
__decorate([
    Action
], PlugnotasConfiguracao.prototype, "createPlugnotasConfiguracao", null);
__decorate([
    Action
], PlugnotasConfiguracao.prototype, "updatePlugnotasConfiguracao", null);
__decorate([
    Action
], PlugnotasConfiguracao.prototype, "plugNotasConfiguracaoAceite", null);
__decorate([
    Action
], PlugnotasConfiguracao.prototype, "plugNotasConfiguracaoInserirCertificado", null);
__decorate([
    Action
], PlugnotasConfiguracao.prototype, "plugNotasConfiguracaoAtualizaCertificado", null);
PlugnotasConfiguracao = __decorate([
    Module({ name: 'plugnotasConfiguracao' })
], PlugnotasConfiguracao);
export default PlugnotasConfiguracao;
