import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let CompeticaoModule = class CompeticaoModule extends VuexModule {
    async getCompeticoes() {
        try {
            const response = await api.getCompeticoes(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCompeticao(id) {
        try {
            const response = await api.getCompeticao(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], CompeticaoModule.prototype, "getCompeticoes", null);
__decorate([
    Action
], CompeticaoModule.prototype, "getCompeticao", null);
CompeticaoModule = __decorate([
    Module({ name: 'competicao' })
], CompeticaoModule);
export default CompeticaoModule;
