import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let EnderecoModule = class EnderecoModule extends VuexModule {
    async getEndereco(cep) {
        try {
            const response = await api.getEndereco(cep);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], EnderecoModule.prototype, "getEndereco", null);
EnderecoModule = __decorate([
    Module({ name: 'endereco' })
], EnderecoModule);
export default EnderecoModule;
