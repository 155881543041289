import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let AtHomeModule = class AtHomeModule extends VuexModule {
    async getJWT() {
        try {
            const response = await api.getJWT(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getNotificacaoAula(user_token) {
        try {
            const response = await api.getNotificacaoAula(mainStore.token, user_token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAtHomeTurmas(somenteDigital = false) {
        try {
            const response = await api.getAtHomeTurmas(mainStore.token, somenteDigital);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAtHomeLivrosAluno() {
        try {
            const response = await api.getAtHomeLivrosAluno(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAtHomeTurma(turmaAlunoId) {
        try {
            const response = await api.getAtHomeTurma(mainStore.token, turmaAlunoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAtHomeTurmaAulaHoje(turmaId) {
        try {
            const response = await api.getAtHomeTurmaAulaHoje(mainStore.token, turmaId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAtHomeTurmaAlunoAulaHoje(turmaAlunoId) {
        try {
            const response = await api.getAtHomeTurmaAlunoAulaHoje(mainStore.token, turmaAlunoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAtHomeNotas(turmaAlunoId) {
        try {
            const response = await api.getAtHomeNotas(mainStore.token, turmaAlunoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAtHomeNotasAtHome(turmaAlunoId) {
        try {
            const response = await api.getAtHomeNotasAtHome(mainStore.token, turmaAlunoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAtHomeFinanceiro() {
        try {
            const response = await api.getAtHomeFinanceiro(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async atHomeScannearQrCode(payload) {
        try {
            const response = await api.atHomeScannearQrCode(mainStore.token, payload.qrCode, payload.codigoProduto);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], AtHomeModule.prototype, "getJWT", null);
__decorate([
    Action
], AtHomeModule.prototype, "getNotificacaoAula", null);
__decorate([
    Action
], AtHomeModule.prototype, "getAtHomeTurmas", null);
__decorate([
    Action
], AtHomeModule.prototype, "getAtHomeLivrosAluno", null);
__decorate([
    Action
], AtHomeModule.prototype, "getAtHomeTurma", null);
__decorate([
    Action
], AtHomeModule.prototype, "getAtHomeTurmaAulaHoje", null);
__decorate([
    Action
], AtHomeModule.prototype, "getAtHomeTurmaAlunoAulaHoje", null);
__decorate([
    Action
], AtHomeModule.prototype, "getAtHomeNotas", null);
__decorate([
    Action
], AtHomeModule.prototype, "getAtHomeNotasAtHome", null);
__decorate([
    Action
], AtHomeModule.prototype, "getAtHomeFinanceiro", null);
__decorate([
    Action
], AtHomeModule.prototype, "atHomeScannearQrCode", null);
AtHomeModule = __decorate([
    Module({ name: 'atHome' })
], AtHomeModule);
export default AtHomeModule;
