import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
import _ from 'lodash';
let VeiculoModule = class VeiculoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.veiculos = [];
        this.veiculosActive = [];
    }
    setVeiculos(payload) {
        this.veiculos = payload;
        this.veiculosActive = _.filter(this.veiculos, (item) => item.is_active);
    }
    async createVeiculo(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createVeiculo(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Veiculo criado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateVeiculo(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateVeiculo(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Veículo atualizado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getVeiculos() {
        try {
            const response = await api.getVeiculos(mainStore.token);
            if (response) {
                this.setVeiculos(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getVeiculo(id) {
        try {
            const response = await api.getVeiculo(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async deleteVeiculo(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const respose = await api.deleteVeiculo(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Veículo excluído com sucesso',
                color: 'success',
            });
            if (respose) {
                return respose.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], VeiculoModule.prototype, "setVeiculos", null);
__decorate([
    Action
], VeiculoModule.prototype, "createVeiculo", null);
__decorate([
    Action
], VeiculoModule.prototype, "updateVeiculo", null);
__decorate([
    Action
], VeiculoModule.prototype, "getVeiculos", null);
__decorate([
    Action
], VeiculoModule.prototype, "getVeiculo", null);
__decorate([
    Action
], VeiculoModule.prototype, "deleteVeiculo", null);
VeiculoModule = __decorate([
    Module({ name: 'veiculo' })
], VeiculoModule);
export default VeiculoModule;
