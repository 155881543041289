import { __decorate } from "tslib";
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
let SongModule = class SongModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.isPlaying = false;
        this.audio = null;
        this.currentTrack = null;
        this.openFooter = false;
    }
    setPlayingStatus(status) {
        this.isPlaying = status;
    }
    setAudio(audio) {
        this.audio = audio;
    }
    setCurrentTrack(track) {
        this.currentTrack = track;
    }
    setOpenFooter(open) {
        this.openFooter = open;
    }
    loadSong(track) {
        this.context.commit('setCurrentTrack', track);
        if (this.audio && this.audio.src) {
            this.audio.pause();
            this.context.commit('setPlayingStatus', false);
            this.audio.src = '';
        }
        this.context.commit('setAudio', new Audio());
        this.audio.src = track.url_audio;
        setTimeout(() => {
            this.context.commit('setPlayingStatus', true);
            this.audio.play();
        }, 200);
    }
    playOrPauseSong() {
        if (this.audio.paused) {
            this.context.commit('setPlayingStatus', true);
            this.audio.play();
        }
        else {
            this.context.commit('setPlayingStatus', false);
            this.audio.pause();
        }
    }
    playOrPauseThisSong(track) {
        if (!this.audio ||
            !this.audio.src ||
            this.currentTrack.sequencial !== track.sequencial ||
            this.currentTrack.unit !== track.unit) {
            this.context.commit('setOpenFooter', true);
            this.loadSong(track);
            return;
        }
        this.playOrPauseSong();
    }
    resetState() {
        this.audio.pause();
        this.context.commit('setPlayingStatus', false);
        this.context.commit('setCurrentTrack', { currentTrack: null });
        this.context.commit('setOpenFooter', false);
    }
};
__decorate([
    Mutation
], SongModule.prototype, "setPlayingStatus", null);
__decorate([
    Mutation
], SongModule.prototype, "setAudio", null);
__decorate([
    Mutation
], SongModule.prototype, "setCurrentTrack", null);
__decorate([
    Mutation
], SongModule.prototype, "setOpenFooter", null);
__decorate([
    Action
], SongModule.prototype, "loadSong", null);
__decorate([
    Action
], SongModule.prototype, "playOrPauseSong", null);
__decorate([
    Action
], SongModule.prototype, "playOrPauseThisSong", null);
__decorate([
    Action
], SongModule.prototype, "resetState", null);
SongModule = __decorate([
    Module({ name: 'song' })
], SongModule);
export default SongModule;
