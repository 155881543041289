import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let CompanyConfiguracaoNegociacaoModule = class CompanyConfiguracaoNegociacaoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.companyConfiguracaoNegociacoes = [];
    }
    setCompanyConfiguracaoNegociacoes(payload) {
        this.companyConfiguracaoNegociacoes = payload;
    }
    async createCompanyConfiguracaoNegociacao(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createCompanyConfiguracaoNegociacao(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Configuração criada com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateCompanyConfiguracaoNegociacao(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateCompanyConfiguracaoNegociacao(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Configuração atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateCompanyConfiguracaoNegociacoes(payload) {
        // const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            // mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateCompanyConfiguracaoNegociacoes(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            // mainStore.removeNotification(loadingNotification);
            // mainStore.addNotification({
            //   content: 'Configuração atualizada com sucesso',
            //   color: 'success',
            // });
            return;
        }
        catch (error) {
            // mainStore.removeNotification(loadingNotification);
            // mainStore.addNotification({
            //   content: error.detail,
            //   color: 'error',
            // });
            await mainStore.checkApiError(error);
        }
    }
    async getCompanyConfiguracaoNegociacoes() {
        try {
            const response = await api.getCompanyConfiguracaoNegociacoes(mainStore.token);
            if (response) {
                this.setCompanyConfiguracaoNegociacoes(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCompanyConfiguracaoNegociacao(id) {
        try {
            const response = await api.getCompanyConfiguracaoNegociacao(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async deleteCompanyConfiguracaoNegociacao(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteCompanyConfiguracaoNegociacao(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Configuração excluida com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async companyConfiguracaoNegociacaoRestaurarPadrao(tipo) {
        try {
            const response = await api.restaurarPadraoTipo(mainStore.token, tipo);
            if (response) {
                const negociacaoTitulos = response.data;
                this.setCompanyConfiguracaoNegociacoes(negociacaoTitulos);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], CompanyConfiguracaoNegociacaoModule.prototype, "setCompanyConfiguracaoNegociacoes", null);
__decorate([
    Action
], CompanyConfiguracaoNegociacaoModule.prototype, "createCompanyConfiguracaoNegociacao", null);
__decorate([
    Action
], CompanyConfiguracaoNegociacaoModule.prototype, "updateCompanyConfiguracaoNegociacao", null);
__decorate([
    Action
], CompanyConfiguracaoNegociacaoModule.prototype, "updateCompanyConfiguracaoNegociacoes", null);
__decorate([
    Action
], CompanyConfiguracaoNegociacaoModule.prototype, "getCompanyConfiguracaoNegociacoes", null);
__decorate([
    Action
], CompanyConfiguracaoNegociacaoModule.prototype, "getCompanyConfiguracaoNegociacao", null);
__decorate([
    Action
], CompanyConfiguracaoNegociacaoModule.prototype, "deleteCompanyConfiguracaoNegociacao", null);
__decorate([
    Action
], CompanyConfiguracaoNegociacaoModule.prototype, "companyConfiguracaoNegociacaoRestaurarPadrao", null);
CompanyConfiguracaoNegociacaoModule = __decorate([
    Module({ name: 'companyConfiguracaoNegociacao' })
], CompanyConfiguracaoNegociacaoModule);
export default CompanyConfiguracaoNegociacaoModule;
