import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
let OcorrenciaModule = class OcorrenciaModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.departamentos = [];
    }
    setDepartamentos(payload) {
        this.departamentos = payload;
    }
    async getDepartamentos() {
        this.setDepartamentos([
            { nome: 'Administrativo', id: 5 },
            { nome: 'Comercial', id: 4 },
            { nome: 'Financeiro', id: 6 },
            { nome: 'Pedagógico', id: 1 },
            { nome: 'Retenção financeira', id: 2 },
            { nome: 'Retenção pedagógica', id: 3 }
        ]);
        // bater o tempo todo no banco nao é o uma boa opcao
        // try {
        //   const response = await api.getDepartamentos(mainStore.token);
        //   if (response) {
        //     this.setDepartamentos(response.data);
        //   }
        // } catch (error) {
        //   await mainStore.checkApiError(error);
        // }
    }
};
__decorate([
    Mutation
], OcorrenciaModule.prototype, "setDepartamentos", null);
__decorate([
    Action
], OcorrenciaModule.prototype, "getDepartamentos", null);
OcorrenciaModule = __decorate([
    Module({ name: 'departamento' })
], OcorrenciaModule);
export default OcorrenciaModule;
