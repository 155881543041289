import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let ActionModule = class ActionModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.action = [];
    }
    setActions(payload) {
        this.action = payload;
    }
    async getActions() {
        try {
            const response = await api.getActions(mainStore.token);
            if (response) {
                this.setActions(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getActionsByUserId(userId) {
        try {
            const response = await api.getActionsByUserId(mainStore.token, userId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            return [];
        }
    }
    async getActionsDefault(payload) {
        try {
            const response = await api.getActionsDefault(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            return [];
        }
    }
    async updateActionsDefault(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateActionsDefault(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Action atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], ActionModule.prototype, "setActions", null);
__decorate([
    Action
], ActionModule.prototype, "getActions", null);
__decorate([
    Action
], ActionModule.prototype, "getActionsByUserId", null);
__decorate([
    Action
], ActionModule.prototype, "getActionsDefault", null);
__decorate([
    Action
], ActionModule.prototype, "updateActionsDefault", null);
ActionModule = __decorate([
    Module({ name: 'action' })
], ActionModule);
export default ActionModule;
