import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let CompeticaoModule = class CompeticaoModule extends VuexModule {
    async getCompeticaoEtapas(competicaoId) {
        try {
            const response = await api.getCompeticaoEtapas(mainStore.token, competicaoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCompeticaoEtapa(id) {
        try {
            const response = await api.getCompeticaoEtapa(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], CompeticaoModule.prototype, "getCompeticaoEtapas", null);
__decorate([
    Action
], CompeticaoModule.prototype, "getCompeticaoEtapa", null);
CompeticaoModule = __decorate([
    Module({ name: 'competicaoEtapa' })
], CompeticaoModule);
export default CompeticaoModule;
