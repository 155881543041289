import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let RotaPontoParadaAlunosModule = class RotaPontoParadaAlunosModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.rota_ponto_parada_alunos = [];
    }
    setRotaPontoParadaAlunoss(payload) {
        this.rota_ponto_parada_alunos = payload;
    }
    setRotaPontoParadaAlunos(payload) {
        const rota_ponto_parada_alunos = this.rota_ponto_parada_alunos.filter((company) => company.id !== payload.id);
        rota_ponto_parada_alunos.push(payload);
        this.rota_ponto_parada_alunos = rota_ponto_parada_alunos;
    }
    async createRotaPontoParadaAlunos(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createRotaPontoParadaAluno(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'RotaPontoParadaAlunos criado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateRotaPontoParadaAluno(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateRotaPontoParadaAluno(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Aluno atualizado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getRotaPontoParadaAlunoss() {
        try {
            const response = await api.getRotaPontoParadaAlunos(mainStore.token);
            if (response) {
                this.setRotaPontoParadaAlunoss(response.data);
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getRotaPontoParadaAluno(id) {
        try {
            const response = await api.getRotaPontoParadaAluno(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteRotaPontoParadaAlunos(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteRotaPontoParadaAluno(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Aluno removido do ponto de parada com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], RotaPontoParadaAlunosModule.prototype, "setRotaPontoParadaAlunoss", null);
__decorate([
    Mutation
], RotaPontoParadaAlunosModule.prototype, "setRotaPontoParadaAlunos", null);
__decorate([
    Action
], RotaPontoParadaAlunosModule.prototype, "createRotaPontoParadaAlunos", null);
__decorate([
    Action
], RotaPontoParadaAlunosModule.prototype, "updateRotaPontoParadaAluno", null);
__decorate([
    Action
], RotaPontoParadaAlunosModule.prototype, "getRotaPontoParadaAlunoss", null);
__decorate([
    Action
], RotaPontoParadaAlunosModule.prototype, "getRotaPontoParadaAluno", null);
__decorate([
    Action
], RotaPontoParadaAlunosModule.prototype, "deleteRotaPontoParadaAlunos", null);
RotaPontoParadaAlunosModule = __decorate([
    Module({ name: 'rotaPontoParadaAluno' })
], RotaPontoParadaAlunosModule);
export default RotaPontoParadaAlunosModule;
