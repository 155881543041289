import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let BryModule = class BryModule extends VuexModule {
    async bryColeta(chave) {
        try {
            const response = await api.bryColeta(mainStore.token, chave);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async bryColetas() {
        try {
            const response = await api.bryColetas(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async bryColetaParticipantes(chave) {
        try {
            const response = await api.bryColetaParticipantes(mainStore.token, chave);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], BryModule.prototype, "bryColeta", null);
__decorate([
    Action
], BryModule.prototype, "bryColetas", null);
__decorate([
    Action
], BryModule.prototype, "bryColetaParticipantes", null);
BryModule = __decorate([
    Module({ name: 'bry' })
], BryModule);
export default BryModule;
