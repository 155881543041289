import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let DocumentoFiscalModule = class DocumentoFiscalModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.documentosFiscais = [];
    }
    setDocumentosFiscais(payload) {
        this.documentosFiscais = payload;
    }
    setDocumentoFiscal(payload) {
        const documentosFiscais = this.documentosFiscais.filter((company) => company.id !== payload.id);
        documentosFiscais.push(payload);
        this.documentosFiscais = documentosFiscais;
    }
    async createDocumentoFiscal(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createDocumentoFiscal(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Documento Fiscal criado com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateDocumentoFiscal(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateDocumentoFiscal(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Documento Fiscal salva com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getDocumentosFiscais() {
        try {
            const response = await api.getDocumentosFiscais(mainStore.token);
            if (response) {
                this.setDocumentosFiscais(response.data);
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getDocumentoFiscal(id) {
        try {
            const response = await api.getDocumentoFiscal(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteDocumentoFiscal(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteDocumentoFiscal(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Documento Fiscal excluida com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], DocumentoFiscalModule.prototype, "setDocumentosFiscais", null);
__decorate([
    Mutation
], DocumentoFiscalModule.prototype, "setDocumentoFiscal", null);
__decorate([
    Action
], DocumentoFiscalModule.prototype, "createDocumentoFiscal", null);
__decorate([
    Action
], DocumentoFiscalModule.prototype, "updateDocumentoFiscal", null);
__decorate([
    Action
], DocumentoFiscalModule.prototype, "getDocumentosFiscais", null);
__decorate([
    Action
], DocumentoFiscalModule.prototype, "getDocumentoFiscal", null);
__decorate([
    Action
], DocumentoFiscalModule.prototype, "deleteDocumentoFiscal", null);
DocumentoFiscalModule = __decorate([
    Module({ name: 'documentoFiscal' })
], DocumentoFiscalModule);
export default DocumentoFiscalModule;
