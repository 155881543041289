import '@babel/polyfill';
import { format } from 'date-fns';
// Import Component hooks before component definitions
import '@/plugins/highcharts-vue';
import '@/plugins/sweet-alert';
import '@/plugins/v-currency-field';
import '@/plugins/v-mask';
import '@/plugins/vue-speech';
import '@/plugins/vue-swing';
import '@/plugins/vue-toasted';
import vuetify from '@/plugins/vuetify';
import '@/plugins/window-size';
import store from '@/store';
import { pt } from 'date-fns/locale';
import _ from 'lodash';
import Vue from 'vue';
import 'vuetify/dist/vuetify.min.css';
import App from './App.vue';
import './assets/css/index.css';
import './component-hooks';
import './plugins/prismic-blogs-knn';
import './plugins/prismic-dream-hub';
import './plugins/vee-validate';
import './plugins/vue-qrcode';
import './plugins/vuetify';
import './registerServiceWorker';
import router from './router';
import { financeiroCategoriaStore, mainStore } from '@/store';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
Vue.config.productionTip = false;
// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
// Disponibiliza o lodash para o template
Vue.prototype._ = _;
Vue.filter('formatCpfCnpj', (value) => {
    if (value) {
        let formatedValue = '';
        if (value.length > 11) {
            formatedValue = value.padStart(14, '0');
            formatedValue = formatedValue.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }
        else {
            formatedValue = value.padStart(11, '0');
            formatedValue = formatedValue.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        }
        return formatedValue;
    }
});
Vue.filter('formatDate', (value) => {
    if (value) {
        if (typeof value === 'object') {
            return format(value, 'dd/MM/yyyy');
        }
        else {
            let parts = value.substring(0, 10);
            // @ts-ignore
            parts = parts.split('-');
            // @ts-ignore
            const date = new Date(parts[0], parts[1] - 1, parts[2]);
            return format(date, 'dd/MM/yyyy');
        }
    }
});
Vue.filter('formatCapitalize', (value) => {
    if (value) {
        const lowerToCase = value.toLowerCase().split(' ');
        const firstLetterEachWord = lowerToCase
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        return firstLetterEachWord;
    }
    return value;
});
Vue.filter('boolToStr', (value) => {
    if (value) {
        return 'Sim';
    }
    else {
        return 'Não';
    }
});
Vue.filter('formatDateTime', (value) => {
    if (value) {
        const value2 = new Date(value);
        const date = new Date(Date.UTC(value2.getFullYear(), value2.getMonth(), value2.getDate(), value2.getHours(), value2.getMinutes(), value2.getSeconds()));
        return format(date, 'dd/MM/yyyy HH:mm');
    }
});
Vue.filter('formatDateTimeSecond', (value) => {
    if (value) {
        const value2 = new Date(value);
        const date = new Date(Date.UTC(value2.getFullYear(), value2.getMonth(), value2.getDate(), value2.getHours(), value2.getMinutes(), value2.getSeconds()));
        return format(date, 'dd/MM/yyyy HH:mm:ss');
    }
});
Vue.filter('formatDateTimeWithoutConvert', (value) => {
    if (value) {
        value = value.replace('Z', '');
        const value2 = new Date(value);
        return format(value2, 'dd/MM/yyyy HH:mm');
    }
});
Vue.filter('formatTimeWithoutConvert', (value) => {
    if (value) {
        value = value.replace('Z', '');
        const value2 = new Date(value);
        return format(value2, 'HH:mm:ss');
    }
});
Vue.filter('formatTime', (value) => {
    if (value) {
        const value2 = new Date(value);
        const date = new Date(Date.UTC(value2.getFullYear(), value2.getMonth(), value2.getDate(), value2.getHours(), value2.getMinutes(), value2.getSeconds()));
        return format(date, 'HH:mm:ss');
    }
});
Vue.filter('formatTimeWithoutConvert', (value) => {
    if (value) {
        const value2 = new Date(value);
        return format(value2, 'HH:mm:ss');
    }
});
Vue.filter('formatMonth', (value) => {
    if (value) {
        // @ts-ignore
        value = value.replaceAll('-', '/');
        const date = new Date(value);
        // @ts-ignore
        return format(date, 'MMMM/yyyy', { locale: ptLocale });
    }
});
Vue.filter('formatPriceValue', (value) => {
    if (value) {
        // @ts-ignore
        return '' + value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
    else {
        return '0,00';
    }
});
Vue.filter('formatPriceValueWithoutDefault', (value) => {
    // @ts-ignore
    return '' + value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
});
Vue.filter('formatPhone', (value) => {
    if (value) {
        // @ts-ignore
        return '(' + value.substr(0, 2) + ') ' + value.substr(2, 5) + '-' + value.substr(7, 4);
    }
});
Vue.mixin({
    methods: {
        departamentoByCargo(value) {
            const ids = {
                1: [1],
                2: [5, 4, 3, 2, 1],
                3: [4],
                4: [5],
                5: [4],
                6: [1, 2, 3, 4, 5] // Admnistrador
            };
            return ids[value];
        },
        formatDateTime(value) {
            if (value) {
                const value2 = new Date(value);
                const date = new Date(Date.UTC(value2.getFullYear(), value2.getMonth(), value2.getDate(), value2.getHours(), value2.getMinutes(), value2.getSeconds()));
                return format(date, 'dd/MM/yyyy HH:mm');
            }
        },
        formatEndereco(company) {
            return `${company.logradouro},
      ${company.numero} -
      ${company.bairro},
      ${company.cidade} -
      ${company.uf}
      `;
        },
        formatTime(value) {
            if (value) {
                const value2 = new Date(value);
                const date = new Date(Date.UTC(value2.getFullYear(), value2.getMonth(), value2.getDate(), value2.getHours(), value2.getMinutes(), value2.getSeconds()));
                return format(date, 'HH:mm');
            }
        },
        formatDate(value) {
            if (value) {
                if (typeof value === 'object') {
                    return format(value, 'dd/MM/yyyy');
                }
                else {
                    let parts = value.substring(0, 10);
                    // @ts-ignore
                    parts = parts.split('-');
                    // @ts-ignore
                    const date = new Date(parts[0], parts[1] - 1, parts[2]);
                    return format(date, 'dd/MM/yyyy');
                }
            }
        },
        formatDayMonth(value) {
            if (value) {
                if (typeof value === 'object') {
                    return format(value, 'dd/MM');
                }
                else {
                    let parts = value.substring(0, 10);
                    // @ts-ignore
                    parts = parts.split('-');
                    // @ts-ignore
                    const date = new Date(parts[0], parts[1] - 1, parts[2]);
                    return format(date, 'dd/MM');
                }
            }
        },
        formatMonthYear(value) {
            if (value) {
                const formattedMonthYear = format(new Date(value), 'MMMM yyyy', {
                    locale: pt,
                });
                return formattedMonthYear;
            }
        },
        parcelamentoText(item) {
            const text = '';
            let operacao = '';
            let percentual = 0;
            if (item.desconto_percentual > 0) {
                operacao = 'desconto';
                percentual = item.desconto_percentual;
            }
            else {
                operacao = 'acréscimo';
                percentual = item.desconto_percentual * -1;
            }
            let msg = '';
            if (item.desconto_percentual) {
                msg = `Permite até ${percentual}% de ${operacao}`;
            }
            else if (item.desconto_valor) {
                msg = `Permite até R$${item.desconto_valor} de ${operacao}`;
            }
            else {
                msg = `Não permite desconto ou acréscimo`;
            }
            if (item.desconto_valor > 0) {
                msg = msg + ` limitado a R$${item.desconto_valor}`;
            }
            return msg;
        },
        formatPriceValue: (value) => {
            if (value) {
                // @ts-ignore
                return '' + value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
            else {
                return '0,00';
            }
        },
        formatMinuteSecond(value) {
            if (value) {
                const [minutes, seconds] = value.split(':');
                const date = new Date(0, 0, 0, 0, Number(minutes), Number(seconds));
                const formattedMinutes = Number(minutes) !== 1 ? 'minutos' : 'minuto';
                const formattedSeconds = Number(seconds) !== 1 ? 'segundos' : 'segundo';
                let formattedTime = '';
                let char_e = '';
                if (Number(minutes) > 0) {
                    formattedTime = format(date, `m '${formattedMinutes}'`);
                    char_e = ' e ';
                }
                if (Number(seconds) > 0) {
                    formattedTime += ` ${char_e} ${format(date, `s '${formattedSeconds}'`)}`;
                }
                return formattedTime;
            }
        },
        dateToBR: (date) => {
            if (date) {
                const dataFormated = date.split('-');
                return dataFormated[2] + '/' + dataFormated[1] + '/' + dataFormated[0];
            }
            else {
                return '';
            }
        },
        BRToDate: (date) => {
            const dataFormated = date.split('/');
            return dataFormated[2] + '-' + dataFormated[1] + '-' + dataFormated[0];
        },
        datetimeToDate(date) {
            const val = date.split('T');
            return val[0];
        },
        dateAndTime(date) {
            if (!date) {
                return ['', ''];
            }
            const val = date.split('T');
            const time = val[1].split(':');
            return [val[0], `${time[0]}:${time[1]}`];
        },
        datetimeToBRDate: (date) => {
            if (date) {
                const val = date.split('T');
                const data = val[0].split('-');
                return `${data[2]}/${data[1]}/${data[0]}`;
            }
            else {
                return '';
            }
        },
        isBRDate: (date) => {
            if (date) {
                return date.split('/').length === 3;
            }
            else {
                return '';
            }
        },
        sumField: (array, key) => {
            // sum data in give key (property)
            let value = array.reduce((a, b) => a + (b[key] || 0), 0);
            if (key.toLowerCase().includes('valor')) {
                value = value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
            return value;
        },
        diasDaSemana: () => {
            return [
                'Domingo',
                'Segunda-feira',
                'Terça-feira',
                'Quarta-feira',
                'Quinta-feira',
                'Sexta-feira',
                'Sábado',
            ];
        },
        periodosDoDiaArray: () => {
            return [
                'Manhã',
                'Tarde',
                'Noite',
            ];
        },
        cursoModalidadeOptions: () => {
            return [
                { id: 1, nome: 'Regular', dia_por_semana: 1, aula_por_dia: 2, quantidade_meses_por_estagio: 6 },
                { id: 2, nome: 'Intensivo', dia_por_semana: 5, aula_por_dia: 2, quantidade_meses_por_estagio: 1 },
                { id: 3, nome: 'VIP', dia_por_semana: 5, aula_por_dia: 2, quantidade_meses_por_estagio: 6 },
                { id: 4, nome: 'Executive', dia_por_semana: 3, aula_por_dia: 2, quantidade_meses_por_estagio: 6 },
                { id: 5, nome: 'Semi-intensivo', dia_por_semana: 2, aula_por_dia: 2, quantidade_meses_por_estagio: 3 },
            ];
        },
        companyStatusOptions: () => {
            return [
                { id: 1, nome: 'Aguardando primeiro contato' },
                { id: 2, nome: 'Em negociação/apresentação' },
                { id: 3, nome: 'Não fechou negociação' },
                { id: 4, nome: 'Aguardando assinatura do contrato' },
                { id: 5, nome: 'Treinamentos iniciais' },
                { id: 6, nome: 'Em acompanhamento inicial' },
                { id: 7, nome: 'Em uso' },
                { id: 8, nome: 'Cancelou o uso' },
                { id: 9, nome: 'Efetuando a migração manual dos dados' },
                { id: 10, nome: 'Aguardando migração automática' },
                { id: 11, nome: 'Franqueado sumiu' },
            ];
        },
        plugnotasCreditoQuantidade(credito_quantidade) {
            return credito_quantidade == -1 ? 'ilimitado' : credito_quantidade;
        },
        nomeEscola(escolaId) {
            return escolaId == 1 ? 'KNN' : 'Phenom';
        },
        async focusElementById(elementId) {
            const element = document.getElementById(elementId);
            if (element) {
                this.$nextTick(() => {
                    if (elementId.includes('btn')) {
                        // todo something if element is a button
                    }
                    else if (elementId.includes('txt')) {
                        // todo something if element is a text field
                    }
                    element.focus();
                    element.classList.add('searched-focus');
                    setTimeout(() => {
                        element.classList.remove('searched-focus');
                    }, 200);
                });
            }
        },
        getLocalAula(localAulaId) {
            if (!localAulaId) {
                return '';
            }
            const locaisAula = [
                {
                    id: 1,
                    label: 'At School'
                },
                {
                    id: 2,
                    label: 'At Home'
                }
            ];
            return locaisAula.find((localAula) => localAula.id === localAulaId);
        },
        cursoPublicoAlvo(opcao) {
            const opcoes = {
                'Advanced': 'Para quem seja nivelado para este livro ou já tenha concluído Keep Talking',
                'Alemão': 'A partir dos 15 anos',
                'Chiquitos': 'Crianças de 8 a 9 anos já alfabetizadas',
                'Conquering': 'Para crianças de 8 a 9 anos',
                'Discovering': 'Para crianças de 4 a 5 anos',
                'Espanhol avançado': 'A partir dos 15 anos',
                'Espanhol básico': 'A partir dos 15 anos',
                'Espanhol intermediário': 'A partir dos 15 anos',
                'Espanhol': 'Acima de 15 anos para alunos iniciais',
                'Exploring': 'Para crianças de 6 a 7 anos',
                'Formação': 'Acima de 15 anos para alunos iniciais',
                'Francês avançado': 'A partir dos 15 anos',
                'Francês básico': 'A partir dos 15 anos',
                'Francês intermediário': 'A partir dos 15 anos',
                'Francês': 'A partir dos 15 anos',
                'Inner': 'A partir dos 15 anos',
                'Jóvenes': 'Adolescentes de 13 a 15 anos, para alunos iniciais',
                'Keep Talking': 'Para quem seja nivelado para este livro ou já tenha concluído Books ou Teens',
                'Kids': 'Crianças de 7 a 8 anos já alfabetizadas',
                'Kinder': 'Crianças de 4 a 6 anos ainda sem alfabetização',
                'Knowledge': 'Acima de 15 anos para alunos que são notados com dificuldade para o book',
                'Moving Up': 'Para adolescentes de 12 a 14 anos',
                'Muchachos': 'Crianças de 10 a 12 anos',
                'On The Road': 'Para quem seja nivelado para este livro ou já tenha feito pelo menos o Keep Talking',
                'Out There': 'Para pré-adolescentes de 10 a 11 anos',
                'Taking Off': 'A partir dos 15 anos',
                'Teens': 'Adolescentes de 13 a 15 anos para alunos iniciais',
                'Turbine': 'A partir dos 15 anos',
                'Tweens': 'Crianças de 10 a 12 anos',
                'Seeds': 'Acima de 15 anos para alunos iniciais',
                'Italiano': 'Acima de 15 anos para alunos iniciais',
                'Continua a parlare': 'Acima de 15 anos para alunos não iniciais',
            };
            if (opcao) {
                return opcoes[opcao];
            }
            return opcoes;
        },
        getAge: (dateString) => {
            const today = new Date();
            const birthDate = new Date(dateString);
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },
        getMeses: () => {
            return [
                { id: 1, name: 'Janeiro' },
                { id: 2, name: 'Fevereiro' },
                { id: 3, name: 'Março' },
                { id: 4, name: 'Abril' },
                { id: 5, name: 'Maio' },
                { id: 6, name: 'Junho' },
                { id: 7, name: 'Julho' },
                { id: 8, name: 'Agosto' },
                { id: 9, name: 'Setembro' },
                { id: 10, name: 'Outubro' },
                { id: 11, name: 'Novembro' },
                { id: 12, name: 'Dezembro' },
            ];
        },
        turmaStatusOptions() {
            return [
                { id: 1, name: 'Em formação', color: 'yellow' },
                { id: 2, name: 'Vigente', color: 'green' },
                { id: 3, name: 'Cancelada', color: 'red' },
                { id: 4, name: 'Concluído', color: 'blue' },
            ];
        },
        getStatusOcorrenciaTarefa(status) {
            switch (status) {
                case 0:
                    return 'Pendente';
                case 1:
                    return 'Realizada';
                case 2:
                    return 'Cancelada';
            }
        },
        getStatusFinanceiroParcelaStatus(status) {
            switch (status) {
                case 1:
                    return { name: 'Em aberto', color: 'yellow' };
                case 2:
                    return { name: 'Paga', color: 'success' };
                case 3:
                    return { name: 'Cancelada', color: 'error' };
                case 4:
                    return { name: 'Renegociada', color: 'warning' };
                case 5:
                    return { name: 'Protestado', color: 'error' };
            }
        },
        financeiroParcelaStatusOptions() {
            return [
                { id: 1, name: 'Em aberto', color: 'yellow' },
                { id: 2, name: 'Paga', color: 'green' },
                { id: 3, name: 'Cancelada', color: 'red' },
                { id: 4, name: 'Renegociada', color: 'red' },
                { id: 5, name: 'Protestado', color: 'red' },
            ];
        },
        tarefaStatusOptions() {
            return [
                { id: 1, name: 'Pendente', color: 'yellow' },
                { id: 2, name: 'Realizada', color: 'green' },
                { id: 3, name: 'Cancelada', color: 'red' },
            ];
        },
        parceriaNichoOptions() {
            let parceriaNome = 'Club';
            if (mainStore.userProfile.company.escola_id === 1) {
                parceriaNome = 'KNN Club';
            }
            return [
                { id: 1, name: 'Escola', description: 'Instituições de ensino, seja ensino fundamental, médio, técnico, superior ou até mesmo livre e profissional', readOnly: false },
                { id: 2, name: 'Sala de atividade', description: 'Locais de atividades diversas, extra educação, como academias, escolinhas (natação, judô, balé), escoteiros, catequese, células de igreja e auto-escola', readOnly: false },
                { id: 3, name: 'Empresa', description: 'Locais empresáriais como indústrias, comércio, fábricas, farmácias, bancos, escritórios, mercados, postos de gasolina e etc', readOnly: false },
                { id: 4, name: 'Evento', description: 'São características de eventos a ocorrência pontual e trazendo público normalmente relacionado a lazer, alguns exemplos são: competições, exposição, festas, shows, peças, datas comemorativas, palestras, feiras, workshops', readOnly: false },
                { id: 5, name: 'Organização', description: 'Locais com direcionamentos específicos frequentemente relacionados a direitos e socialização, a exemplo: sindicatos, associação, cooperativas, ONGs e clubes', readOnly: false },
                { id: 6, name: 'Marketing Digital', description: 'Parceria de marketing digital', readOnly: true },
                { id: 7, name: parceriaNome, description: `Parceria de ${parceriaNome}`, readOnly: true },
            ];
        },
        parceriaAcaoOptions() {
            return [
                { id: 3, name: 'Abordagem direta', description: 'Ação na qual o próprio colaborador preenche o cupom' },
                { id: 4, name: 'Abordagem indireta', description: 'Ação na qual o interessado preenche o cupom' },
                { id: 2, name: 'Pesquisa', description: 'Ação na qual o colaborador utiliza o formuário de pesquisa como cupom' },
                { id: 1, name: 'Urna', description: 'Ação na qual o cupom é coletado por meio de urna' },
            ];
        },
        parceriaStatusOptions() {
            return [
                { id: 1, name: 'Em prospecção', description: 'Possivel local para parceria' },
                { id: 2, name: 'Em negociação', description: 'Parceria em negociação' },
                { id: 3, name: 'Parceria não consolidada', description: 'Não fechou parceria' },
                { id: 4, name: 'Vigente', description: 'Parceria vigente' },
                { id: 5, name: 'Finalizada', description: 'Parceria finalizada' },
            ];
        },
        parceriaCupomStatusOptions() {
            return [
                { id: 1, name: 'Sem contato', description: 'Cupom que ainda não teve contato', ordem: 3 },
                { id: 2, name: 'Não atendeu', description: 'Cupom que foi efetuada a ligação e não atendeu', ordem: 3 },
                { id: 3, name: 'Agendado', description: 'Cupom com visita agendada', ordem: 0 },
                { id: 4, name: 'Reagendado', description: 'Cupom com visita reagendada', ordem: 0 },
                { id: 5, name: 'Confirmado', description: 'Cupom com visita confirmada', ordem: 0 },
                { id: 6, name: 'Desistiu', description: 'Cupom que desistiu', ordem: 0 },
                { id: 7, name: 'Sem interesse', description: 'Cupom sem interesse', ordem: 0 },
                { id: 8, name: 'Não compareceu', description: 'Não compareceu na visita', ordem: 2 },
                { id: 9, name: 'Confirmado para hoje', description: 'Cupom com visita confirmada para hoje', ordem: 0 },
                { id: 10, name: 'Agendado para hoje', description: 'Agendou visita para hoje', ordem: 1 },
                { id: 11, name: 'Ligar outro momento', description: 'Solicitou para ligar em outro momento', ordem: 2 },
                { id: 12, name: 'Prospectado', description: 'Cupom virou prospex', ordem: 4 },
                { id: 13, name: 'Matriculado', description: 'Cupom virou aluno', ordem: 4 },
                { id: 14, name: 'Caixa postal', description: 'Caixa postal', ordem: 0 },
                { id: 15, name: 'Telefone não existente', description: 'Telefone não existente', ordem: 0 },
            ];
        },
        parceriaCupomParaQuemCursoOptions() {
            return [
                { id: 1, nome: 'Para mim' },
                { id: 2, nome: 'Para meu filho(a)' },
                { id: 3, nome: 'Para meus colaboradores' },
                { id: 4, nome: 'Para meus familiares' },
            ];
        },
        parceriaCupomTipoOptions() {
            return [
                { id: 1, name: 'Real interessado', color: 'yellow' },
                { id: 2, name: 'Comum', color: 'green' },
                { id: 3, name: 'Oportunista', color: 'red' },
            ];
        },
        motivoPerdaOptions() {
            return [
                { id: 1, name: 'Não gosta do idioma, tem trauma' },
                { id: 2, name: 'Precisa ver com o marido/esposa' },
                { id: 3, name: 'Sem disponibilidade financeira (ficou pesado)' },
                { id: 4, name: 'Está desempregado(a)' },
                { id: 5, name: 'Não tem interesse no idioma' },
                { id: 6, name: 'Filho(a) não tem interesse pelo idioma' },
                { id: 7, name: 'Não tem tempo disponível no dia a dia' },
                { id: 8, name: 'Distância grande para chegar na escola' },
                { id: 9, name: 'Não entendeu porque preencheu o cupom' },
                { id: 10, name: 'Outro' },
            ];
        },
        dificuldadeAprenderIdioma() {
            return [
                { id: 1, name: 'Falta de tempo' },
                { id: 2, name: 'Falta de dedicação e/ou empenho' },
                { id: 3, name: 'Entender gramática' },
                { id: 4, name: 'Não aprendi a falar' },
                { id: 5, name: 'Escola longe da minha casa e/ou trabalho' },
                { id: 6, name: 'Não me adaptei à aula online' },
                { id: 7, name: 'Motivos financeiros' },
                { id: 8, name: 'Outro' },
            ];
        },
        objetivoFazerIdiomaOptions() {
            return [
                { id: 1, nome: 'Conseguir emprego' },
                { id: 2, nome: 'Progressão de carreira e aumento de salário' },
                { id: 3, nome: 'Internacionalizar meu negócio' },
                { id: 4, nome: 'Viajar o mundo' },
                { id: 5, nome: 'Conhecer e/ou Conversar com estrangeiros' },
                { id: 6, nome: 'Entretenimento em outros idiomas (Jogos, Filmes, Música, Livro...)' },
                { id: 7, nome: 'Escola e/ou Universidade' },
                { id: 8, nome: 'Investir no futuro do(s) meu(s) filho(s)' },
                { id: 9, nome: 'Aprender novas habilidades' },
                { id: 10, nome: 'Realização pessoal' },
                { id: 11, nome: 'Outros' }
            ];
        },
        masterPreInvoiceStatus(preInvoiceStatusId) {
            switch (preInvoiceStatusId) {
                case -3:
                    return { name: 'Aguardando nivelamento', color: 'blue' };
                case -2:
                    return { name: 'Cancelado', color: 'error' };
                case -1:
                    return { name: 'Erro', color: 'error' };
                case 1:
                    return { name: 'Aguardando pagamento', color: 'error' };
                case 2:
                    return { name: 'A atender', color: 'warning' };
                case 3:
                    return { name: 'A atender', color: 'warning' };
                case 4:
                    return { name: 'Liberado para separação', color: 'warning' };
                case 5:
                    return { name: 'Em separação', color: 'warning' };
                case 6:
                    return { name: 'Aguardando transportadora', color: 'blue' };
                case 7:
                    return { name: 'Ret. pela transportadora', color: 'success' };
                case 8:
                    return { name: 'Entregue/Finalizado', color: 'success' };
            }
        },
        fusoHorarioBrasil() {
            return [
                { id: -2, name: 'Horário Fernando de Noronha - UTC-02:00' },
                { id: -3, name: 'Horário Brasília - UTC-03:00' },
                { id: -4, name: 'Horário Amazonas - UTC-04:00' },
                { id: -5, name: 'Horário Acre - UTC-05:00' },
            ];
        },
        getStatusAlunoTurma(status) {
            switch (status) {
                case 1:
                    return 'green';
                case 2:
                    return 'yellow';
                case 3:
                    return 'red';
                case 4:
                    return 'green';
                case 5:
                    return 'red';
                case 6:
                    return 'red';
            }
        },
        getOcorrenciaTipoReuniao(status) {
            switch (status) {
                case 1:
                    return 'Reunião';
                case 2:
                    return 'Reunião financeira';
                case 3:
                    return 'Reunião pedagógica';
                case 4:
                    return 'Reunião de rendimento';
                case 5:
                    return 'Reunião troca de livro';
                case 6:
                    return 'Reunião rematrícula';
            }
        },
        getProspexStatus(statusId) {
            switch (statusId) {
                case 1:
                    return 'Aguardando contato';
                case 2:
                    return 'Contato em andamento';
                case 3:
                    return 'Matriculado';
                case 4:
                    return 'Cancelado';
            }
        },
        getFormaDePagamento(statusId) {
            switch (parseInt(statusId, 0)) {
                case 1:
                    return 'Dinheiro';
                case 2:
                    return 'Cartão de Crédito';
                case 3:
                    return 'Cartão de Débito';
                case 4:
                    return 'Boleto';
                case 5:
                    return 'Transferência Eletrônica';
                case 6:
                    return 'Cheque';
                case 7:
                    return 'PIX';
                case 8:
                    return 'Pagamento de cobrança PIX';
                case 9:
                    return 'Transferência por chave PIX';
                case 10:
                    return 'Baixa Manual';
            }
        },
        getStatusOptions(status_id) {
            const status = [
                {
                    id: -3,
                    name: 'Aguardando nivelamento',
                    color: 'blue',
                    description: 'Pedido pendente de nivelamento do aluno. Depois de realizado, deve-se selecionar o produto que o aluno deverá receber',
                },
                {
                    id: -2,
                    name: 'Cancelado',
                    color: 'error',
                    description: 'Pedido cancelado',
                },
                {
                    id: -1,
                    name: 'Processando pedido',
                    color: 'error',
                    description: 'Nesta etapa, pode ter ocorrido algum problema com seu pedido, mas não se preocupe, a KNN já está trabalhando para resolvê-lo',
                },
                {
                    id: 1,
                    name: 'Aguardando pagamento',
                    color: 'error',
                    description: 'Seu pedido está com o pagamento pendente. Efetue o pagamento em até 7 dias para que o pedido não seja cancelado. Ah, se você já efetuou o pagamento, aguarde algumas horas para que o mesmo seja processado!',
                },
                {
                    id: 2,
                    name: 'Em andamento',
                    color: 'warning',
                    description: 'Pedido em processamento',
                },
                {
                    id: 5,
                    name: 'Em andamento',
                    color: 'warning',
                    description: 'Pedido em processamento',
                },
                {
                    id: 3,
                    name: 'A atender',
                    color: 'warning',
                    description: 'Pedido criado no Nectar, aguardando atendimento',
                },
                {
                    id: 6,
                    name: 'Aguardando envio',
                    color: 'blue',
                    description: 'Falta pouco! Estamos aguardando a transportadora para levar seu pedido',
                },
                {
                    id: 7,
                    name: 'Enviado',
                    color: 'success',
                    description: 'Aêeee! Seu pedido já está à caminho!',
                },
                {
                    id: 0,
                    name: 'Nenhuma compra encontrada',
                    color: '',
                    description: '',
                },
            ];
            const findStatus = status.find((statusItem) => {
                if (statusItem.id === status_id) {
                    return statusItem;
                }
            });
            return findStatus;
        },
        plugnotasRegimeTributario() {
            return [
                { id: 0, name: 'Nenhum' },
                { id: 1, name: 'Simples Nacional' },
                { id: 2, name: 'Simples Nacional - Excesso' },
                { id: 3, name: 'Regime Normal - Lucro Presumido' },
                { id: 4, name: 'Normal - Lucro Real' },
            ];
        },
        plugnotasRegimeTributarioEspecial() {
            return [
                { id: 0, name: 'Sem Regime Tributário Especial' },
                { id: 1, name: 'Micro Empresa Municipal' },
                { id: 2, name: 'Estimativa' },
                { id: 3, name: 'Sociedade de Profissionais' },
                { id: 4, name: 'Cooperativa' },
                { id: 5, name: 'Microempresário Individual - MEI' },
                { id: 6, name: 'Microempresa ou Pequeno Porte - ME EPP' },
                { id: 7, name: 'Lucro Real' },
                { id: 8, name: 'Lucro Presumido' },
                { id: 9, name: 'Tributação Normal' },
                { id: 10, name: 'Simples nacional com excesso do sublimite' },
                { id: 11, name: 'Empresa de Responsabilidade Limitada' },
            ];
        },
        formasDePagamento() {
            return [
                { id: 1, name: 'Dinheiro' },
                { id: 2, name: 'Cartão de Crédito' },
                { id: 3, name: 'Cartão de Débito' },
                { id: 4, name: 'Boleto' },
                { id: 5, name: 'Transferência Eletrônica' },
                { id: 6, name: 'Cheque' },
                { id: 7, name: 'PIX' },
                { id: 8, name: 'Pagamento de cobrança PIX' },
                { id: 9, name: 'Transferência por chave PIX' },
                { id: 10, name: 'Baixa Manual' },
            ];
        },
        getCaixaStatus(statusId) {
            switch (statusId) {
                case 0:
                    return 'Fechado';
                case 1:
                    return 'Aberto';
                case 2:
                    return 'Conferido';
            }
        },
        ocorrenciaTarefaStatus(id) {
            switch (id) {
                case 0:
                    return { id: 0, nome: 'Pendente', color: 'warning' };
                case 1:
                    return { id: 1, nome: 'Realizada', color: 'success' };
                case 2:
                    return { id: 2, nome: 'Cancelada', color: 'error' };
            }
        },
        getStatusDoContratoOptions: () => {
            // Status do aluno perante o contrato
            return [
                {
                    id: 1,
                    name: 'Vigente',
                    color: 'green',
                    description: 'Aluno em turma com status vigente, aprovado ou reprovado',
                    class: 'success'
                },
                // { id: 2, name: 'Transferido', color: 'yellow', description: 'Transferido para outra unidade', class: 'warning' }, descomentar
                { id: 3, name: 'Trancado', color: 'red', description: 'Aluno com contrato trancado', class: 'error' },
                { id: 5, name: 'Evadido', color: 'red', description: 'Aluno que foi evadido', class: 'error' },
                { id: 6, name: 'Desistente', color: 'red', description: 'Aluno com contrato rescindido', class: 'error' },
                { id: null, name: 'Sem contrato', color: 'red', description: 'Aluno sem contrato', class: 'error' },
                {
                    id: 10,
                    name: 'Matriculado',
                    color: 'green',
                    description: 'Aluno com contrato ativo e que não está em turma',
                    class: 'warning'
                },
                { id: 11, name: 'Pendente rematrícula', color: 'blue', description: 'Terminou o contrato e aguardando fazer algo', class: 'secondary' },
                { id: 12, name: 'Rematriculado', color: 'blue', description: 'Aluno vai continuar estudando', class: 'secondary' },
                { id: 13, name: 'Não aceitou rematricular', color: 'blue', description: 'Aluno não quer mais estudar', class: 'secondary' },
                { id: 14, name: 'Idioma finalizado', color: 'blue', description: 'Aluno estudou até o último livro do idioma', class: 'secondary' },
            ];
        },
        getStatusDoAlunoOptions: () => {
            // Status do aluno perante o contrato
            return [
                { id: 1, name: 'Vigente', color: 'green', description: 'Aluno em turma com status vigente', class: 'success' },
                {
                    id: 2,
                    name: 'Transferido',
                    color: 'yellow',
                    description: 'Transferido para outra unidade',
                    class: 'warning'
                },
                { id: 3, name: 'Trancado', color: 'red', description: 'Aluno com contrato trancado', class: 'error' },
                { id: 4, name: 'Reativado', color: 'green', class: 'warning' },
                { id: 5, name: 'Evadido', color: 'red', description: 'Aluno que foi evadido', class: 'error' },
                { id: 6, name: 'Desistente', color: 'red', description: 'Aluno com contrato rescindido', class: 'error' },
                { id: 7, name: 'Aprovado', color: 'green', class: 'success' },
                { id: 8, name: 'Reprovado', color: 'red', class: 'error' },
                { id: 9, name: 'Removido', color: 'red', class: 'error' },
                {
                    id: 10,
                    name: 'Matriculado',
                    color: 'green',
                    description: 'Aluno com contrato ativo e que não está em turma',
                    class: 'warning'
                },
                { id: 11, name: 'Pendente rematrícula', color: 'blue', description: 'Terminou o contrato e aguardando fazer algo', class: 'secondary' },
                { id: 12, name: 'Rematriculado', color: 'blue', description: 'Aluno vai continuar estudando', class: 'secondary' },
                { id: 13, name: 'Não aceitou rematricular', color: 'blue', description: 'Aluno não quer mais estudar', class: 'secondary' },
                { id: 14, name: 'Idioma finalizado', color: 'blue', description: 'Aluno estudou até o último livro do idioma', class: 'secondary' },
                { id: null, name: 'Sem contrato', color: 'red', description: 'Aluno sem contrato', class: 'error' },
            ];
        },
        getIdiomas() {
            if (mainStore.userProfile.company.escola_id == 1) {
                return ['Inglês', 'Espanhol', 'Francês', 'Alemão', 'Italiano'];
            }
            return ['Inglês', 'Espanhol', 'Francês'];
        },
        getObjetivoFazerOutroIdioma() {
            return [
                'Conseguir emprego',
                'Progressão de carreira e aumento de salário',
                'Internacionalizar meu negócio',
                'Viajar o mundo',
                'Conhecer e/ou Conversar com estrangeiros',
                'Entretenimento em outros idiomas (Jogos, Filmes, Música, Livro...)',
                'Escola e/ou Universidade',
                'Investir no futuro do(s) meu(s) filho(s)',
                'Aprender novas habilidades',
                'Realização pessoal',
                'Outros',
            ];
        },
        getNomeProspex() {
            return mainStore.userProfile.company.escola_id == 1 ? 'Prospex' : 'Checkin';
        },
        getNomeCDA() {
            return mainStore.userProfile.company.escola_id == 1 ? 'CDA' : 'SDR';
        },
        getNomeHomework() {
            return mainStore.userProfile.company.escola_id == 1 ? 'Homework' : 'Keep Practicing';
        },
        getDomain() {
            return mainStore.userProfile.company.escola_id == 1 ? 'knnidiomas' : 'phenomidiomas';
        },
        getNomeAulaExtraSingular() {
            return mainStore.userProfile.company.escola_id == 1 ? 'Aula extra' : 'Aula plus';
        },
        getNomeAulaExtraPlural() {
            return mainStore.userProfile.company.escola_id == 1 ? 'Aulas extras' : 'Aulas plus';
        },
        getNomeEscola() {
            return mainStore.userProfile.company.escola_id == 1 ? 'KNN' : 'Phenom';
        },
        getNomeMarketingDigital() {
            return mainStore.userProfile.company.escola_id == 1 ? 'Digital Nacional KNN' : 'Digital Phenomenal';
        },
        getQuantidadeDescontoMarketingDigital() {
            return mainStore.userProfile.company.escola_id == 1 ? '100' : '15';
        },
        getFinanceiroCategoriaRescisaoContratual109() {
            // @ts-ignore
            return _.find(financeiroCategoriaStore.FinanceiroCategorias, (item) => item.id_antigo === 109).id;
        },
        getFinanceiroCategoriaTaxaMatricula108() {
            // @ts-ignore
            return _.find(financeiroCategoriaStore.FinanceiroCategorias, (item) => item.id_antigo === 108).id;
        },
        getFinanceiroCategoriaTaxaReposicaoAula107() {
            // @ts-ignore
            return _.find(financeiroCategoriaStore.FinanceiroCategorias, (item) => item.id_antigo === 107).id;
        },
        getFinanceiroCategoriaTaxaReforco106() {
            // @ts-ignore
            return _.find(financeiroCategoriaStore.FinanceiroCategorias, (item) => item.id_antigo === 106).id;
        },
        getFinanceiroCategoriaCurso105() {
            // @ts-ignore
            return _.find(financeiroCategoriaStore.FinanceiroCategorias, (item) => item.id_antigo === 105).id;
        },
        getFinanceiroCategoriaMaterialDidatico104() {
            // @ts-ignore
            return _.find(financeiroCategoriaStore.FinanceiroCategorias, (item) => item.id_antigo === 104).id;
        },
        getFinanceiroCategoriaTaxaAvulsa103() {
            // @ts-ignore
            return _.find(financeiroCategoriaStore.FinanceiroCategorias, (item) => item.id_antigo === 103).id;
        },
        motivosAulaExtraOptions() {
            return [
                { id: 1, name: 'Pós-nivelamento' },
                { id: 2, name: 'Suporte' },
                { id: 3, name: 'Rendimento' },
                { id: 4, name: 'Motivacional' },
            ];
        },
        buscaMatriculaTipo(tipo) {
            if (tipo === 1) {
                return 'Matrícula';
            }
            if (tipo === 2) {
                return 'Rematrícula';
            }
        },
        getFinanceiroParcelaStatusIdEmAberto() {
            return 1;
        },
        getFinanceiroParcelaStatusIdPago() {
            return 2;
        },
        getFinanceiroParcelaStatusIdCancelada() {
            return 3;
        },
        getFinanceiroParcelaStatusIdRenegociada() {
            return 4;
        },
        getFinanceiroParcelaStatusIdProtestado() {
            return 5;
        },
        getChipStyle(status) {
            const styles = {
                'Na fila': {
                    color: 'var(--v-red900)',
                    background: 'var(--v-red400)',
                    border: '1px solid var(--v-red900)'
                },
                'Em desenvolvimento': {
                    color: 'var(--v-orange900)',
                    background: 'var(--v-orange300)',
                    border: '1px solid var(--v-orange900)'
                },
                'Finalizado': {
                    color: 'var(--v-green900)',
                    background: 'var(--v-green300)',
                    border: '1px solid var(--v-green900)'
                }
            };
            return styles[status] || {};
        }
    },
    computed: {
        isMobile: () => vuetify.framework.breakpoint.mobile,
    }
});
