import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let CompanyConfiguracaoModule = class CompanyConfiguracaoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.companyConfiguracoes = {};
    }
    setCompanyConfiguracoes(payload) {
        this.companyConfiguracoes = payload;
    }
    async getCompanyConfiguracoes(companyId = null) {
        try {
            const response = await api.getCompanyConfiguracoes(mainStore.token, companyId);
            if (response) {
                this.setCompanyConfiguracoes(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateCompanyConfiguracoesContrato(contrato) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.updateCompanyConfiguracoesContrato(mainStore.token, contrato);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Contrato atualizado com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async restaurarContratoPadrao() {
        const loadingNotification = { content: 'Restaurando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.restaurarContratoPadrao(mainStore.token);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Modelo padrão restaurado com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateCompanyConfiguracoes(contrato) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.updateCompanyConfiguracoes(mainStore.token, contrato);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Configurações atualizadas com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async sincronizarSponte(payload) {
        try {
            const response = await api.sincronizarSponte(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async sincronizarMatricom() {
        try {
            const response = await api.sincronizarMatricom(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async companyConfiguracaoRestaurarPadrao(tipo) {
        try {
            const response = await api.restaurarPadraoTipo(mainStore.token, tipo);
            if (response) {
                const configuracoes = { ...this.companyConfiguracoes, ...response.data };
                this.setCompanyConfiguracoes(configuracoes);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateCompanyConfiguracoesPermiteVisualizarCompartilharTumasAtHome(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            await api.updateCompanyConfiguracoesPermiteVisualizarCompartilharTumasAtHome(mainStore.token, payload.permiteVisualizarCompartilharTurmasAtHome);
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], CompanyConfiguracaoModule.prototype, "setCompanyConfiguracoes", null);
__decorate([
    Action
], CompanyConfiguracaoModule.prototype, "getCompanyConfiguracoes", null);
__decorate([
    Action
], CompanyConfiguracaoModule.prototype, "updateCompanyConfiguracoesContrato", null);
__decorate([
    Action
], CompanyConfiguracaoModule.prototype, "restaurarContratoPadrao", null);
__decorate([
    Action
], CompanyConfiguracaoModule.prototype, "updateCompanyConfiguracoes", null);
__decorate([
    Action
], CompanyConfiguracaoModule.prototype, "sincronizarSponte", null);
__decorate([
    Action
], CompanyConfiguracaoModule.prototype, "sincronizarMatricom", null);
__decorate([
    Action
], CompanyConfiguracaoModule.prototype, "companyConfiguracaoRestaurarPadrao", null);
__decorate([
    Action
], CompanyConfiguracaoModule.prototype, "updateCompanyConfiguracoesPermiteVisualizarCompartilharTumasAtHome", null);
CompanyConfiguracaoModule = __decorate([
    Module({ name: 'companyConfiguracao' })
], CompanyConfiguracaoModule);
export default CompanyConfiguracaoModule;
