import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let RotaModule = class RotaModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.rotas = [];
        this.alunoSemTransporte = [];
        this.alunoRetirarTransporte = [];
    }
    setRotas(payload) {
        this.rotas = payload;
    }
    setRota(payload) {
        const rotas = this.rotas.filter((company) => company.id !== payload.id);
        rotas.push(payload);
        this.rotas = rotas;
    }
    setAlunoSemTransporte(payload) {
        this.alunoSemTransporte = payload;
    }
    setAlunoRetirarTransporte(payload) {
        this.alunoRetirarTransporte = payload;
    }
    async createRota(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createRota(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Rota criado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateRota(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateRota(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Rota atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getRotas() {
        try {
            const response = await api.getRotas(mainStore.token);
            if (response) {
                this.setRotas(response.data);
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getRota(id) {
        try {
            const response = await api.getRota(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getRotaAlunos(id) {
        try {
            const response = await api.getRotaAlunos(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAlunoSemTransporte() {
        try {
            const response = await api.getAlunoSemTransporte(mainStore.token);
            if (response) {
                this.setAlunoSemTransporte(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAlunoRetirarTransporte() {
        try {
            const response = await api.getAlunoRetirarTransporte(mainStore.token);
            if (response) {
                this.setAlunoRetirarTransporte(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getRotaPDF(id) {
        try {
            const response = await api.getRotaPDF(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteRota(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteRota(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Rota excluído com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], RotaModule.prototype, "setRotas", null);
__decorate([
    Mutation
], RotaModule.prototype, "setRota", null);
__decorate([
    Mutation
], RotaModule.prototype, "setAlunoSemTransporte", null);
__decorate([
    Mutation
], RotaModule.prototype, "setAlunoRetirarTransporte", null);
__decorate([
    Action
], RotaModule.prototype, "createRota", null);
__decorate([
    Action
], RotaModule.prototype, "updateRota", null);
__decorate([
    Action
], RotaModule.prototype, "getRotas", null);
__decorate([
    Action
], RotaModule.prototype, "getRota", null);
__decorate([
    Action
], RotaModule.prototype, "getRotaAlunos", null);
__decorate([
    Action
], RotaModule.prototype, "getAlunoSemTransporte", null);
__decorate([
    Action
], RotaModule.prototype, "getAlunoRetirarTransporte", null);
__decorate([
    Action
], RotaModule.prototype, "getRotaPDF", null);
__decorate([
    Action
], RotaModule.prototype, "deleteRota", null);
RotaModule = __decorate([
    Module({ name: 'rota' })
], RotaModule);
export default RotaModule;
