import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let CompanyConfiguracaoMaterialParcelamentoModule = class CompanyConfiguracaoMaterialParcelamentoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.companyConfiguracaoMaterialParcelamentos = [];
    }
    setCompanyConfiguracaoMaterialParcelamentos(payload) {
        this.companyConfiguracaoMaterialParcelamentos = payload;
    }
    async createCompanyConfiguracaoMaterialParcelamento(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createCompanyConfiguracaoMaterialParcelamento(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Configuração criada com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateCompanyConfiguracaoMaterialParcelamento(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateCompanyConfiguracaoMaterialParcelamento(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Configuração atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateCompanyConfiguracaoMaterialParcelamentos(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = api.updateCompanyConfiguracaoMaterialParcelamentos(mainStore.token, payload);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Configuração atualizada com sucesso',
                color: 'success',
            });
            return;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getCompanyConfiguracaoMaterialParcelamentos() {
        try {
            const response = await api.getCompanyConfiguracaoMaterialParcelamentos(mainStore.token);
            if (response) {
                this.setCompanyConfiguracaoMaterialParcelamentos(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCompanyConfiguracaoMaterialParcelamento(id) {
        try {
            const response = await api.getCompanyConfiguracaoMaterialParcelamento(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async deleteCompanyConfiguracaoMaterialParcelamento(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteCompanyConfiguracaoMaterialParcelamento(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Configuração excluida com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async companyConfiguracaoMaterialParcelamentoRestaurarPadrao(tipo) {
        try {
            const response = await api.restaurarPadraoTipo(mainStore.token, tipo);
            if (response) {
                const materialParcelamento = response.data;
                this.setCompanyConfiguracaoMaterialParcelamentos(materialParcelamento);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], CompanyConfiguracaoMaterialParcelamentoModule.prototype, "setCompanyConfiguracaoMaterialParcelamentos", null);
__decorate([
    Action
], CompanyConfiguracaoMaterialParcelamentoModule.prototype, "createCompanyConfiguracaoMaterialParcelamento", null);
__decorate([
    Action
], CompanyConfiguracaoMaterialParcelamentoModule.prototype, "updateCompanyConfiguracaoMaterialParcelamento", null);
__decorate([
    Action
], CompanyConfiguracaoMaterialParcelamentoModule.prototype, "updateCompanyConfiguracaoMaterialParcelamentos", null);
__decorate([
    Action
], CompanyConfiguracaoMaterialParcelamentoModule.prototype, "getCompanyConfiguracaoMaterialParcelamentos", null);
__decorate([
    Action
], CompanyConfiguracaoMaterialParcelamentoModule.prototype, "getCompanyConfiguracaoMaterialParcelamento", null);
__decorate([
    Action
], CompanyConfiguracaoMaterialParcelamentoModule.prototype, "deleteCompanyConfiguracaoMaterialParcelamento", null);
__decorate([
    Action
], CompanyConfiguracaoMaterialParcelamentoModule.prototype, "companyConfiguracaoMaterialParcelamentoRestaurarPadrao", null);
CompanyConfiguracaoMaterialParcelamentoModule = __decorate([
    Module({ name: 'companyConfiguracaoMaterialParcelamento' })
], CompanyConfiguracaoMaterialParcelamentoModule);
export default CompanyConfiguracaoMaterialParcelamentoModule;
