import { __decorate } from "tslib";
import { api } from '@/api';
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils';
import router from '@/router';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { UNAUTHORIZED } from 'http-status-codes';
let MainModule = class MainModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.token = '';
        this.companiesToLogin = [];
        this.isLoggedIn = null;
        this.logInError = false;
        this.sendPassword = false;
        this.userProfile = null;
        this.dashboardMiniDrawer = false;
        this.dashboardShowDrawer = true;
        this.notifications = [];
        this.habilitadoLogin2FA = false;
        this.palavrasTraduzidas = {
            Inglês: {
                ouvindo: 'Listening',
                glossario: 'Glossary',
                pratica: 'Pratice',
                EXPRESSION: 'Expression',
                VERB: 'Verb',
                WORD: 'Word',
            },
            Francês: {
                ouvindo: 'Écoute',
                glossario: 'Glossaire',
                pratica: 'Pratique',
                EXPRESSION: 'Expression',
                VERB: 'Verbe',
                WORD: 'Mot',
            },
            Alemão: {
                ouvindo: 'Hören',
                glossario: 'Glossar',
                pratica: 'Trainieren',
                EXPRESSION: 'Ausdruck',
                VERB: 'Verb',
                WORD: 'Wort',
            },
            Espanhol: {
                ouvindo: 'Escuchando',
                glossario: 'Glosario',
                pratica: 'Práctica',
                EXPRESSION: 'Expresión',
                VERB: 'Verbo',
                WORD: 'Palabra',
            },
        };
    }
    get hasAdminAccess() {
        return (this.userProfile && this.userProfile.is_superuser && this.userProfile.is_active);
    }
    get firstNotification() {
        if (this.notifications) {
            return this.notifications.length > 0 && this.notifications[0];
        }
    }
    setCompaniesListToLogin(payload) {
        this.companiesToLogin = payload;
    }
    setLogin2FA(habilitadoLogin2FA) {
        this.habilitadoLogin2FA = habilitadoLogin2FA;
    }
    setToken(payload) {
        this.token = payload;
    }
    setLoggedIn(payload) {
        this.isLoggedIn = payload;
    }
    setLogInError(payload) {
        this.logInError = payload;
    }
    setSendPassword(payload) {
        this.sendPassword = payload;
    }
    setUserProfile(payload) {
        this.userProfile = payload;
    }
    setDashboardMiniDrawer(payload) {
        this.dashboardMiniDrawer = payload;
    }
    setDashboardShowDrawer(payload) {
        this.dashboardShowDrawer = payload;
    }
    addNotification(payload) {
        if (this.notifications.length > 0) {
            if (this.notifications[this.notifications.length - 1].content !== payload.content) {
                this.notifications.push(payload);
            }
        }
        else {
            this.notifications.push(payload);
        }
    }
    removeNotification(payload) {
        this.notifications = this.notifications.filter((notification) => notification !== payload);
    }
    addErrorToasted({ toasted, errors }) {
        errors.forEach((err) => {
            let found = false;
            // @ts-ignore
            for (const toast of toasted.toasts) {
                if (toast.el.innerText === err) {
                    found = true;
                    return;
                }
            }
            // @ts-ignore
            toasted.show(err, {
                type: 'error',
                position: 'top-right',
                duration: 3000,
                icon: 'mdi-alert-circle',
                iconPack: 'mdi',
                theme: 'bubble',
                action: {
                    icon: 'mdi-close',
                    text: '',
                    onClick: (e, toastObj) => {
                        toastObj.goAway(0);
                    }
                },
                className: 'toast-style',
                containerClass: 'toast-container'
            });
        });
    }
    async logIn(payload) {
        const loadingNotification = { content: 'Efetuando login', showProgress: true };
        const loggedNotification = {
            content: 'Login efetuado com sucesso!',
            color: 'success'
        };
        const errorNotification = {
            content: 'Usuário ou senha incorreta',
            color: 'error',
        };
        try {
            this.addNotification(loadingNotification);
            const response = await api.logInGetToken(payload.username, payload.password, payload.admin, payload.gToken, payload.codeTwoFA, payload.company_id);
            if (response.data.login_2fa_enabled) {
                this.setLogin2FA(response.data.login_2fa_enabled);
                this.removeNotification(loadingNotification);
                return;
            }
            else if (response.data.companies && response.data.companies.length > 1) {
                this.setCompaniesListToLogin(response.data.companies);
                this.removeNotification(loadingNotification);
                return;
            }
            const token = response.data.access_token;
            if (token) {
                saveLocalToken(token);
                this.setToken(token);
                this.setLoggedIn(true);
                this.setLogInError(false);
                await this.getUserProfile();
                if (this.userProfile.is_aluno && this.userProfile.company.escola_id == 2) {
                    await this.routeLoggedInAluno();
                }
                else {
                    await this.routeLoggedIn();
                }
                this.removeNotification(loadingNotification);
                this.addNotification(loggedNotification);
                this.removeNotificationDelayed({ notification: loggedNotification, timeout: 300 });
            }
            else {
                await this.logOut();
            }
        }
        catch (err) {
            this.removeNotification(loadingNotification);
            this.addNotification({
                content: err.response.data.detail,
                color: 'error',
            });
        }
    }
    async getUserProfile() {
        try {
            const response = await api.getMe(this.token);
            if (response.data) {
                // @ts-ignore
                this.setUserProfile(response.data);
            }
        }
        catch (error) {
            await this.checkApiError(error);
        }
    }
    async updateUserProfile(payload) {
        try {
            const loadingNotification = { content: 'Salvando', showProgress: true };
            this.addNotification(loadingNotification);
            const response = (await Promise.all([
                api.updateMe(this.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            // @ts-ignore
            this.setUserProfile(response.data);
            this.removeNotification(loadingNotification);
            this.addNotification({
                content: 'Usuário atualizado com sucesso',
                color: 'success',
            });
        }
        catch (error) {
            await this.checkApiError(error);
        }
    }
    async checkLoggedIn() {
        if (!this.isLoggedIn) {
            let token = this.token;
            if (!token) {
                const localToken = getLocalToken();
                if (localToken) {
                    this.setToken(localToken);
                    token = localToken;
                }
            }
            if (token) {
                try {
                    const response = await api.getMe(token);
                    this.setLoggedIn(true);
                    // @ts-ignore
                    this.setUserProfile(response.data);
                }
                catch (error) {
                    await this.removeLogIn();
                }
            }
            else {
                await this.removeLogIn();
            }
        }
    }
    async removeLogIn() {
        removeLocalToken();
        this.setToken('');
        this.setLoggedIn(false);
    }
    async logOut() {
        const is_aluno = this.userProfile.is_aluno;
        await this.removeLogIn();
        await this.routeLogOut(is_aluno);
        this.setLogin2FA(false);
        this.setCompaniesListToLogin([]);
    }
    async userLogOut() {
        const logoutNotification = { content: 'Você foi deslogado com sucesso!', color: 'success' };
        await this.logOut();
        this.addNotification(logoutNotification);
        this.removeNotificationDelayed({ notification: logoutNotification, timeout: 600 });
    }
    async routeLogOut(is_aluno) {
        if (router.currentRoute.path !== '/login') {
            if (is_aluno) {
                router.push('/portal');
            }
            else {
                router.push('/login');
            }
        }
    }
    async checkApiError(payload) {
        if (payload.response && payload.response.status === UNAUTHORIZED) {
            await this.logOut();
        }
        if (payload.request) {
            if (payload.request.status === 405) {
                window.location.href = '/main/dashboard';
            }
        }
    }
    async routeLoggedIn() {
        if (router.currentRoute.path === '/login' || router.currentRoute.path === '/' || router.currentRoute.path === '/portal') {
            router.push('/main');
        }
    }
    async routeLoggedInAluno() {
        if (router.currentRoute.path === '/login' || router.currentRoute.path === '/' || router.currentRoute.path === '/portal') {
            router.push('/main/Pedagogico');
        }
    }
    async removeNotificationDelayed(payload) {
        return new Promise((resolve) => {
            setTimeout(() => {
                this.removeNotification(payload.notification);
                resolve(true);
            }, payload.timeout);
        });
    }
    async recoverPassword(payload) {
        const loadingNotification = {
            content: 'Enviando e-mail de recuperação de senha',
            showProgress: true,
        };
        try {
            this.addNotification(loadingNotification);
            await Promise.all([
                api.passwordRecovery(payload.email),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            this.removeNotification(loadingNotification);
            this.addNotification({
                content: 'E-mail enviado!',
                color: 'success',
            });
            this.setSendPassword(true);
        }
        catch (error) {
            this.setSendPassword(false);
            this.removeNotification(loadingNotification);
            this.addNotification({ color: 'error', content: 'Usuário errado' });
        }
    }
    async resetPassword(payload) {
        const loadingNotification = { content: 'Atualizando senha', showProgress: true };
        try {
            this.addNotification(loadingNotification);
            await Promise.all([
                api.resetPassword(this.token, payload.password, payload.email, payload.user_id),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            this.removeNotification(loadingNotification);
            this.addNotification({
                content: 'Senha atualizada com sucesso',
                color: 'success',
            });
            this.setSendPassword(true);
        }
        catch (error) {
            this.setSendPassword(false);
            this.removeNotification(loadingNotification);
            this.addNotification({
                color: 'error',
                content: 'Ocorreu um erro ao resetar a senha',
            });
        }
    }
};
__decorate([
    Mutation
], MainModule.prototype, "setCompaniesListToLogin", null);
__decorate([
    Mutation
], MainModule.prototype, "setLogin2FA", null);
__decorate([
    Mutation
], MainModule.prototype, "setToken", null);
__decorate([
    Mutation
], MainModule.prototype, "setLoggedIn", null);
__decorate([
    Mutation
], MainModule.prototype, "setLogInError", null);
__decorate([
    Mutation
], MainModule.prototype, "setSendPassword", null);
__decorate([
    Mutation
], MainModule.prototype, "setUserProfile", null);
__decorate([
    Mutation
], MainModule.prototype, "setDashboardMiniDrawer", null);
__decorate([
    Mutation
], MainModule.prototype, "setDashboardShowDrawer", null);
__decorate([
    Mutation
], MainModule.prototype, "addNotification", null);
__decorate([
    Mutation
], MainModule.prototype, "removeNotification", null);
__decorate([
    Mutation
], MainModule.prototype, "addErrorToasted", null);
__decorate([
    Action
], MainModule.prototype, "logIn", null);
__decorate([
    Action
], MainModule.prototype, "getUserProfile", null);
__decorate([
    Action
], MainModule.prototype, "updateUserProfile", null);
__decorate([
    Action
], MainModule.prototype, "checkLoggedIn", null);
__decorate([
    Action
], MainModule.prototype, "removeLogIn", null);
__decorate([
    Action
], MainModule.prototype, "logOut", null);
__decorate([
    Action
], MainModule.prototype, "userLogOut", null);
__decorate([
    Action
], MainModule.prototype, "routeLogOut", null);
__decorate([
    Action
], MainModule.prototype, "checkApiError", null);
__decorate([
    Action
], MainModule.prototype, "routeLoggedIn", null);
__decorate([
    Action
], MainModule.prototype, "routeLoggedInAluno", null);
__decorate([
    Action
], MainModule.prototype, "removeNotificationDelayed", null);
__decorate([
    Action
], MainModule.prototype, "recoverPassword", null);
__decorate([
    Action
], MainModule.prototype, "resetPassword", null);
MainModule = __decorate([
    Module({ name: 'main' })
], MainModule);
export default MainModule;
