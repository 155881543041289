import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let TurmaAlunoAtividadeModule = class TurmaAlunoAtividadeModule extends VuexModule {
    async createTurmaAlunosAtividades(content) {
        try {
            const response = await api.createTurmaAlunosAtividades(mainStore.token, content);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateHomeworkPendente(payload) {
        try {
            const response = await api.updateHomeworkPendente(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTurmaAlunosHomeworksPendentes(turmaId) {
        try {
            const response = await api.getTurmaAlunosHomeworksPendentes(mainStore.token, turmaId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTurmaAlunosHomeworksPendentesTurmas() {
        try {
            const response = await api.getTurmaAlunosHomeworksPendentesTurmas(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTurmaAlunoAtividadeOpcoesReposicao(turmaAlunoAtividadeId) {
        try {
            const response = await api.getTurmaAlunoAtividadeOpcoesReposicao(mainStore.token, turmaAlunoAtividadeId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getHorariosDisponiveis(turmaAlunoAtividadeId) {
        try {
            const response = await api.getHorariosDisponiveis(mainStore.token, turmaAlunoAtividadeId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async naoIraRepor(payload) {
        try {
            const response = await api.turmaAlunoAtividadeNaoIraRepor(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async marcarReposicao(payload) {
        try {
            const response = await api.turmaAlunoAtividadeMarcarReposicao(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async turmaAlunoAtividadeUpdate(payload) {
        const loadingNotification = { content: 'Atualizando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.turmaAlunoAtividadeUpdate(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Atualização realizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Ocorreu um erro ao atualizar, verifique a sua conexão com a internet',
                color: 'error',
            });
            await mainStore.checkApiError(error);
            return false;
        }
    }
    async getRelatorioFaltasQuery(queryIn) {
        try {
            const response = await api.getRelatorioFaltasQuery(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async enviaArquivoAtividade(payload) {
        const loadingNotification = { content: 'Atualizando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.enviaArquivoAtividade(mainStore.token, payload.id, payload.file);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Arquivo enviado com sucesso.',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Erro ao enviar arquivo ' + error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async enviaArquivoCorrecao(payload) {
        const loadingNotification = { content: 'Atualizando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.enviaArquivoCorrecao(mainStore.token, payload.id, payload.file);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Arquivo enviado com sucesso.',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Erro ao enviar arquivo ' + error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async downloadAtividade(payload) {
        try {
            const response = await api.downloadAtividade(mainStore.token, payload.turmaAlunoAtividade, payload.bucketArquivoAtividadeId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async downloadCorrecao(payload) {
        try {
            const response = await api.downloadCorrecao(mainStore.token, payload.turmaAlunoAtividade, payload.bucketArquivoCorrecaoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async turmaAlunoAtividadeExcluirTarefa(payload) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            const response = await api.turmaAlunoAtividadeExcluirTarefa(mainStore.token, payload.turmaAlunoAtividade, payload.bucketArquivoAtividadeId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Atividade excluída com sucesso.',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Erro: ' + error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async turmaAlunoAtividadePresencaAluno(payload) {
        try {
            const response = await api.turmaAlunoAtividadePresencaAluno(mainStore.token, payload.turmaAtividadeId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], TurmaAlunoAtividadeModule.prototype, "createTurmaAlunosAtividades", null);
__decorate([
    Action
], TurmaAlunoAtividadeModule.prototype, "updateHomeworkPendente", null);
__decorate([
    Action
], TurmaAlunoAtividadeModule.prototype, "getTurmaAlunosHomeworksPendentes", null);
__decorate([
    Action
], TurmaAlunoAtividadeModule.prototype, "getTurmaAlunosHomeworksPendentesTurmas", null);
__decorate([
    Action
], TurmaAlunoAtividadeModule.prototype, "getTurmaAlunoAtividadeOpcoesReposicao", null);
__decorate([
    Action
], TurmaAlunoAtividadeModule.prototype, "getHorariosDisponiveis", null);
__decorate([
    Action
], TurmaAlunoAtividadeModule.prototype, "naoIraRepor", null);
__decorate([
    Action
], TurmaAlunoAtividadeModule.prototype, "marcarReposicao", null);
__decorate([
    Action
], TurmaAlunoAtividadeModule.prototype, "turmaAlunoAtividadeUpdate", null);
__decorate([
    Action
], TurmaAlunoAtividadeModule.prototype, "getRelatorioFaltasQuery", null);
__decorate([
    Action
], TurmaAlunoAtividadeModule.prototype, "enviaArquivoAtividade", null);
__decorate([
    Action
], TurmaAlunoAtividadeModule.prototype, "enviaArquivoCorrecao", null);
__decorate([
    Action
], TurmaAlunoAtividadeModule.prototype, "downloadAtividade", null);
__decorate([
    Action
], TurmaAlunoAtividadeModule.prototype, "downloadCorrecao", null);
__decorate([
    Action
], TurmaAlunoAtividadeModule.prototype, "turmaAlunoAtividadeExcluirTarefa", null);
__decorate([
    Action
], TurmaAlunoAtividadeModule.prototype, "turmaAlunoAtividadePresencaAluno", null);
TurmaAlunoAtividadeModule = __decorate([
    Module({ name: 'turmaAlunoAtividade' })
], TurmaAlunoAtividadeModule);
export default TurmaAlunoAtividadeModule;
