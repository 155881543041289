import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
import _ from 'lodash';
let CdaModule = class CdaModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.cupons = [];
        this.cdas = [];
    }
    get cdasAtivos() {
        return _.filter(this.cdas, (i) => i.is_active);
    }
    setCdas(payload) {
        this.cdas = payload;
    }
    setCupons(payload) {
        this.cupons = payload;
    }
    async getCupons() {
        try {
            const response = await api.getCupons(mainStore.token);
            if (response) {
                this.setCupons(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCdas() {
        try {
            const response = await api.getCdas(mainStore.token);
            if (response) {
                this.setCdas(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async distribuirCupons(cupons) {
        const loadingNotification = { content: 'Distribuindo os cupons', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.distribuirCupons(mainStore.token, cupons),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Operação efetuada com sucesso!',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async excluirCuponsLote(cupons) {
        const loadingNotification = { content: 'Excluindo os cupons', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.excluirCuponsLote(mainStore.token, cupons),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Operação efetuada com sucesso!',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async migrarCupons(payload) {
        const loadingNotification = { content: 'Transferindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.migrarCupons(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Transferência efetuada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getCadernoCda(payload) {
        try {
            const response = await api.getCadernoCda(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], CdaModule.prototype, "setCdas", null);
__decorate([
    Mutation
], CdaModule.prototype, "setCupons", null);
__decorate([
    Action
], CdaModule.prototype, "getCupons", null);
__decorate([
    Action
], CdaModule.prototype, "getCdas", null);
__decorate([
    Action
], CdaModule.prototype, "distribuirCupons", null);
__decorate([
    Action
], CdaModule.prototype, "excluirCuponsLote", null);
__decorate([
    Action
], CdaModule.prototype, "migrarCupons", null);
__decorate([
    Action
], CdaModule.prototype, "getCadernoCda", null);
CdaModule = __decorate([
    Module({ name: 'cda' })
], CdaModule);
export default CdaModule;
