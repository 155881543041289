import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let LogModule = class LogModule extends VuexModule {
    async getLog(payload) {
        try {
            let response;
            switch (payload.params.model) {
                case 'UserTool':
                    response = await api.getLogUserTool(mainStore.token, payload.customParams);
                    break;
                case 'UserAction':
                    response = await api.getLogUserAction(mainStore.token, payload.customParams);
                    break;
                default:
                    response = await api.getLog(mainStore.token, payload.params.model, payload.params.modelId);
                    break;
            }
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getLogById(payload) {
        try {
            let response;
            switch (payload.params.model) {
                case 'UserTool':
                    response = await api.getLogByIdUserTool(mainStore.token, payload.params.logId, payload.customParams);
                    break;
                case 'UserAction':
                    response = await api.getLogByIdUserAction(mainStore.token, payload.params.logId, payload.customParams);
                    break;
                default:
                    response = await api.getLogById(mainStore.token, payload.params.logId, payload.params.model, payload.params.modelId);
                    break;
            }
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTurmaAlunoAtividadeLog(payload) {
        try {
            const response = await api.getTurmaAlunoAtividadeLog(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getFullTurmaAlunosAtividadeLog(payload) {
        try {
            const response = await api.getFullTurmaAlunosAtividadeLog(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], LogModule.prototype, "getLog", null);
__decorate([
    Action
], LogModule.prototype, "getLogById", null);
__decorate([
    Action
], LogModule.prototype, "getTurmaAlunoAtividadeLog", null);
__decorate([
    Action
], LogModule.prototype, "getFullTurmaAlunosAtividadeLog", null);
LogModule = __decorate([
    Module({ name: 'log' })
], LogModule);
export default LogModule;
