import { __decorate } from "tslib";
// import { api } from '@/api';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
// import { mainStore } from '@/utils/store-accessor';
let BancoModule = class BancoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.bancos = [];
    }
    setBancos(payload) {
        this.bancos = payload;
    }
    async getBancos() {
        this.setBancos([
            { id: 1, codigo: '246', nome: 'Banco ABC Brasil S.A.' },
            { id: 2, codigo: '748', nome: 'Banco Cooperativo Sicredi S.A.' },
            { id: 3, codigo: '117', nome: 'Advanced Cc Ltda' },
            { id: 4, codigo: '121', nome: 'Banco Agibank S.A.' },
            { id: 5, codigo: '172', nome: 'Albatross Ccv S.A' },
            { id: 6, codigo: '188', nome: 'Ativa Investimentos S.A' },
            { id: 7, codigo: '280', nome: 'Avista S.A. Crédito, Financiamento e Investimento' },
            { id: 8, codigo: '80', nome: 'B&T Cc Ltda' },
            { id: 9, codigo: '654', nome: 'Banco A.J.Renner S.A.' },
            { id: 10, codigo: '246', nome: 'Banco ABC Brasil S.A.' },
            { id: 11, codigo: '75', nome: 'Banco ABN AMRO S.A' },
            { id: 12, codigo: '121', nome: 'Banco Agibank S.A.' },
            { id: 13, codigo: '25', nome: 'Banco Alfa S.A.' },
            { id: 14, codigo: '641', nome: 'Banco Alvorada S.A.' },
            { id: 15, codigo: '65', nome: 'Banco Andbank (Brasil) S.A.' },
            { id: 16, codigo: '213', nome: 'Banco Arbi S.A.' },
            { id: 17, codigo: '96', nome: 'Banco B3 S.A.' },
            { id: 18, codigo: '24', nome: 'Banco BANDEPE S.A.' },
            { id: 19, codigo: '318', nome: 'Banco BMG S.A.' },
            { id: 20, codigo: '752', nome: 'Banco BNP Paribas Brasil S.A.' },
            { id: 21, codigo: '107', nome: 'Banco BOCOM BBM S.A.' },
            { id: 22, codigo: '63', nome: 'Banco Bradescard S.A.' },
            { id: 23, codigo: '36', nome: 'Banco Bradesco BBI S.A.' },
            { id: 24, codigo: '122', nome: 'Banco Bradesco BERJ S.A.' },
            { id: 25, codigo: '204', nome: 'Banco Bradesco Cartões S.A.' },
            { id: 26, codigo: '394', nome: 'Banco Bradesco Financiamentos S.A.' },
            { id: 27, codigo: '237', nome: 'Banco Bradesco S.A.' },
            { id: 28, codigo: '218', nome: 'Banco BS2 S.A.' },
            { id: 29, codigo: '208', nome: 'Banco BTG Pactual S.A.' },
            { id: 30, codigo: '336', nome: 'Banco C6 S.A – C6 Bank' },
            { id: 31, codigo: '473', nome: 'Banco Caixa Geral – Brasil S.A.' },
            { id: 32, codigo: '412', nome: 'Banco Capital S.A.' },
            { id: 33, codigo: '40', nome: 'Banco Cargill S.A.' },
            { id: 34, codigo: '368', nome: 'Banco Carrefour' },
            { id: 35, codigo: '266', nome: 'Banco Cédula S.A.' },
            { id: 36, codigo: '739', nome: 'Banco Cetelem S.A.' },
            { id: 37, codigo: '233', nome: 'Banco Cifra S.A.' },
            { id: 38, codigo: '745', nome: 'Banco Citibank S.A.' },
            { id: 39, codigo: '241', nome: 'Banco Clássico S.A.' },
            { id: 40, codigo: '756', nome: 'Banco Cooperativo do Brasil S.A. – BANCOOB' },
            { id: 41, codigo: '748', nome: 'Banco Cooperativo Sicredi S.A.' },
            { id: 42, codigo: '222', nome: 'Banco Credit Agricole Brasil S.A.' },
            { id: 43, codigo: '505', nome: 'Banco Credit Suisse (Brasil) S.A.' },
            { id: 44, codigo: '69', nome: 'Banco Crefisa S.A.' },
            { id: 45, codigo: '3', nome: 'Banco da Amazônia S.A.' },
            { id: 46, codigo: '83', nome: 'Banco da China Brasil S.A.' },
            { id: 47, codigo: '707', nome: 'Banco Daycoval S.A.' },
            { id: 48, codigo: '51', nome: 'Banco de Desenvolvimento do Espírito Santo S.A.' },
            { id: 49, codigo: '300', nome: 'Banco de La Nacion Argentina' },
            { id: 50, codigo: '495', nome: 'Banco de La Provincia de Buenos Aires' },
            { id: 51, codigo: '494', nome: 'Banco de La Republica Oriental del Uruguay' },
            { id: 52, codigo: '335', nome: 'Banco Digio S.A' },
            { id: 53, codigo: '1', nome: 'Banco do Brasil S.A.' },
            { id: 54, codigo: '47', nome: 'Banco do Estado de Sergipe S.A.' },
            { id: 55, codigo: '37', nome: 'Banco do Estado do Pará S.A.' },
            { id: 56, codigo: '41', nome: 'Banco do Estado do Rio Grande do Sul S.A.' },
            { id: 57, codigo: '4', nome: 'Banco do Nordeste do Brasil S.A.' },
            { id: 58, codigo: '196', nome: 'Banco Fair Corretora de Câmbio S.A' },
            { id: 59, codigo: '265', nome: 'Banco Fator S.A.' },
            { id: 60, codigo: '224', nome: 'Banco Fibra S.A.' },
            { id: 61, codigo: '626', nome: 'Banco Ficsa S.A.' },
            { id: 62, codigo: '94', nome: 'Banco Finaxis S.A.' },
            { id: 63, codigo: '612', nome: 'Banco Guanabara S.A.' },
            { id: 64, codigo: '12', nome: 'Banco Inbursa S.A.' },
            { id: 65, codigo: '604', nome: 'Banco Industrial do Brasil S.A.' },
            { id: 66, codigo: '653', nome: 'Banco Indusval S.A.' },
            { id: 67, codigo: '77', nome: 'Banco Inter S.A.' },
            { id: 68, codigo: '249', nome: 'Banco Investcred Unibanco S.A.' },
            { id: 69, codigo: '184', nome: 'Banco Itaú BBA S.A.' },
            { id: 70, codigo: '29', nome: 'Banco Itaú Consignado S.A.' },
            { id: 71, codigo: '479', nome: 'Banco ItauBank S.A' },
            { id: 72, codigo: '376', nome: 'Banco J. P. Morgan S.A.' },
            { id: 73, codigo: '74', nome: 'Banco J. Safra S.A.' },
            { id: 74, codigo: '217', nome: 'Banco John Deere S.A.' },
            { id: 75, codigo: '76', nome: 'Banco KDB S.A.' },
            { id: 76, codigo: '757', nome: 'Banco KEB HANA do Brasil S.A.' },
            { id: 77, codigo: '600', nome: 'Banco Luso Brasileiro S.A.' },
            { id: 78, codigo: '243', nome: 'Banco Máxima S.A.' },
            { id: 79, codigo: '720', nome: 'Banco Maxinvest S.A.' },
            { id: 80, codigo: '389', nome: 'Banco Mercantil de Investimentos S.A.' },
            { id: 81, codigo: '389', nome: 'Banco Mercantil do Brasil S.A.' },
            { id: 82, codigo: '370', nome: 'Banco Mizuho do Brasil S.A.' },
            { id: 83, codigo: '746', nome: 'Banco Modal S.A.' },
            { id: 84, codigo: '66', nome: 'Banco Morgan Stanley S.A.' },
            { id: 85, codigo: '456', nome: 'Banco MUFG Brasil S.A.' },
            { id: 86, codigo: '7', nome: 'Banco Nacional de Desenvolvimento Econômico e Social – BNDES' },
            { id: 87, codigo: '169', nome: 'Banco Olé Bonsucesso Consignado S.A.' },
            { id: 88, codigo: '111', nome: 'Banco Oliveira Trust Dtvm S.A' },
            { id: 89, codigo: '79', nome: 'Banco Original do Agronegócio S.A.' },
            { id: 90, codigo: '212', nome: 'Banco Original S.A.' },
            { id: 91, codigo: '712', nome: 'Banco Ourinvest S.A.' },
            { id: 92, codigo: '623', nome: 'Banco PAN S.A.' },
            { id: 93, codigo: '611', nome: 'Banco Paulista S.A.' },
            { id: 94, codigo: '643', nome: 'Banco Pine S.A.' },
            { id: 95, codigo: '658', nome: 'Banco Porto Real de Investimentos S.A.' },
            { id: 96, codigo: '747', nome: 'Banco Rabobank International Brasil S.A.' },
            { id: 97, codigo: '633', nome: 'Banco Rendimento S.A.' },
            { id: 98, codigo: '741', nome: 'Banco Ribeirão Preto S.A.' },
            { id: 99, codigo: '120', nome: 'Banco Rodobens S.A.' },
            { id: 100, codigo: '422', nome: 'Banco Safra S.A.' },
            { id: 101, codigo: '33', nome: 'Banco Santander (Brasil) S.A.' },
            { id: 102, codigo: '743', nome: 'Banco Semear S.A.' },
            { id: 103, codigo: '754', nome: 'Banco Sistema S.A.' },
            { id: 104, codigo: '630', nome: 'Banco Smartbank S.A.' },
            { id: 105, codigo: '366', nome: 'Banco Société Générale Brasil S.A.' },
            { id: 106, codigo: '637', nome: 'Banco Sofisa S.A.' },
            { id: 107, codigo: '464', nome: 'Banco Sumitomo Mitsui Brasileiro S.A.' },
            { id: 108, codigo: '82', nome: 'Banco Topázio S.A.' },
            { id: 109, codigo: '634', nome: 'Banco Triângulo S.A.' },
            { id: 110, codigo: '18', nome: 'Banco Tricury S.A.' },
            { id: 111, codigo: '655', nome: 'Banco Votorantim S.A.' },
            { id: 112, codigo: '610', nome: 'Banco VR S.A.' },
            { id: 113, codigo: '119', nome: 'Banco Western Union do Brasil S.A.' },
            { id: 114, codigo: '124', nome: 'Banco Woori Bank do Brasil S.A.' },
            { id: 115, codigo: '348', nome: 'Banco Xp S/A' },
            { id: 116, codigo: '81', nome: 'BancoSeguro S.A.' },
            { id: 117, codigo: '21', nome: 'BANESTES S.A. Banco do Estado do Espírito Santo' },
            { id: 118, codigo: '755', nome: 'Bank of America Merrill Lynch Banco Múltiplo S.A.' },
            { id: 119, codigo: '268', nome: 'Barigui Companhia Hipotecária' },
            { id: 120, codigo: '250', nome: 'BCV – Banco de Crédito e Varejo S.A.' },
            { id: 121, codigo: '144', nome: 'BEXS Banco de Câmbio S.A.' },
            { id: 122, codigo: '253', nome: 'Bexs Corretora de Câmbio S/A' },
            { id: 123, codigo: '134', nome: 'Bgc Liquidez Dtvm Ltda' },
            { id: 124, codigo: '17', nome: 'BNY Mellon Banco S.A.' },
            { id: 125, codigo: '301', nome: 'Bpp Instituição De Pagamentos S.A' },
            { id: 126, codigo: '126', nome: 'BR Partners Banco de Investimento S.A.' },
            { id: 127, codigo: '70', nome: 'BRB – Banco de Brasília S.A.' },
            { id: 128, codigo: '92', nome: 'Brickell S.A. Crédito, Financiamento e Investimento' },
            { id: 129, codigo: '173', nome: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.' },
            { id: 130, codigo: '142', nome: 'Broker Brasil Cc Ltda' },
            { id: 131, codigo: '292', nome: 'BS2 Distribuidora de Títulos e Valores Mobiliários S.A.' },
            { id: 132, codigo: '11', nome: 'C.Suisse Hedging-Griffo Cv S.A (Credit Suisse)' },
            { id: 133, codigo: '104', nome: 'Caixa Econômica Federal' },
            { id: 134, codigo: '288', nome: 'Carol Distribuidora de Títulos e Valor Mobiliários Ltda' },
            { id: 135, codigo: '130', nome: 'Caruana Scfi' },
            { id: 136, codigo: '159', nome: 'Casa Credito S.A' },
            { id: 137, codigo: '16', nome: 'Ccm Desp Trâns Sc E Rs' },
            { id: 138, codigo: '89', nome: 'Ccr Reg Mogiana' },
            { id: 139, codigo: '114', nome: 'Central Cooperativa De Crédito No Estado Do Espírito Santo' },
            { id: 140, codigo: '1147', nome: 'Central das Cooperativas de Economia e Crédito Mútuo doEstado do Espírito Santo Ltda.' },
            { id: 141, codigo: '320', nome: 'China Construction Bank (Brasil) Banco Múltiplo S.A.' },
            { id: 142, codigo: '477', nome: 'Citibank N.A.' },
            { id: 143, codigo: '180', nome: 'Cm Capital Markets Cctvm Ltda' },
            { id: 144, codigo: '127', nome: 'Codepe Cvc S.A' },
            { id: 145, codigo: '163', nome: 'Commerzbank Brasil S.A. – Banco Múltiplo' },
            { id: 146, codigo: '60', nome: 'Confidence Cc S.A' },
            { id: 147, codigo: '85', nome: 'Coop Central Ailos' },
            { id: 148, codigo: '97', nome: 'Cooperativa Central de Crédito Noroeste Brasileiro Ltda.' },
            { id: 149, codigo: '085x', nome: 'Cooperativa Central de Crédito Urbano-CECRED' },
            { id: 150, codigo: '0902', nome: 'Cooperativa Central de Economia e Crédito Mutuo – SICOOB UNIMAIS' },
            { id: 151, codigo: '0876', nome: 'Cooperativa Central de Economia e Crédito Mútuo das Unicredsde Santa Catarina e Paraná' },
            { id: 152, codigo: '0892', nome: 'Cooperativa de Crédito Rural da Região da Mogiana' },
            { id: 153, codigo: '286', nome: 'Cooperativa de Crédito Rural De Ouro' },
            { id: 154, codigo: '279', nome: 'Cooperativa de Crédito Rural de Primavera Do Leste' },
            { id: 155, codigo: '273', nome: 'Cooperativa de Crédito Rural de São Miguel do Oeste – Sulcredi/São Miguel' },
            { id: 156, codigo: '98', nome: 'Credialiança Ccr' },
            { id: 157, codigo: '0981', nome: 'CREDIALIANÇA COOPERATIVA DE CRÉDITO RURAL' },
            { id: 158, codigo: '10', nome: 'Credicoamo' },
            { id: 159, codigo: '133', nome: 'Cresol Confederação' },
            { id: 160, codigo: '182', nome: 'Dacasa Financeira S/A' },
            { id: 161, codigo: '707', nome: 'Banco Daycoval S.A.' },
            { id: 162, codigo: '487', nome: 'Deutsche Bank S.A. – Banco Alemão' },
            { id: 163, codigo: '140', nome: 'Easynvest – Título Cv S.A' },
            { id: 164, codigo: '149', nome: 'Facta S.A. Cfi' },
            { id: 165, codigo: '285', nome: 'Frente Corretora de Câmbio Ltda.' },
            { id: 166, codigo: '278', nome: 'Genial Investimentos Corretora de Valores Mobiliários S.A.' },
            { id: 167, codigo: '138', nome: 'Get Money Cc Ltda' },
            { id: 168, codigo: '64', nome: 'Goldman Sachs do Brasil Banco Múltiplo S.A.' },
            { id: 169, codigo: '177', nome: 'Guide Investimentos S.A. Corretora de Valores' },
            { id: 170, codigo: '146', nome: 'Guitta Corretora de Câmbio Ltda' },
            { id: 171, codigo: '78', nome: 'Haitong Banco de Investimento do Brasil S.A.' },
            { id: 172, codigo: '62', nome: 'Hipercard Banco Múltiplo S.A.' },
            { id: 173, codigo: '189', nome: 'HS Financeira S/A Crédito, Financiamento e Investimentos' },
            { id: 174, codigo: '269', nome: 'HSBC Brasil S.A. – Banco de Investimento' },
            { id: 175, codigo: '271', nome: 'IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.' },
            { id: 176, codigo: '157', nome: 'Icap Do Brasil Ctvm Ltda' },
            { id: 177, codigo: '132', nome: 'ICBC do Brasil Banco Múltiplo S.A.' },
            { id: 178, codigo: '492', nome: 'ING Bank N.V.' },
            { id: 179, codigo: '139', nome: 'Intesa Sanpaolo Brasil S.A. – Banco Múltiplo' },
            { id: 180, codigo: '652', nome: 'Itaú Unibanco Holding S.A.' },
            { id: 181, codigo: '341', nome: 'Itaú Unibanco S.A.' },
            { id: 182, codigo: '488', nome: 'JPMorgan Chase Bank, National Association' },
            { id: 183, codigo: '399', nome: 'Kirton Bank S.A. – Banco Múltiplo' },
            { id: 184, codigo: '293', nome: 'Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.' },
            { id: 185, codigo: '105', nome: 'Lecca Crédito, Financiamento e Investimento S/A' },
            { id: 186, codigo: '145', nome: 'Levycam Ccv Ltda' },
            { id: 187, codigo: '113', nome: 'Magliano S.A' },
            { id: 188, codigo: '323', nome: 'Mercado Pago – Conta Do Mercado Livre' },
            { id: 189, codigo: '128', nome: 'MS Bank S.A. Banco de Câmbio' },
            { id: 190, codigo: '137', nome: 'Multimoney Cc Ltda' },
            { id: 191, codigo: '14', nome: 'Natixis Brasil S.A. Banco Múltiplo' },
            { id: 192, codigo: '191', nome: 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.' },
            { id: 193, codigo: '753', nome: 'Novo Banco Continental S.A. – Banco Múltiplo' },
            { id: 194, codigo: '260', nome: 'Nu Pagamentos S.A (Nubank)' },
            { id: 195, codigo: '613', nome: 'Omni Banco S.A.' },
            { id: 196, codigo: '613', nome: 'Omni Banco S.A.' },
            { id: 197, codigo: '290', nome: 'Pagseguro Internet S.A' },
            { id: 198, codigo: '254', nome: 'Paraná Banco S.A.' },
            { id: 199, codigo: '326', nome: 'Parati – Crédito Financiamento e Investimento S.A.' },
            { id: 200, codigo: '194', nome: 'Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda' },
            { id: 201, codigo: '174', nome: 'Pernambucanas Financ S.A' },
            { id: 202, codigo: '100', nome: 'Planner Corretora De Valores S.A' },
            { id: 203, codigo: '125', nome: 'Plural S.A. – Banco Múltiplo' },
            { id: 204, codigo: '93', nome: 'Pólocred Scmepp Ltda' },
            { id: 205, codigo: '108', nome: 'Portocred S.A' },
            { id: 206, codigo: '283', nome: 'Rb Capital Investimentos Dtvm Ltda' },
            { id: 207, codigo: '101', nome: 'Renascenca Dtvm Ltda' },
            { id: 208, codigo: '270', nome: 'Sagitur Corretora de Câmbio Ltda.' },
            { id: 209, codigo: '751', nome: 'Scotiabank Brasil S.A. Banco Múltiplo' },
            { id: 210, codigo: '276', nome: 'Senff S.A. – Crédito, Financiamento e Investimento' },
            { id: 211, codigo: '545', nome: 'Senso Ccvm S.A' },
            { id: 212, codigo: '190', nome: 'Servicoop' },
            { id: 213, codigo: '183', nome: 'Socred S.A' },
            { id: 214, codigo: '299', nome: 'Sorocred Crédito, Financiamento e Investimento S.A.' },
            { id: 215, codigo: '118', nome: 'Standard Chartered Bank (Brasil) S/A–Bco Invest.' },
            { id: 216, codigo: '197', nome: 'Stone Pagamentos S.A' },
            { id: 217, codigo: '340', nome: 'Super Pagamentos e Administração de Meios Eletrônicos S.A.' },
            { id: 218, codigo: '95', nome: 'Travelex Banco de Câmbio S.A.' },
            { id: 219, codigo: '143', nome: 'Treviso Corretora de Câmbio S.A.' },
            { id: 220, codigo: '131', nome: 'Tullett Prebon Brasil Cvc Ltda' },
            { id: 221, codigo: '129', nome: 'UBS Brasil Banco de Investimento S.A.' },
            { id: 222, codigo: '0914', nome: 'Unicred Central do Rio Grande do Sul' },
            { id: 223, codigo: '91', nome: 'Unicred Central Rs' },
            { id: 224, codigo: '136', nome: 'Unicred Cooperativa' },
            { id: 225, codigo: '99', nome: 'UNIPRIME Central – Central Interestadual de Cooperativas de Crédito Ltda.' },
            { id: 226, codigo: '84', nome: 'Uniprime Norte do Paraná – Coop de Economia eCrédito Mútuo dos Médicos, Profissionais das Ciências' },
            { id: 227, codigo: '298', nome: 'Vips Cc Ltda' },
            { id: 228, codigo: '310', nome: 'Vortx Distribuidora de Títulos e Valores Mobiliários Ltda' },
            { id: 229, codigo: '102', nome: 'Xp Investimentos S.A' },
            { id: 230, codigo: '403', nome: 'Cora SCD S.A.' },
            { id: 231, codigo: '461', nome: 'ASAAS IP S.A.' },
            { id: 232, codigo: '364', nome: 'Efí By Gerencianet' },
        ]);
        // nao bater na api
        // try {
        //   const response = await api.getBancos(mainStore.token);
        //   if (response) {
        //     this.setBancos(response.data);
        //   }
        // } catch (error) {
        //   await mainStore.checkApiError(error);
        // }
    }
};
__decorate([
    Mutation
], BancoModule.prototype, "setBancos", null);
__decorate([
    Action
], BancoModule.prototype, "getBancos", null);
BancoModule = __decorate([
    Module({ name: 'banco' })
], BancoModule);
export default BancoModule;
