import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let ParceriaModule = class ParceriaModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.parcerias = [];
    }
    setParcerias(payload) {
        this.parcerias = payload;
    }
    setParceria(payload) {
        const parcerias = this.parcerias.filter((company) => company.id !== payload.id);
        parcerias.push(payload);
        this.parcerias = parcerias;
    }
    async getParceriaRestaurarPadrao(id) {
        const loadingNotification = { content: 'Restaurando o padrão', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.getParceriaRestaurarPadrao(mainStore.token, id),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Padrão restaurado com sucesso!',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getParceriaFlyer(id) {
        const loadingNotification = { content: 'Gerando o flyer', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.getParceriaFlyer(mainStore.token, id),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Flyer gerado com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0];
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getParceriaCartaz(id) {
        const loadingNotification = { content: 'Gerando o cartaz', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.getParceriaCartaz(mainStore.token, id),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Cartaz gerado com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0];
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async createParceria(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createParceria(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Parceria salva com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateParceria(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateParceria(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Parceria atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getParcerias() {
        try {
            const response = await api.getParcerias(mainStore.token);
            if (response) {
                this.setParcerias(response.data);
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getParceriasCampanha() {
        try {
            const response = await api.getParceriasCampanha(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getParceria(id) {
        try {
            const response = await api.getParceria(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getParceriaDivulgacao(id) {
        try {
            const response = await api.getParceriaDivulgacao(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteParceria(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteParceria(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Parceria excluida com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], ParceriaModule.prototype, "setParcerias", null);
__decorate([
    Mutation
], ParceriaModule.prototype, "setParceria", null);
__decorate([
    Action
], ParceriaModule.prototype, "getParceriaRestaurarPadrao", null);
__decorate([
    Action
], ParceriaModule.prototype, "getParceriaFlyer", null);
__decorate([
    Action
], ParceriaModule.prototype, "getParceriaCartaz", null);
__decorate([
    Action
], ParceriaModule.prototype, "createParceria", null);
__decorate([
    Action
], ParceriaModule.prototype, "updateParceria", null);
__decorate([
    Action
], ParceriaModule.prototype, "getParcerias", null);
__decorate([
    Action
], ParceriaModule.prototype, "getParceriasCampanha", null);
__decorate([
    Action
], ParceriaModule.prototype, "getParceria", null);
__decorate([
    Action
], ParceriaModule.prototype, "getParceriaDivulgacao", null);
__decorate([
    Action
], ParceriaModule.prototype, "deleteParceria", null);
ParceriaModule = __decorate([
    Module({ name: 'parceria' })
], ParceriaModule);
export default ParceriaModule;
