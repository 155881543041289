import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let TurmaAlunoModule = class TurmaAlunoModule extends VuexModule {
    async getDetalhamentoTurmaAluno(contratoId) {
        try {
            const response = await api.getDetalhamentoTurmaAluno(mainStore.token, contratoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateTurmaAluno(payload) {
        const loadingNotification = { content: 'Atualizando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateTurmaAluno(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Atualização efetuada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async evadir(turmaAlunoId) {
        const loadingNotification = { content: 'Evadindo o aluno', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.turmaAlunoEvadir(mainStore.token, turmaAlunoId),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Operação efetuada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getTurmaAlunoBoletins(queryIn) {
        try {
            const response = await api.getTurmaAlunoBoletins(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCadernoEvadidosQuery(queryIn) {
        try {
            const response = await api.getCadernoEvadidosQuery(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], TurmaAlunoModule.prototype, "getDetalhamentoTurmaAluno", null);
__decorate([
    Action
], TurmaAlunoModule.prototype, "updateTurmaAluno", null);
__decorate([
    Action
], TurmaAlunoModule.prototype, "evadir", null);
__decorate([
    Action
], TurmaAlunoModule.prototype, "getTurmaAlunoBoletins", null);
__decorate([
    Action
], TurmaAlunoModule.prototype, "getCadernoEvadidosQuery", null);
TurmaAlunoModule = __decorate([
    Module({ name: 'turmaAluno' })
], TurmaAlunoModule);
export default TurmaAlunoModule;
