import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let PlugnotasNfe = class PlugnotasNfe extends VuexModule {
    constructor() {
        super(...arguments);
        this.notaFiscal = [];
        this.notaFiscaisNfes = [];
    }
    setNotaFiscal(payload) {
        this.notaFiscal = payload;
    }
    setNotaFiscaisNfes(payload) {
        this.notaFiscaisNfes = payload;
    }
    async getNotasFicais() {
        try {
            const response = await api.getNotasFicais(mainStore.token);
            this.setNotaFiscal(response.data);
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfeEmitirTroca(payload) {
        try {
            const response = await api.plugnotasNfeEmitirTroca(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfeConsultarTrocaDevolucao(nfeId) {
        const loadingNotification = { content: 'Consultando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.plugnotasNfeConsultarTrocaDevolucao(mainStore.token, nfeId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Consultando NFe',
                color: 'success',
            });
            if (result) {
                this.atualizaStatusPlugnotasNfe(result.data.nfe);
                return result.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getNfeTrocaDevolucao(payload) {
        try {
            const response = await api.getNfeTrocaDevolucao(mainStore.token, payload.dataInicial, payload.dataFinal);
            if (response) {
                this.setNotaFiscaisNfes(response.data);
            }
            return [];
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async atualizaStatusPlugnotasNfe(item) {
        const items = this.notaFiscaisNfes;
        const index = items.findIndex((nota) => nota.id === item.id);
        items[index].plugnotas_status = item.plugnotas_status;
        this.setNotaFiscaisNfes(items);
    }
};
__decorate([
    Mutation
], PlugnotasNfe.prototype, "setNotaFiscal", null);
__decorate([
    Mutation
], PlugnotasNfe.prototype, "setNotaFiscaisNfes", null);
__decorate([
    Action
], PlugnotasNfe.prototype, "getNotasFicais", null);
__decorate([
    Action
], PlugnotasNfe.prototype, "plugnotasNfeEmitirTroca", null);
__decorate([
    Action
], PlugnotasNfe.prototype, "plugnotasNfeConsultarTrocaDevolucao", null);
__decorate([
    Action
], PlugnotasNfe.prototype, "getNfeTrocaDevolucao", null);
__decorate([
    Action
], PlugnotasNfe.prototype, "atualizaStatusPlugnotasNfe", null);
PlugnotasNfe = __decorate([
    Module({ name: 'plugnotasNfe' })
], PlugnotasNfe);
export default PlugnotasNfe;
