import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let FornecedorModule = class FornecedorModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.fornecedores = [];
    }
    setFornecedores(payload) {
        this.fornecedores = payload;
    }
    setFornecedor(payload) {
        const fornecedores = this.fornecedores.filter((company) => company.id !== payload.id);
        fornecedores.push(payload);
        this.fornecedores = fornecedores;
    }
    async createFornecedor(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createFornecedor(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Fornecedor criado com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateFornecedor(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateFornecedor(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Fornecedor atualizado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getFornecedores() {
        try {
            const response = await api.getFornecedores(mainStore.token);
            if (response) {
                this.setFornecedores(response.data);
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getFornecedor(id) {
        try {
            const response = await api.getFornecedor(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getFornecedorByCnpj(cnpj) {
        try {
            const response = await api.getFornecedorByCnpj(mainStore.token, cnpj);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteFornecedor(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteFornecedor(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Fornecedor excluído com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], FornecedorModule.prototype, "setFornecedores", null);
__decorate([
    Mutation
], FornecedorModule.prototype, "setFornecedor", null);
__decorate([
    Action
], FornecedorModule.prototype, "createFornecedor", null);
__decorate([
    Action
], FornecedorModule.prototype, "updateFornecedor", null);
__decorate([
    Action
], FornecedorModule.prototype, "getFornecedores", null);
__decorate([
    Action
], FornecedorModule.prototype, "getFornecedor", null);
__decorate([
    Action
], FornecedorModule.prototype, "getFornecedorByCnpj", null);
__decorate([
    Action
], FornecedorModule.prototype, "deleteFornecedor", null);
FornecedorModule = __decorate([
    Module({ name: 'fornecedor' })
], FornecedorModule);
export default FornecedorModule;
