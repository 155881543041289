import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let RotaPontoParadaModule = class RotaPontoParadaModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.rota_ponto_paradas = [];
    }
    setRotaPontosParada(payload) {
        this.rota_ponto_paradas = payload;
    }
    setRotaPontoParada(payload) {
        const rota_ponto_paradas = this.rota_ponto_paradas.filter((company) => company.id !== payload.id);
        rota_ponto_paradas.push(payload);
        this.rota_ponto_paradas = rota_ponto_paradas;
    }
    async createRotaPontoParada(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createRotaPontoParada(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'RotaPontoParada criado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateRotaPontoParada(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateRotaPontoParada(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Ponto de parada atualizado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getRotaPontosParada(rota_id) {
        try {
            const response = await api.getRotaPontosParada(mainStore.token, rota_id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async deleteRotaPontoParada(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteRotaPontoParada(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Ponto de parada excluído com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], RotaPontoParadaModule.prototype, "setRotaPontosParada", null);
__decorate([
    Mutation
], RotaPontoParadaModule.prototype, "setRotaPontoParada", null);
__decorate([
    Action
], RotaPontoParadaModule.prototype, "createRotaPontoParada", null);
__decorate([
    Action
], RotaPontoParadaModule.prototype, "updateRotaPontoParada", null);
__decorate([
    Action
], RotaPontoParadaModule.prototype, "getRotaPontosParada", null);
__decorate([
    Action
], RotaPontoParadaModule.prototype, "deleteRotaPontoParada", null);
RotaPontoParadaModule = __decorate([
    Module({ name: 'rotaPontoParada' })
], RotaPontoParadaModule);
export default RotaPontoParadaModule;
