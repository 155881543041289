import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let ContaBancariaFormaPagamentoModule = class ContaBancariaFormaPagamentoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.contasBancariasFormasPagamentos = [];
    }
    setContasBancariasFormasPagamentos(payload) {
        this.contasBancariasFormasPagamentos = payload;
    }
    setContaBancariaFormaPagamento(payload) {
        const contasBancariasFormasPagamentos = this.contasBancariasFormasPagamentos.filter((conta) => conta.id !== payload.id);
        contasBancariasFormasPagamentos.push(payload);
        this.contasBancariasFormasPagamentos = contasBancariasFormasPagamentos;
    }
    async createContaBancariaFormaPagamento(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createContaBancariaFormaPagamento(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            const successNotification = {
                content: 'Conta Bancária Forma de Pagamento criada com sucesso',
                color: 'success',
            };
            mainStore.addNotification(successNotification);
            return result[0].data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateContaBancariaFormaPagamento(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateContaBancariaFormaPagamento(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Conta Bancária Forma de Pagamento atualizada com sucesso',
                color: 'success',
            });
            return result[0].data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getContasBancariasFormasPagamentos() {
        try {
            const response = await api.getContasBancariasFormasPagamentos(mainStore.token);
            if (response) {
                this.setContasBancariasFormasPagamentos(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getFormasPagamentosDisponiveis() {
        try {
            const response = await api.getFormasPagamentosDisponiveis(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getContaBancariaFormaPagamento(contaBancariaFormaPagamentoId) {
        try {
            const response = await api.getContaBancariaFormaPagamento(mainStore.token, contaBancariaFormaPagamentoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], ContaBancariaFormaPagamentoModule.prototype, "setContasBancariasFormasPagamentos", null);
__decorate([
    Mutation
], ContaBancariaFormaPagamentoModule.prototype, "setContaBancariaFormaPagamento", null);
__decorate([
    Action
], ContaBancariaFormaPagamentoModule.prototype, "createContaBancariaFormaPagamento", null);
__decorate([
    Action
], ContaBancariaFormaPagamentoModule.prototype, "updateContaBancariaFormaPagamento", null);
__decorate([
    Action
], ContaBancariaFormaPagamentoModule.prototype, "getContasBancariasFormasPagamentos", null);
__decorate([
    Action
], ContaBancariaFormaPagamentoModule.prototype, "getFormasPagamentosDisponiveis", null);
__decorate([
    Action
], ContaBancariaFormaPagamentoModule.prototype, "getContaBancariaFormaPagamento", null);
ContaBancariaFormaPagamentoModule = __decorate([
    Module({ name: 'contaBancariaFormaPagamento' })
], ContaBancariaFormaPagamentoModule);
export default ContaBancariaFormaPagamentoModule;
