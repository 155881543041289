import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let AdminModule = class AdminModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.users = [];
        this.user = {};
    }
    get adminOneUser() {
        return (userId) => {
            const filteredUsers = this.users.filter((user) => user.id === userId);
            if (filteredUsers) {
                if (filteredUsers.length > 0) {
                    return { ...filteredUsers[0] };
                }
            }
        };
    }
    setUsers(payload) {
        this.users = payload;
    }
    setUser(payload) {
        this.user = payload;
    }
    async getUsers(somenteAtivo) {
        try {
            const response = await api.getUsers(mainStore.token, somenteAtivo);
            if (response) {
                // @ts-ignore
                this.setUsers(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getUser(id) {
        try {
            const response = await api.getUser(mainStore.token, id);
            if (response) {
                // @ts-ignore
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async userVerificaDisponibilidade(payload) {
        try {
            const response = await api.userVerificaDisponibilidade(mainStore.token, payload);
            if (response) {
                // @ts-ignore
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getProfessores() {
        try {
            const response = await api.getProfessores(mainStore.token);
            if (response) {
                // @ts-ignore
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getProfessoresByIdioma(id) {
        try {
            const response = await api.getProfessoresByIdioma(mainStore.token, id);
            if (response) {
                // @ts-ignore
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getProfessoresByIdiomaAndDisponibilidade(payload) {
        try {
            const response = await api.getProfessoresByIdiomaAndDisponibilidade(mainStore.token, payload.idioma, payload.dias);
            if (response) {
                // @ts-ignore
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCompanyProfessoresByIdioma(idioma) {
        try {
            const response = await api.getCompanyProfessoresByIdioma(mainStore.token, idioma);
            if (response) {
                // @ts-ignore
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateUserLoginOtp() {
        try {
            const response = await api.updateUserLoginOtp(mainStore.token);
            if (response) {
                // @ts-ignore
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateUser(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateUser(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Colaborador atualizado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateUserColaborador(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            await Promise.all([
                api.updateUser(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Colaborador atualizado com sucesso',
                color: 'success',
            });
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updatePassword(payload) {
        const loadingNotification = { content: 'Atualizando senha', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            await api.updatePassword(mainStore.token, payload);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Senha alterada com sucesso',
                color: 'success',
            });
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateCurrentUserPassword(payload) {
        const loadingNotification = { content: 'Atualizando senha', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            await api.updateCurrentUserPassword(mainStore.token, payload);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Senha alterada com sucesso',
                color: 'success',
            });
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteUser(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            await Promise.all([
                api.deleteUser(mainStore.token, id),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Colaborador removido com sucesso',
                color: 'success',
            });
        }
        catch (error) {
            await mainStore.checkApiError(error);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async createUser(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                await api.createUser(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Colaborador criado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getUserByCompanyId(payload) {
        try {
            const response = await api.getUserByCompanyId(mainStore.token, payload);
            if (response) {
                // @ts-ignore
                this.setUsers(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getUserByAlunoId(alunoId) {
        try {
            const response = await api.getUserByAlunoId(mainStore.token, alunoId);
            if (response) {
                // @ts-ignore
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createAlunoAcessoPortal(alunoId) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                await api.createAlunoAcessoPortal(mainStore.token, alunoId),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Acesso ao portal do aluno criado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async desativarAlunoAcessoPortal(alunoId) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                await api.desativarAlunoAcessoPortal(mainStore.token, alunoId),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Acesso ao portal do aluno desativado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async ativarAlunoAcessoPortal(alunoId) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                await api.ativarAlunoAcessoPortal(mainStore.token, alunoId),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Acesso ao portal do aluno ativado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async generateNewTOTP() {
        try {
            const response = await api.generateNewTOTP(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async verifyTOTPCode(payload) {
        try {
            const response = await api.verifyTOTPCode(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getLogOtp(userId) {
        try {
            const response = await api.getLogOtp(mainStore.token, userId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getUserNames() {
        try {
            const response = await api.getUserNames(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], AdminModule.prototype, "setUsers", null);
__decorate([
    Mutation
], AdminModule.prototype, "setUser", null);
__decorate([
    Action
], AdminModule.prototype, "getUsers", null);
__decorate([
    Action
], AdminModule.prototype, "getUser", null);
__decorate([
    Action
], AdminModule.prototype, "userVerificaDisponibilidade", null);
__decorate([
    Action
], AdminModule.prototype, "getProfessores", null);
__decorate([
    Action
], AdminModule.prototype, "getProfessoresByIdioma", null);
__decorate([
    Action
], AdminModule.prototype, "getProfessoresByIdiomaAndDisponibilidade", null);
__decorate([
    Action
], AdminModule.prototype, "getCompanyProfessoresByIdioma", null);
__decorate([
    Action
], AdminModule.prototype, "updateUserLoginOtp", null);
__decorate([
    Action
], AdminModule.prototype, "updateUser", null);
__decorate([
    Action
], AdminModule.prototype, "updateUserColaborador", null);
__decorate([
    Action
], AdminModule.prototype, "updatePassword", null);
__decorate([
    Action
], AdminModule.prototype, "updateCurrentUserPassword", null);
__decorate([
    Action
], AdminModule.prototype, "deleteUser", null);
__decorate([
    Action
], AdminModule.prototype, "createUser", null);
__decorate([
    Action
], AdminModule.prototype, "getUserByCompanyId", null);
__decorate([
    Action
], AdminModule.prototype, "getUserByAlunoId", null);
__decorate([
    Action
], AdminModule.prototype, "createAlunoAcessoPortal", null);
__decorate([
    Action
], AdminModule.prototype, "desativarAlunoAcessoPortal", null);
__decorate([
    Action
], AdminModule.prototype, "ativarAlunoAcessoPortal", null);
__decorate([
    Action
], AdminModule.prototype, "generateNewTOTP", null);
__decorate([
    Action
], AdminModule.prototype, "verifyTOTPCode", null);
__decorate([
    Action
], AdminModule.prototype, "getLogOtp", null);
__decorate([
    Action
], AdminModule.prototype, "getUserNames", null);
AdminModule = __decorate([
    Module({ name: 'user' })
], AdminModule);
export default AdminModule;
