import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let ToolModule = class ToolModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.tools = [];
    }
    setTools(payload) {
        this.tools = payload;
    }
    async getTools() {
        try {
            const response = await api.getTools(mainStore.token);
            if (response) {
                this.setTools(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getToolsDefault(payload) {
        try {
            const response = await api.getToolsDefault(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateToolsDefault(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateToolsDefault(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Tool atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], ToolModule.prototype, "setTools", null);
__decorate([
    Action
], ToolModule.prototype, "getTools", null);
__decorate([
    Action
], ToolModule.prototype, "getToolsDefault", null);
__decorate([
    Action
], ToolModule.prototype, "updateToolsDefault", null);
ToolModule = __decorate([
    Module({ name: 'tool' })
], ToolModule);
export default ToolModule;
