import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let RelatorioModule = class RelatorioModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.relatorios = [];
        this.relatorioResult = {};
    }
    setRelatorios(payload) {
        this.relatorios = payload;
    }
    setRelatorioResult(payload) {
        this.relatorioResult = payload;
    }
    async getRelatorios() {
        try {
            const response = await api.getRelatorios(mainStore.token);
            if (response) {
                this.setRelatorios(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async executeRelatorio(payload) {
        try {
            const response = await api.executeRelatorio(mainStore.token, payload.id, payload.data);
            if (response) {
                this.setRelatorioResult(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], RelatorioModule.prototype, "setRelatorios", null);
__decorate([
    Mutation
], RelatorioModule.prototype, "setRelatorioResult", null);
__decorate([
    Action
], RelatorioModule.prototype, "getRelatorios", null);
__decorate([
    Action
], RelatorioModule.prototype, "executeRelatorio", null);
RelatorioModule = __decorate([
    Module({ name: 'relatorio' })
], RelatorioModule);
export default RelatorioModule;
