import Vue from 'vue';
import Vuetify from 'vuetify';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/dist/vuetify.min.css';
import pt from 'vuetify/src/locale/pt';
Vue.use(Vuetify, {
    iconfont: 'md',
});
const opts = {
    lang: {
        locales: { pt },
        current: 'pt',
    },
    theme: {
        themes: {
            light: {
                primary: '#6f18a8',
                secondary: '#008bbd',
                accent: '#FF6304',
                redmain: '#F70052',
                redsecondary: '#FF4A86',
                red900: '#B81148',
                red500: '#FF7BA7',
                red400: '#FFA7C4',
                orange900: '#FF6100',
                orange700: '#FF8133',
                orange600: '#FF8D55',
                orange300: '#FFD0B8',
                purplemain: '#6400AC',
                purplemain2: '#621F9A',
                purplesecondary: '#7F51C7',
                purple500: '#A77DE8',
                purple300: '#D2B6FF',
                blue900: '#12032B',
                blue850: '#1A0A33',
                blue800: '#271A5D',
                bluemain: '#3E009F',
                bluesecondary: '#4A2DA3',
                blue300: '#645FC9',
                blue200: '#8883F7',
                blue150: '#C4C1FF',
                blue100: '#D6D3FF',
                gray700: '#313943',
                gray500: '#5E6D7E',
                gray300: '#A2AFBD',
                gray200: '#BEC5D7',
                gray100: '#CFD9E3',
                gray50: '#E6EDF4',
                gray25: '#F2F7FF',
                white: '#FAFCFF',
                primaryfranchising: '#645FC9',
                green900: '#257A4C',
                green700: '#00B854',
                green300: '#C2F2D8',
            },
        },
    },
};
export default new Vuetify(opts);
