import { __decorate } from "tslib";
import NotificationsManager from '@/components/NotificationsManager.vue';
import { dispatchCheckLoggedIn } from '@/store/main/actions';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { cursoStore, departamentoStore, financeiroCategoriaStore, livroStore, mainStore, objecaoStore, userActionStore, } from '@/store';
let App = class App extends Vue {
    constructor() {
        super(...arguments);
        this.loadingStore = true;
        this.isOffline = false;
    }
    async created() {
        await dispatchCheckLoggedIn(this.$store);
    }
    get token() {
        return mainStore.isLoggedIn;
    }
    get currentUser() {
        return mainStore.userProfile;
    }
    get cssProps() {
        const themeColors = {};
        Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
            themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color];
        });
        return themeColors;
    }
    async onTokenChange(val) {
        if (val) {
            this.loadingStore = true;
            await userActionStore.getUserActions();
            if (this.currentUser) {
                if (!this.currentUser.is_aluno) {
                    await cursoStore.getCursos(); // otimizado
                    await cursoStore.getCursosEtapas(); // otimizado
                    await departamentoStore.getDepartamentos(); // otimizado
                    await financeiroCategoriaStore.getFinanceiroCategorias();
                    await livroStore.getLivros(); // otimizado
                    await objecaoStore.getObjecaos(); // otimizado
                }
            }
            const self = this;
            window.addEventListener('offline', (e) => {
                self.isOffline = true;
            });
            window.addEventListener('online', (e) => {
                self.isOffline = false;
            });
            this.loadingStore = false;
        }
        else {
            this.loadingStore = false;
        }
    }
};
__decorate([
    Watch('token')
], App.prototype, "onTokenChange", null);
App = __decorate([
    Component({
        components: {
            NotificationsManager,
        },
    })
], App);
export default App;
